import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CognitoUser} from "../../../models";

@Component({
  selector: 'CPF-fnol-request-find',
  templateUrl: './fnol-request-find.component.html',
  styleUrls: ['./fnol-request-find.component.scss']
})
export class FnolRequestFindComponent {
  public search = {
    progress: '',
    assignee: '',
    fnolFirstName: '',
    fnolLastName: '',
    fnolEmail: '',
    fnolPhone: '',
    fnolPetName: '',
  }

  public users: CognitoUser[] = [];

  constructor(public dialogRef: MatDialogRef<FnolRequestFindComponent>, @Inject(MAT_DIALOG_DATA) public data: { users: CognitoUser[], search: {
      progress: string,
      assignee: string,
      fnolFirstName: string,
      fnolLastName: string,
      fnolEmail: string,
      fnolPhone: string,
      fnolPetName: string,
    } 
  }) {
    this.users = data.users;
    this.search = data.search;
  }

  searchFnolRequests() {
    this.dialogRef.close(this.search);
  }

  clearAll() {
    this.search = {
      progress: '',
      assignee: '',
      fnolFirstName: '',
      fnolLastName: '',
      fnolEmail: '',
      fnolPhone: '',
      fnolPetName: '',
    }
  }
}
