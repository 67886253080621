<ng-container *ngIf="fnolRequest">
  <div class="action-container">
    <div class="icon" *ngIf="isUnclosedTasks()">
      <mat-icon color="primary">pending_actions</mat-icon>
    </div>
    <button (click)="openTasksList()" color="primary"
            mat-raised-button>{{ 'fnol-request-side-bar.task_button' | translate }}
    </button>
    <div class="flex-placeholder" *ngIf="isUnclosedTasks()"></div>
  </div>

  <div>
    <mat-form-field>
      <mat-label>{{ 'fnol-request-side-bar.priority_label' | translate }}</mat-label>
      <mat-select [(ngModel)]="fnolRequest.priority" (selectionChange)="dropdownValueChanged()">
        <mat-option [value]="0">{{ 'fnol-request-side-bar.priority_normal' | translate }}</mat-option>
        <mat-option [value]="1">{{ 'fnol-request-side-bar.priority_high' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>{{ 'fnol-request-side-bar.assignee_label' | translate }}</mat-label>
      <mat-select [(ngModel)]="fnolRequest.processingAssignee" (selectionChange)="dropdownValueChanged()">
        <mat-option value="Unassigned">{{ 'fnol-request-side-bar.assignee_unassigned' | translate }}</mat-option>
        <mat-option [value]="user.email" *ngFor="let user of usersList">{{ user.firstname }} {{ user.lastname }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="labels-container">
    <section>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.SIMPLE_CLAIM)"
                    (change)="checkLabel(tasksLabels.SIMPLE_CLAIM)">{{ 'fnol-request-side-bar.labels.simple_claim' | translate }}
      </mat-checkbox>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.MULTIPLE_CLAIMS)"
                    (change)="checkLabel(tasksLabels.MULTIPLE_CLAIMS)">{{ 'fnol-request-side-bar.labels.multiple_claims' | translate }}
      </mat-checkbox>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.WAITING)"
                    (change)="checkLabel(tasksLabels.WAITING)">{{ 'fnol-request-side-bar.labels.waiting' | translate }}
      </mat-checkbox>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.SIMPEGO)"
                    (change)="checkLabel(tasksLabels.SIMPEGO)">{{ 'fnol-request-side-bar.labels.simpego' | translate }}
      </mat-checkbox>
    </section>
  </div>

  <mat-form-field>
    <mat-label>{{ 'fnol-request-side-bar.reserve-claim-contract-id-label' | translate }}</mat-label>
    <input matInput [(ngModel)]="fnolRequest.mfvReserveClaimContractID" (change)="dropdownValueChanged()">
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'fnol-request-side-bar.reserve-claim-id-label' | translate }}</mat-label>
    <input matInput [(ngModel)]="fnolRequest.mfvReserveClaimID" (change)="dropdownValueChanged()">
  </mat-form-field>


  <button mat-raised-button color="warn"
  (click)="completeFNOLRequestWithoutProcessing()">
    {{ 'fnol-request-side-bar.close-without-processing' | translate }}
  </button>

  <button mat-raised-button color="primary" *ngIf="this.fnolRequest.processingStatus === 98"
  (click)="reopenFNOLRequest()">
    {{ 'fnol-request-side-bar.reopen' | translate }}
  </button>

</ng-container>
