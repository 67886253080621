import { Injectable } from '@angular/core';
import {MatDrawer} from "@angular/material/sidenav";
import {Contract, CoveredPosition, FnolRequest, MfvClaim} from "../models";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidebarDetailsService {
  private sidenav: MatDrawer | undefined;

  private sidebarType: 'contract' | 'fnolRequest' | 'mfvClaimPositions' = 'contract';

  private selectedContractDetails: BehaviorSubject<Contract |undefined> = new BehaviorSubject<Contract | undefined>(undefined);
  private selectedFnolRequest: BehaviorSubject<FnolRequest |undefined> = new BehaviorSubject<FnolRequest | undefined>(undefined);
  private selectedMfvClaimPositions: BehaviorSubject< {
    coveredPositions: CoveredPosition[],
    nonCoveredPositions: CoveredPosition[],
    totalCovered: number,
    totalNonCovered: number
  } |undefined> = new BehaviorSubject< {
    coveredPositions: CoveredPosition[],
    nonCoveredPositions: CoveredPosition[],
    totalCovered: number,
    totalNonCovered: number
  } | undefined>(undefined);


  public setSidenav(sidenav: MatDrawer) {
    this.sidenav = sidenav;
  }

  public setContractDetails(contractDetails: Contract) {
    this.setSidebarType('contract')
    this.selectedContractDetails.next(contractDetails);
  }

  public setFnolRequest(fnolRequest: FnolRequest) {
    this.setSidebarType('fnolRequest')
    this.selectedFnolRequest.next(fnolRequest);
  }

  public setMfvClaimPositions(mfvClaimPositions:  {
    coveredPositions: CoveredPosition[],
    nonCoveredPositions: CoveredPosition[],
    totalCovered: number,
    totalNonCovered: number
  }) {
    this.setSidebarType('mfvClaimPositions')
    this.selectedMfvClaimPositions.next(mfvClaimPositions);
  }

  setSidebarType(type: 'contract' | 'fnolRequest' | 'mfvClaimPositions') {
    this.sidebarType = type;
  }

  public getContractDetails() {
    return this.selectedContractDetails.asObservable();
  }

  public getFnolRequest() {
    return this.selectedFnolRequest.asObservable();
  }

  public getSidebarType() {
    return this.sidebarType;
  }

  public getMfvClaim() {
    return this.selectedMfvClaimPositions.asObservable();
  }

  public open() {
    return this.sidenav?.open();
  }


  public close() {
    return this.sidenav?.close();
  }

  public toggle(): void {
    this.sidenav?.toggle();
  }
}
