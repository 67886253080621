import {Component, Input} from '@angular/core';
import {Contract, ContractUIUsedUpFranchise} from "../../../models";

@Component({
  selector: 'CPF-contract-used-up-franchise-preview',
  templateUrl: './contract-used-up-franchise-preview.component.html',
  styleUrls: ['./contract-used-up-franchise-preview.component.scss']
})
export class ContractUsedUpFranchisePreviewComponent {
  public contracts:ContractUIUsedUpFranchise[] = [];

  @Input()
  set contractsWithUsedUpFranchise(contracts: ContractUIUsedUpFranchise[]) {
    this.contracts = contracts;
  }

  get contractsWithUsedUpFranchise():Contract[] {
    return this.contracts.map(c => {
      const {expanded, franchiseTypes, ...contract} = c;
      return contract;
    });
  }

  isContractIncluded(contract: ContractUIUsedUpFranchise, subtype: string) {
    return contract.franchiseTypes.includes(subtype);
  }

  buildContractLink(contract: ContractUIUsedUpFranchise) {
    //https://webapp.simpego.ch/vorgaenge/vertraege/<SECOND_PART_OF_FULL_CONTRACT_NUMBER>
    return `https://webapp.simpego.ch/vorgaenge/vertraege/${contract.full_contract_number.split('-')[1]}`;
  }

  toggleExpandUsedUpFranchise(contract: ContractUIUsedUpFranchise) {
    contract.expanded = !contract.expanded;
  }
}
