import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountService} from "../../services/account.service";
import {CognitoAuthenticatedUser} from "../../models";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {Observable} from "rxjs";
import {ApiHttpService} from "../../services/api-http.service";
import {DataStorageService} from "../../services/data-storage.service";
import { Router } from '@angular/router';

@Component({
  selector: 'CPF-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user$: Observable<CognitoAuthenticatedUser | null> | null = null;
  langs: string[] = ['de', 'en', 'it', 'fr'];
  currentLang: string = 'en';
  availableLangs = this.langs.filter(lang => lang !== this.currentLang);

  constructor(private accountService: AccountService, private dataStorageService: DataStorageService, private apiHttpService: ApiHttpService, private router: Router, private translate: TranslateService) {
    this.user$ = this.accountService.user$;

    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
      this.availableLangs = this.langs.filter(lang => lang !== this.currentLang);
    });

    this.currentLang = this.translate.currentLang;
    this.availableLangs = this.langs.filter(lang => lang !== this.currentLang);
  }

  ngOnInit(): void {
    this.accountService.handleFetchUserAttributes();

    this.user$?.subscribe(async user => {
      if (user && this.dataStorageService.classificationTypes.types.length === 0 && this.dataStorageService.classificationTypes.subTypes.length === 0 && this.dataStorageService.classificationTypes.causes.length === 0) {
        this.dataStorageService.classificationTypes = await this.apiHttpService.getClassificationTypes();
        this.dataStorageService.loaded = true;
      }
    })
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  async logout() {
    await this.accountService.logout();
  }

  goToFnolRequestList() {
    this.router.navigate(['platform', 'fnol-request-list']);
  }
}
