<main>
  <div>
    <section>
      <h1>Font</h1>
      <h2>Font</h2>
      <h3>Font</h3>
      <h4>Font</h4>
      <h5>Font</h5>
      <h6>Font</h6>
      <span class="title-1">Font</span>
      <span class="title-2">Font</span>
    </section>
    <section>
      <div class="example-label">Basic</div>
      <div class="example-button-row">
        <button mat-button>Basic</button>
        <button mat-button color="primary">Primary</button>
        <button mat-button color="accent">Accent</button>
        <button mat-button disabled>Disabled</button>
        <button mat-button color="primary">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-button color="primary">
          <mat-icon>add</mat-icon>
          Text
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Raised</div>
      <div class="example-button-row">
        <button mat-raised-button>Basic</button>
        <button mat-raised-button color="primary">Primary</button>
        <button mat-raised-button color="accent">Accent</button>
        <button mat-raised-button disabled>Disabled</button>
        <button mat-raised-button color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Stroked</div>
      <div class="example-button-row">
        <button mat-stroked-button>Basic</button>
        <button mat-stroked-button color="primary">Primary</button>
        <button mat-stroked-button color="accent">Accent</button>
        <button mat-stroked-button disabled>Disabled</button>
        <button mat-stroked-button color="primary">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-stroked-button color="primary">
          <mat-icon>add</mat-icon>
          Text
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Flat</div>
      <div class="example-button-row">
        <button mat-flat-button>Basic</button>
        <button mat-flat-button color="primary">Primary</button>
        <button mat-flat-button color="accent">Accent</button>
        <button mat-flat-button disabled>Disabled</button>
        <button mat-flat-button color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </section>

    <mat-divider></mat-divider>
    <br>
    <br>
    <br>
    <section>
      <mat-form-field>
        <mat-label>Label</mat-label>
        <input matInput placeholder="Input text" value="Input text">
        <mat-hint>Assistive text</mat-hint>
      </mat-form-field>

      <mat-form-field hideRequiredMarker>
        <mat-label>Label</mat-label>
        <mat-icon matIconPrefix>favorite</mat-icon>
        <input [formControl]="errorInputControl" matInput placeholder="Input text" value="Input text">
        <mat-error>Error message</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" (focus)="picker.open()">
        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Label</mat-label>
        <textarea matInput placeholder="Input text" value="Input text"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Cars</mat-label>
        <mat-select>
          <mat-option value="volvo">Volvo</mat-option>
          <mat-option value="saab">Saab</mat-option>
          <mat-option value="mercedes">Mercedes</mat-option>
          <mat-option value="audi">Audi</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="example-section">
      <mat-checkbox color="primary">Check me!</mat-checkbox>
      <mat-checkbox class="example-margin" [disabled]="true">Disabled</mat-checkbox>
    </section>
  </div>
</main>
