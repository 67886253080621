<div class="contract-info" *ngIf="contract">
  <div class="info-with-title favourites">
    <h3>{{ 'contract-details.contract.summary-title' | translate }}</h3>
    <div class="info">
      <div>
        <label>{{ 'contract-details.contract.dates' | translate }}</label>
        <p>{{ dateToDMYString(contract.start_date) }} - {{ dateToDMYString(contract.end_date) }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.product_bundle' | translate }}</label>
        <p>{{ contract.product_bundle }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.worldwide_coverage' | translate }}</label>
        <p>{{ contract.worldwide_protection_coverage.worldwide_protection | booleanTranslate }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.preexisting_conditions' | translate }}</label>
        <p>{{ contract.preexisting_conditions_coverage.preexisting_conditions | booleanTranslate }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.full_contract_number' | translate }}</label>
        <p>{{ contract.full_contract_number }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.status' | translate }}</label>
        <p>{{ contract.status }}</p>
      </div>
    </div>
  </div>

  <div class="info-with-title">
    <h3>{{ 'contract-details.contract.customer-title' |translate }}</h3>
    <div class="info">
      <div>
        <label>{{ 'contract-details.customer.firstname' | translate }}</label>
        <p>{{ contract.firstname }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.lastname' | translate }}</label>
        <p>{{ contract.lastname }}</p>
      </div>
      <div class="email">
        <label>{{ 'contract-details.customer.email' | translate }}</label>
        <p>{{ contract.email }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.street' | translate }}</label>
        <p>{{ contract.street }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.houseNumber' | translate }}</label>
        <p>{{ contract.houseNumber }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.zip' | translate }}</label>
        <p>{{ contract.zip }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.city' | translate }}</label>
        <p>{{ contract.city }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.language' | translate }}</label>
        <p>{{ contract.language }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.phoneNumber' | translate }}</label>
        <p>{{ contract.phoneNumber }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.customer.ibanPresent' | translate }}</label>
        <p>{{ contract.ibanPresent | booleanTranslate }}</p>
      </div>
    </div>
  </div>
  <div class="info-with-title">
    <h3>{{ 'contract-details.contract.pet-info-title' |translate }}</h3>
    <div class="info">

      <div>
        <label>{{ 'contract-details.pet.pet_name' | translate }}</label>
        <p>{{ contract.pet_name }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.pet.pet_species' | translate }}</label>
        <p>{{ contract.pet_species }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.pet.pet_gender' | translate }}</label>
        <p>{{ contract.pet_gender }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.pet.breed_type' | translate }}</label>
        <p>{{ contract.breed_type | breedTypeTranslate}}</p>
      </div>
      <div>
        <label>{{ 'contract-details.pet.breed' | translate }}</label>
        <p>{{ contract.breed }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.pet.birthdate' | translate }}</label>
        <p>{{ dateToString(contract.birthdate) }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.pet.neutered' | translate }}</label>
        <p>{{ contract.neutered | booleanTranslate }}</p>
      </div>
    </div>
  </div>
  <div class="info-with-title">
    <h3>{{ 'contract-details.contract.contract-title'|translate }}</h3>
    <div class="info">

      <div>
        <label>{{ 'contract-details.contract.full_contract_number' | translate }}</label>
        <p>{{ contract.full_contract_number }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.contract_number' | translate }}</label>
        <p>{{ contract.contract_number }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.status' | translate }}</label>
        <p>{{ contract.status }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.start_date' | translate }}</label>
        <p>{{ dateToString(contract.start_date) }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.end_date' | translate }}</label>
        <p>{{ dateToString(contract.end_date) }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.cancellation_date' | translate }}</label>
        <p>{{ dateToString(contract.cancellation_date) }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.date_of_wish_to_cancel' | translate }}</label>
        <p>{{ dateToString(contract.date_of_wish_to_cancel) }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.yearly_gross_premium' | translate }}</label>
        <p>{{ contract.yearly_gross_premium }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.yearly_net_premium' | translate }}</label>
        <p>{{ contract.yearly_net_premium }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.payment_interval' | translate }}</label>
        <p>{{ contract.payment_interval }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.taxes' | translate }}</label>
        <p>{{ contract.taxes }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.product_bundle' | translate }}</label>
        <p>{{ contract.product_bundle }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.yearly_net_premium_base' | translate }}</label>
        <p>{{ contract.yearly_net_premium_base }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.annual_franchise' | translate }}</label>
        <p>{{ contract.annual_franchise }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.deductible_percentage_until_9_years' | translate }}</label>
        <p>{{ contract.deductible_percentage_until_9_years }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.deductible_percentage_after_9_years' | translate }}</label>
        <p>{{ contract.deductible_percentage_after_9_years }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.contract.overall_sum_insured' | translate }}</label>
        <p>{{ contract.overall_sum_insured }}</p>
      </div>

      <div>
        <label>{{ 'contract-details.coverage.accident_and_illness_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.accident_and_illness_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.accident_and_illness_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}
          :            {{ contract.accident_and_illness_coverage.sublimit_sum }}</p>
        <p
          *ngIf="contract.accident_and_illness_coverage?.coverage_selected && contract.accident_and_illness_coverage.sublimit_percentage && contract.accident_and_illness_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}
          : {{ contract.accident_and_illness_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.illness_advanced_treatment_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.illness_advanced_treatment_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.illness_advanced_treatment_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.illness_advanced_treatment_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.illness_advanced_treatment_coverage?.coverage_selected && contract.illness_advanced_treatment_coverage.sublimit_percentage && contract.illness_advanced_treatment_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}
          :{{ contract.illness_advanced_treatment_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.illness_complementary_treatment_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.illness_complementary_treatment_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.illness_complementary_treatment_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.illness_complementary_treatment_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.illness_complementary_treatment_coverage?.coverage_selected && contract.illness_complementary_treatment_coverage.sublimit_percentage && contract.illness_complementary_treatment_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}
          :{{ contract.illness_complementary_treatment_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.illness_behavioural_treatment_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.illness_behavioural_treatment_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.illness_behavioural_treatment_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.illness_behavioural_treatment_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.illness_behavioural_treatment_coverage?.coverage_selected && contract.illness_behavioural_treatment_coverage.sublimit_percentage && contract.illness_behavioural_treatment_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}
          :{{ contract.illness_behavioural_treatment_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.accident_dental_treatment_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.accident_dental_treatment_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.accident_dental_treatment_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.accident_dental_treatment_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.accident_dental_treatment_coverage?.coverage_selected && contract.accident_dental_treatment_coverage.sublimit_percentage && contract.accident_dental_treatment_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}:{{ contract.accident_dental_treatment_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.illness_dental_treatment_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.illness_dental_treatment_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.illness_dental_treatment_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.illness_dental_treatment_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.illness_dental_treatment_coverage?.coverage_selected && contract.illness_dental_treatment_coverage.sublimit_percentage && contract.illness_dental_treatment_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}:{{ contract.illness_dental_treatment_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.emergency_minding_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.emergency_minding_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.emergency_minding_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.emergency_minding_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.emergency_minding_coverage?.coverage_selected && contract.emergency_minding_coverage.sublimit_percentage && contract.emergency_minding_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}:{{ contract.emergency_minding_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.emergency_treatment_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.emergency_treatment_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.emergency_treatment_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.emergency_treatment_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.emergency_treatment_coverage?.coverage_selected && contract.emergency_treatment_coverage.sublimit_percentage && contract.emergency_treatment_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}:{{ contract.emergency_treatment_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.medical_food_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.medical_food_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.medical_food_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.medical_food_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.medical_food_coverage?.coverage_selected && contract.medical_food_coverage.sublimit_percentage && contract.medical_food_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}:{{ contract.medical_food_coverage.sublimit_percentage }}</p>
      </div>
      <div>
        <label>{{ 'contract-details.coverage.preventive_care_coverage' | translate }}</label>
        <p>{{ 'contract-details.coverage.coverage-selected' | translate }}
          :  {{ contract.preventive_care_coverage.coverage_selected | booleanTranslate }}</p>
        <p *ngIf="contract.preventive_care_coverage?.coverage_selected">{{ 'contract-details.coverage.sublimit' | translate }}:           {{ contract.preventive_care_coverage.sublimit_sum }}</p>
        <p *ngIf="contract.preventive_care_coverage?.coverage_selected && contract.preventive_care_coverage.sublimit_percentage && contract.preventive_care_coverage.sublimit_percentage >=0">{{ 'contract-details.coverage.percentage-coverage' | translate }}:{{ contract.preventive_care_coverage.sublimit_percentage }}</p>
      </div>
    </div>
  </div>
  <div class="info-claims">
    <h3>{{ 'contract-details.contract.claims-info-title'|translate }}</h3>

    <ng-container *ngIf="contract.claims && contract.claims.length">
      <div class="info claim" *ngFor="let claim of contract.claims">
        <div>
          <label>{{ 'contract-details.claim.identifier' | translate }}</label>
          <p>{{ claim.identifier }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.claim_date' | translate }}</label>
          <p>{{ dateToString(claim.claim_date) }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.paid_amount' | translate }}</label>
          <p>{{ claim.paid_amount }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.expense' | translate }}</label>
          <p>{{ claim.expense }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.used_franchise' | translate }}</label>
          <p>{{ claim.used_franchise }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.status' | translate }}</label>
          <p>{{ claim.status }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.closing_reason' | translate }}</label>
          <p>{{ claim.closing_reason }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.closing_date' | translate }}</label>
          <p>{{ dateToString(claim.closing_date) }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.type' | translate }}</label>
          <p>{{ claim.type }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.sub_type' | translate }}</label>
          <p>{{ claim.sub_type }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.cause' | translate }}</label>
          <p>{{ claim.cause }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.insured_event' | translate }}</label>
          <p>{{ claim.insured_event | booleanTranslate }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.reason_why_not_insured' | translate }}</label>
          <p>{{ claim.reason_why_not_insured }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.deduction_yearly_franchise' | translate }}</label>
          <p>{{ claim.deduction_yearly_franchise }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.deduction_percentage_deductible' | translate }}</label>
          <p>{{ claim.deduction_percentage_deductible }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.deduction_deductible' | translate }}</label>
          <p>{{ claim.deduction_deductible }}</p>
        </div>
        <div>
          <label>{{ 'contract-details.claim.benefit_amount' | translate }}</label>
          <p>{{ claim.benefit_amount }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
