import {Component, Input} from '@angular/core';
import {
  Classification,
  ClassificationReasonNotCovered,
  Contract,
  ContractPreview, InvoiceItemsCoverageCheckStatus,
  ParsedInvoiceData,
  PositionUI,
  ReasonNotCovered,
  Treatment,
  TreatmentUI
} from "../../../models";
import {MatDialog} from "@angular/material/dialog";
import {ContractClaimsListModalComponent} from "../../contract/contract-claims-list-modal/contract-claims-list-modal.component";
import {DataStorageService} from "../../../services/data-storage.service";
import {checkIfTreatmentDateAfterContractEndDate, checkIfTreatmentDateBeforeContractStartDate} from "../../../helpers";
import { SidebarDetailsService } from 'src/app/services/sidebar-details.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'CPF-classification-list',
  templateUrl: './classification-list.component.html',
  styleUrls: ['./classification-list.component.scss']
})
export class ClassificationListComponent {
  treatments: TreatmentUI[] = [];
  contracts: Contract[] = [];
  expanded = true;

  public classificationType: any[] = []
  public classificationSubtype: any[] = []
  public classificationCause: any[] = []
  public classificationReasonNotCovered: any[] = [];

  @Input() set treatmentsValue(parsedInvoiceData: ParsedInvoiceData) {
    this.treatments = parsedInvoiceData.treatments.map(t => (
        {
          ...t,
          expanded: false,
          comment: t.comments || [],
          positions: t.positions.map(p => {
              let classification = p.classification || {};

              if (parsedInvoiceData && parsedInvoiceData.recognizedVet && !parsedInvoiceData.recognizedVet.value && parsedInvoiceData.recognizedVet.needToSetReason) {
                classification.type = this.classificationType[0];
                classification.subtype = this.classificationSubtype[0];
                classification.cause = this.classificationCause[0];
                classification.covered = false;
                classification.reasonNotCovered = this.dataService.classificationTypes.reasonsNotCovered.get(ReasonNotCovered.NoCoverageAccordingToGTC);
              }

              if (t.contract && t.dateValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN) {
                classification.covered = false;

                if (checkIfTreatmentDateBeforeContractStartDate(t.contract.start_date, t.date)) {
                  classification.reasonNotCovered = this.dataService.classificationTypes.reasonsNotCovered.get(ReasonNotCovered.DamageBeforeContractStart);
                } else if (checkIfTreatmentDateAfterContractEndDate(t.contract.end_date, t.date)) {
                  classification.reasonNotCovered = this.dataService.classificationTypes.reasonsNotCovered.get(ReasonNotCovered.Others);
                }

                t.dateValidationStatus = InvoiceItemsCoverageCheckStatus.PERFORMED;
              }

              return {
                ...p,
                copySource: false,
                copyTarget: false,
                classification: classification
              }
            }
          )
        }
      )
    );

    if (parsedInvoiceData && parsedInvoiceData.recognizedVet && parsedInvoiceData.recognizedVet.needToSetReason) {
      parsedInvoiceData.recognizedVet.needToSetReason = false;
    }
  }

  @Input() set contractSelected(contractSelected: ContractPreview[]) {
    this.contracts = contractSelected.map(c => c.contracts).flat();
  }

  get treatmentsFilled() {
    return this.treatments.map(t => {
        const {expanded, ...tr} = t;

        //set booleans to false if they are undefined
        const treatment = {
          ...tr,
          positions: tr.positions.map(p => {
            let {copySource, copyTarget, classification, ...pos} = p;
            classification = classification || {};
            return {
              ...pos,
              classification: {
                ...classification,
                followUpTreatment: classification.followUpTreatment || false,
                preexistingCondition: classification.preexistingCondition || false,
                emergencyAbroad: classification.emergencyAbroad || false,
                chronicDisease: classification.chronicDisease || false,
                obligationsFulfilled: classification.obligationsFulfilled || false,
                covered: classification.covered || false,
              }
            }
          })
        }
        return treatment;
      }
    )
  }

  constructor(private dialog: MatDialog, private dataService: DataStorageService, private sidebarDetailsService: SidebarDetailsService) {
    this.classificationCause = this.dataService.classificationTypes.causes;
    this.classificationType = this.dataService.classificationTypes.types;
    this.classificationSubtype = this.dataService.classificationTypes.subTypes;
    this.classificationReasonNotCovered = Object.values(ClassificationReasonNotCovered);
  }

  onAccidentDateChange(date: Date, p: any) {
    // Apply your custom logic to modify the date
    let tmp = this.toLocalIsoDateString(date)
    p.classification.accidentDate = tmp
  }

  onFollowUpCheckboxChange(event: MatCheckboxChange, position: PositionUI) {
    if (!event.checked) {
      // Checkbox is unchecked
      if(position.classification) {
        position.classification.followUpTreatmentClaim = undefined
        position.classification.followUpTreatmentCluster = undefined
      }
    }
  }

  toLocalIsoDateString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  validate() {
    let valid = true;
    this.treatments.forEach(t => {
      
      if (t.contract) {
        t.positions.forEach(p => {
          if (!p.classification) {
            valid = false;
            return;
          }
          
          if (!(p.classification.type && p.classification.subtype && p.classification.cause && p.classification.clusterAssignment && (p.classification.covered || p.classification.reasonNotCovered))) {
            valid = false;
          }

          if (this.askForAccidentDate(p)) {
            valid = !!p.classification.accidentDate;
          } else {
            // remove accident date if we do not need it
            p.classification.accidentDate = null
          }

        });
      }
        
    });
    return valid;
  }

  openClaimsForContract(contract: Contract | null, classification: Partial<Classification>) {
    if (!contract) return;

    this.dialog.open(ContractClaimsListModalComponent, {
      maxHeight: '90vh',
      data: {
        claims: contract?.claims.filter(claimInfo => claimInfo.precedent_claim_id === "")
      }
    }).afterClosed().subscribe((claimNumber: string) => {
      classification.followUpTreatmentClaim = claimNumber;
    });
  }

  findAssignedContract(fullContractNumber: string | null) {
    return this.contracts.find(c => c.full_contract_number === fullContractNumber);
  }

  toggleExpandTreatment(treatment: TreatmentUI) {
    treatment.expanded = !treatment.expanded;
  }

  isSelectedModeActive(treatment: TreatmentUI) {
    return treatment.positions.some(p => p.copySource);
  }

  isApplyToOthersEnabled(treatment: TreatmentUI) {
    return this.treatments.every(t => t.positions.every(p => !p.copySource)) && treatment.positions.length > 1;
  }

  isSelectedAtLeastOne(treatment: TreatmentUI) {
    return treatment.positions.some(p => p.copyTarget);
  }

  applyToOthers(position: PositionUI) {
    position.copySource = true;
  }

  selectAll(treatment: TreatmentUI) {
    treatment.positions.filter(p => !p.copySource).forEach(p => p.copyTarget = true);
  }

  applySelection(treatment: TreatmentUI) {
    const source = treatment.positions.find(p => p.copySource);

    if (!source) return;

    treatment.positions.filter(p => p.copyTarget).forEach(p => {
      p.classification = {...source.classification};
    });

    this.cancelSelection(treatment);
  }

  cancelSelection(treatment: TreatmentUI) {
    treatment.positions.forEach(p => {
      p.copySource = false;
      p.copyTarget = false;
    });
  }

  openContractDetailsSidebar(contract: Contract) {
    if (!contract) {
      return;
    }

    this.sidebarDetailsService.setContractDetails(contract);
    this.sidebarDetailsService.open();
  }

  askForAccidentDate(position: PositionUI): boolean {
    if (position.classification && position.classification.type) {
      return position.classification.type == 'unfall' && position.classification.followUpTreatment === false
    }
    return false
  }
}
