// PetInfo represents information about a pet.

export interface PetInfo {
  pet_name: string;
  pet_species: string;
  pet_gender: string;
  breed_type: boolean; // Assuming "mischrasse_haustier" is a boolean
  breed: string;
  birthdate: string;
  neutered: boolean;
}

export interface Customer {
  firstname: string;
  lastname: string;
  email: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  language: string;
  phoneNumber: string;
  ibanPresent: boolean;
}

// InsuranceCoverage represents information about a specific insurance coverage.
export interface InsuranceCoverage {
  coverage_selected?: boolean;
  sublimit_sum?: number;
  sublimit_percentage?: number;
  used_up_sum_insured?: number;
}

// Contract represents information about an insurance contract.
export interface Contract extends PetInfo, Customer {
  full_contract_number: string;
  full_contract_number_year_adjusted: string
  contract_number: string;
  status: ContractStatus;
  start_date: string;
  end_date: string;               // when the contract would end without renewal
  cancellation_date: string;      // when the contract ends
  date_of_wish_to_cancel: string; // when the customer said they want to cancel
  yearly_gross_premium: number;   // incl. taxes
  yearly_net_premium: number;     // excl. taxes
  payment_interval: string;       // monatlich/jährlich (monthly/yearly)
  taxes: number;
  product_bundle: string; // S/M/L
  yearly_net_premium_base: number;
  annual_franchise: number;
  used_up_annual_franchise: number;
  deductible_percentage_until_9_years: number;
  deductible_percentage_after_9_years: number;
  overall_sum_insured: number;
  used_up_sum_insured: number;

  accident_and_illness_coverage: InsuranceCoverage;
  illness_advanced_treatment_coverage: InsuranceCoverage;
  illness_complementary_treatment_coverage: InsuranceCoverage;
  illness_behavioural_treatment_coverage: InsuranceCoverage;
  accident_dental_treatment_coverage: InsuranceCoverage;
  illness_dental_treatment_coverage: InsuranceCoverage;
  emergency_minding_coverage: InsuranceCoverage;
  emergency_treatment_coverage: InsuranceCoverage;
  medical_food_coverage: InsuranceCoverage;
  preventive_care_coverage: InsuranceCoverage;

  preexisting_conditions_coverage: {
    yearly_net_premium: number;
    sum_insured_current_year: number;
    preexisting_conditions: boolean;
    used_up_sum_insured: number;
  };
  worldwide_protection_coverage: {
    yearly_net_premium: number;
    sum_insured: number;
    worldwide_protection: boolean;
    used_up_sum_insured: number;
  };

  claims: ClaimInfo[];
}

// ClaimInfo represents information about a claim.
export interface ClaimInfo {
  identifier: string;
  claim_date: string;  // needed in ppt
  paid_amount: number; // needed in ppt
  expense: number;
  used_franchise: number; // Note: This represents the latest state of the used franchise, not the snapshot at the date of the claim
  status: string;         // needed in ppt
  closing_reason: string;
  closing_date: string;
  type: string;     // illness/accident/prevention (claims category in ppt), needed in ppt
  sub_type: string; // e.g. dental treatment, vaccination (claims type in ppt), needed in ppt
  cause: string;    // needed in ppt
  insured_event: boolean;
  reason_why_not_insured: string;
  deduction_yearly_franchise: number;      // needed in ppt
  deduction_percentage_deductible: number; // needed in ppt
  deduction_deductible: number;            // needed in ppt
  benefit_amount: number;                  // ignore, can be inconsistent
  precedent_claim_id: string;
}

export enum ContractStatus {
  ACTIVE = 'policiert',
  EXPIRED = 'gekündigt'
}

export type ContractPreview =
  Pick<Contract, 'full_contract_number' | 'pet_name' | 'firstname' | 'lastname' | 'email' | 'phoneNumber' | 'birthdate'>
  & {
  contractNumberFirstPart: string;
  contracts: Contract[];
}

export interface ContactSearch {
  petName: string,
  petBirthDate: string,
  contractNumber: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
}

export type ContractPreviewUIExpandable = ContractPreview & { expanded?: boolean };

export type ContractPreviewUIStatus = ContractPreview & { status: ContractStatus };

export type ContractUIUsedUpFranchise = Contract & { expanded: boolean, franchiseTypes: string[]};
