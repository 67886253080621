import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DatePipe} from "@angular/common";
import {ClaimInfo} from "../../../models";

@Component({
  selector: 'CPF-contract-claims-list',
  templateUrl: './contract-claims-list.component.html',
  styleUrls: ['./contract-claims-list.component.scss']
})
export class ContractClaimsListComponent {
  @Input()
  public claims: ClaimInfo[] | undefined;

  @Input()
  public showSelectButton: boolean = false;

  @Output()
  public claimSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(private datePipe: DatePipe) {
  }

  dateToString(date: string): string {
    let dateObj = new Date(date);
    if (dateObj.toString() === "Invalid Date" || dateObj.getTime() === 0) {
      return "";
    } else {
      return this.datePipe.transform(dateObj, 'yyyy-MM-dd') || "";
    }
  }

  selectClaim(claimId: string): void {
    this.claimSelected.emit(claimId);
  }
}
