import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FnolRequest, ParsedInvoiceData, Treatment} from "../../../models";
import {ApiHttpService} from "../../../services/api-http.service";
import { HttpClient } from '@angular/common/http';
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomSnackbarErrorComponent} from "../../custom-snackbar-error/custom-snackbar-error.component";
import { createEmptyParsedInvoiceData } from 'src/app/helpers/utility';

@Component({
  selector: 'CPF-invoice-parsed-preview',
  templateUrl: './invoice-parsed-preview.component.html',
  styleUrls: ['./invoice-parsed-preview.component.scss']
})
export class InvoiceParsedPreviewComponent {
  @Input() parsedInvoiceData: ParsedInvoiceData | undefined;

  @Input() invoiceDocument: { s3Id: string } | undefined;

  public _fnolRequest!: FnolRequest;
  @Input() set fnolRequest(fnolRequest: FnolRequest) {
    this._fnolRequest = fnolRequest;
  }

  @Output()
  searchContract = new EventEmitter<{ fieldName: string, value: string }[]>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;

  uploadS3Id: string | undefined
  expanded = true;

  constructor(private apiHttpService: ApiHttpService, private http: HttpClient, private _snackBar: MatSnackBar) {
  }

  async openOrDownload(s3Id: string) {
    if (this._fnolRequest.id) {
      try {
        const location = (await this.apiHttpService.getFileDownloadUrl(this._fnolRequest.id, s3Id)).Location;
        window.open(location, '_blank');
      } catch (e) {
        console.error(e);
      }
    }
  }

  async sendForOcrExtraction(s3Id: string) {
    if (this._fnolRequest.id) {
      try {
        const ocrDocumentID = (await this.apiHttpService.extractDocument(this._fnolRequest.id, s3Id)).documentID;
        this._fnolRequest.ocrExtractionId = ocrDocumentID;
        const emptyParsedInvoiceData = createEmptyParsedInvoiceData()
        this._fnolRequest.parsedInvoiceData = emptyParsedInvoiceData // reset parsed invoice data to avoid confusion
        this.apiHttpService.updateFnolRequest(this._fnolRequest)
      } catch (e) {
        console.error(e);
      }
    }
  }

  async pollOcrExtraction() {
    console.log('polling extraction')
    if (this._fnolRequest.ocrExtractionId) {
      try {
        const extractedDocumentResponse = (await this.apiHttpService.getExtractedDocument(this._fnolRequest.ocrExtractionId));
        if (extractedDocumentResponse.parsedInvoiceData) {
          this._fnolRequest.parsedInvoiceData = extractedDocumentResponse.parsedInvoiceData;
          this.apiHttpService.updateFnolRequest(this._fnolRequest)
        } else if (extractedDocumentResponse.inQueue) {
            if(extractedDocumentResponse.queueStatus === 'in_progress') {
              this._snackBar.open('Extraction still in progress', 'Close', {
                duration: 1000,
              });
            }
        } else {
          this._snackBar.open(extractedDocumentResponse.errorMessage, 'Close', {
            duration: 0,
          });
          /*
          this._snackBar.openFromComponent(CustomSnackbarErrorComponent, {
            data: {
              error: extractedDocumentResponse.errorMessage || 'Error extracting document'
            }
          });
          */
        }
      } catch (e) {
        this._snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            error: e
          }
        });
      }
    } else {
      console.log('no ocr extraction id')
    }
  }

  async uploadNewInvoice(s3Id: string) {
    this.uploadS3Id = s3Id
    this.fileInput?.nativeElement.click()
  }

  // Function to handle the file input change event
  async onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      console.log('received file for upload')
      const file = input.files[0];
      // Perform additional actions with the selected file
      console.log('Selected file:', file);
      if (this._fnolRequest.id && file.name) {
        try {
          const presignedUrl = (await this.apiHttpService.getFileUploadUrl(this._fnolRequest.id, file.name)).Location;
          // Upload the file to S3
          console.log('received presigend URL', presignedUrl)
          const response = await this.http.put(presignedUrl, file, {
            headers: {
              'Content-Type': file.type
            }
          }).toPromise();
          console.log('repsonse', response)
          this._fnolRequest.invoiceDocument.s3Id = file.name
          await this.apiHttpService.updateFnolRequest(this._fnolRequest)

        } catch (e) {
          console.error(e);
        }
      }

    }
  }

  search(fieldName: string, value: string) {
    this.searchContract.emit([{fieldName, value}]);
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  searchRecipientName(recipientName: string) {
    this.searchContract.emit([
      {
        fieldName: 'firstName', value: recipientName.split(' ')[0]
      }, {
        fieldName: 'lastName', value: recipientName.split(' ').slice(1).join(' ')
      }
    ]);
  }

  calculateSubtotal(treatment: Treatment): number {
    return treatment.positions.reduce((total, position) => {return total + position.amount}, 0)
  }

  protected readonly perf_hooks = module
}
