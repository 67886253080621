import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Contract, CoveredPosition, FnolRequest, MfvClaim} from "./models";
import {SidebarDetailsService} from "./services/sidebar-details.service";
import {MatDrawer} from "@angular/material/sidenav";
import {MissingTranslationHandlerParams} from "@ngx-translate/core/lib/missing-translation-handler";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  @ViewChild('drawer', {static:true}) public sidenav!: MatDrawer;

  title: string = 'customer-portal-frontend';

  sidebarType: 'contract' | 'fnolRequest' | 'mfvClaimPositions' = 'contract';
  selectedContractDetails: Contract | undefined;
  selectedFnolRequest: FnolRequest | undefined;
  selectedMfvClaimPositions: {
    coveredPositions: CoveredPosition[],
    nonCoveredPositions: CoveredPosition[],
    totalCovered: number,
    totalNonCovered: number
  } | undefined;

  constructor(private translate: TranslateService, private sidebarDetailsService: SidebarDetailsService) {
    translate.onLangChange.subscribe((event) => {
      this.translate.get('common.application-tab-title').subscribe((res: string) => {
        this.title = res;
      });

      localStorage.setItem('language', event.lang);
    });

    translate.setDefaultLang('en');

    const language = localStorage.getItem('language');
    translate.use(language || 'en');
  }

  ngOnInit(): void {
    //TODO: move this to separate component
    this.sidebarDetailsService.getContractDetails().subscribe((contractDetails: Contract | undefined) => {
      this.sidebarType = this.sidebarDetailsService.getSidebarType();
      this.selectedContractDetails = contractDetails;
    });

    this.sidebarDetailsService.getFnolRequest().subscribe((fnolRequest: FnolRequest | undefined) => {
      this.sidebarType = this.sidebarDetailsService.getSidebarType();
      this.selectedFnolRequest = fnolRequest;
    });

    this.sidebarDetailsService.getMfvClaim().subscribe((mfvClaimPositions: {
      coveredPositions: CoveredPosition[],
      nonCoveredPositions: CoveredPosition[],
      totalCovered: number,
      totalNonCovered: number
    } | undefined) => {
      this.sidebarType = this.sidebarDetailsService.getSidebarType();
      this.selectedMfvClaimPositions = mfvClaimPositions;
    });

    this.sidebarDetailsService.setSidenav(this.sidenav);
  }
}
