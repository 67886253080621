<main>
  <amplify-authenticator [signUpAttributes]="signUpFields" [formFields]="formFields">
    <ng-template
      amplifySlot="authenticated"
      let-user="user"
      let-signOut="signOut"
    >
    </ng-template>
  </amplify-authenticator>
</main>
