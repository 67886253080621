<div class="title-2">{{ 'invoice-assign-items-preview.title-treatment-contract-assignment' | translate }}</div>

<div class="container" *ngIf="contracts.length && _fnolRequest.parsedInvoiceData">
  <div class="content-container">
    

  </div>
  <div class="actions">
    <mat-icon (click)="expanded = !expanded" class="expand-button" [class.expand]="!expanded">expand_less</mat-icon>
  </div>
</div>
