import {Component, HostBinding, Input} from '@angular/core';
import {FnolDetail, FnolRequest, fnolRequestExample} from "../../../models";

@Component({
  selector: 'CPF-fnol-details',
  templateUrl: './fnol-details.component.html',
  styleUrls: ['./fnol-details.component.scss']
})
export class FnolDetailsComponent {
  @Input()
  public fnolDetail: FnolDetail | null = null;

  @Input()
  public isExpanded: boolean = true;

  constructor() {
  }

  @HostBinding('class.expanded')
  get expanded() {
    return this.isExpanded;
  }
}
