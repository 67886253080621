import {Component, Input} from '@angular/core';
import {Contract, ContractUIUsedUpFranchise, Treatment} from "../../../models";

@Component({
  selector: 'CPF-contract-used-up-franchise',
  templateUrl: './contract-used-up-franchise.component.html',
  styleUrls: ['./contract-used-up-franchise.component.scss']
})
export class ContractUsedUpFranchiseComponent {
  public contracts:ContractUIUsedUpFranchise[] = [];
  public treatments:Treatment[] = []
  preexistingConditionsClusterDeductibleMap = new Map<string, number>()

  constructor() {
  }

  @Input()
  set contractsWithUsedUpFranchise(contracts: ContractUIUsedUpFranchise[]) {
    this.contracts = contracts;
  }

  @Input()
  set treatmentsWithClassification(treatments: Treatment[]) {
    this.treatments = treatments;
    treatments.forEach(treatment => {
      treatment.positions.forEach(position => {
        if (position.classification && position.classification.clusterAssignment && position.classification?.preexistingCondition && position.classification.followUpTreatment && position.classification.followUpTreatmentClaim) {
          this.preexistingConditionsClusterDeductibleMap.set(position.classification.clusterAssignment, 0)
        }
      })
    })
  }

  get contractsWithUsedUpFranchise():Contract[] {
    return this.contracts.map(c => {
      const {expanded, franchiseTypes, ...contract} = c;
      return contract;
    });
  }

  get usedUpFranchiseContracts() {
    return this.contracts;
  }

  get preexistingConditionsMap(): Map<string, number> {
    return this.preexistingConditionsClusterDeductibleMap
  }

  isContractIncluded(contract: ContractUIUsedUpFranchise, subtype: string) {
    return contract.franchiseTypes.includes(subtype);
  }

  buildContractLink(contract: ContractUIUsedUpFranchise) {
    //https://webapp.simpego.ch/vorgaenge/vertraege/<SECOND_PART_OF_FULL_CONTRACT_NUMBER>
    return `https://webapp.simpego.ch/vorgaenge/vertraege/${contract.full_contract_number.split('-')[1]}`;
  }

  toggleExpandUsedUpFranchise(contract: ContractUIUsedUpFranchise) {
    contract.expanded = !contract.expanded;
  }
}
