import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CommonModule, DatePipe} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiHttpService} from "./services/api-http.service";
import {MatDialogModule} from "@angular/material/dialog";

import {AmplifyAuthenticatorModule} from '@aws-amplify/ui-angular';
import {Amplify} from "aws-amplify";
import awsconfig from "../aws-exports";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UiKitComponent} from './ui-kit/ui-kit.component';
import {TestComponent} from './test/test.component';
import {AppRoutingModule} from "./app-routing.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {AccountComponent} from './account/account.component';
import {HeaderComponent} from "./components/header/header.component";
import {FindContractComponent} from './components/contract/find-contract/find-contract.component';
import {FindContractPageComponent} from './find-contract-page/find-contract-page.component';
import {ContractDetailsComponent} from './components/contract/contract-details/contract-details.component';
import {ContractDetailsModalComponent} from './components/contract/contract-details-modal/contract-details-modal.component';
import {ContractPreviewSelectModalComponent} from './components/contract/contract-preview-select-modal/contract-preview-select-modal.component';
import {FnolDetailsComponent} from './components/fnol/fnol-details/fnol-details.component';
import {FnolRequestComponent} from './components/fnol/fnol-request/fnol-request.component';
import {FnolDetailsPreviewComponent} from './components/fnol/fnol-details-preview/fnol-details-preview.component';
import {ContractDetailsPreviewComponent} from './components/contract/contract-details-preview/contract-details-preview.component';
import {BooleanTranslatePipe} from './pipes/boolean-translate.pipe';
import {BreedTypeTranslatePipe} from './pipes/breed-type-translate.pipe';
import {InvoiceParsedPreviewComponent} from './components/invoice/invoice-parsed-preview/invoice-parsed-preview.component';
import {InvoiceAssignItemsComponent} from './components/invoice/invoice-assign-items/invoice-assign-items.component';
import {ContractAssignListModalComponent} from './components/contract/contract-assign-list-modal/contract-assign-list-modal.component';
import {InvoiceAssignItemsPreviewComponent} from './components/invoice/invoice-assign-items-preview/invoice-assign-items-preview.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {ClassificationListComponent} from './components/classification/classification-list/classification-list.component';
import {ContractClaimsListComponent} from './components/contract/contract-claims-list/contract-claims-list.component';
import {ContractClaimsListModalComponent} from './components/contract/contract-claims-list-modal/contract-claims-list-modal.component';
import {ClassificationListPreviewComponent} from './components/classification/classification-list-preview/classification-list-preview.component';
import {ContractUsedUpFranchiseComponent} from './components/contract/contract-used-up-franchise/contract-used-up-franchise.component';
import {
  ContractUsedUpFranchisePreviewComponent
} from './components/contract/contract-used-up-franchise-preview/contract-used-up-franchise-preview.component';
import {FnolRequestListComponent} from './components/fnol/fnol-request-list/fnol-request-list.component';
import {FnolRequestFindComponent} from './components/fnol/fnol-request-find/fnol-request-find.component';
import {FnolCommentsModalComponent} from './components/fnol/fnol-comments-modal/fnol-comments-modal.component';
import {GeneralConfirmationModalComponent} from './components/general-confirmation-modal/general-confirmation-modal.component';
import {MfvClaimCalculationComponent} from './components/contract/mfv-claim-calculation/mfv-claim-calculation.component';
import {MfvPositionsDetailsComponent} from './components/contract/mfv-positions-details/mfv-positions-details.component';
import {FnolRequestPageComponent} from './fnol-request-page/fnol-request-page.component';
import {LoadingComponent} from "./components/loading/loading.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {LoadingService} from "./services/loading.service";
import {InvoiceItemsCoverageCheckComponent} from './components/invoice/invoice-items-coverage-check/invoice-items-coverage-check.component';
import {MfvClaimDetailComponent} from './components/contract/mfv-claim-detail/mfv-claim-detail.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { MfvClaimCalculationDetailComponent } from './components/contract/mfv-claim-calculation-detail/mfv-claim-calculation-detail.component';
import { CustomSnackbarErrorComponent } from './components/custom-snackbar-error/custom-snackbar-error.component';
import { InvoiceParsedEditComponent } from './components/invoice/invoice-parsed-edit/invoice-parsed-edit.component';
import { VATAndDiscountDetailsComponent } from './components/invoice/vat-and-discount-details/vat-and-discount-details.component';
import { VatAndDiscountPreviewComponent } from './components/invoice/vat-and-discount-preview/vat-and-discount-preview.component';
import { InvoiceItemsCoverageCheckPreviewComponent } from './components/invoice/invoice-items-coverage-check-preview/invoice-items-coverage-check-preview.component';
import { FnolRequestSideBarComponent } from './components/fnol/fnol-request-side-bar/fnol-request-side-bar.component';
import { TaskListFnolRequestComponent } from './components/task/task-list-fnol-request/task-list-fnol-request.component';
import { TaskListModalComponent } from './components/task/task-list-modal/task-list-modal.component';
import {HandleMissingTranslation} from "./helpers";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

Amplify.configure(awsconfig);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, UiKitComponent, TestComponent, AccountComponent, FindContractComponent, FindContractPageComponent, ContractDetailsComponent, ContractDetailsModalComponent, ContractPreviewSelectModalComponent, FnolDetailsComponent, FnolRequestComponent, FnolDetailsPreviewComponent, ContractDetailsPreviewComponent, BooleanTranslatePipe, BreedTypeTranslatePipe, InvoiceParsedPreviewComponent, InvoiceAssignItemsComponent, ContractAssignListModalComponent, InvoiceAssignItemsPreviewComponent, ClassificationListComponent, ContractClaimsListComponent, ContractClaimsListModalComponent, ClassificationListPreviewComponent, ContractUsedUpFranchiseComponent, ContractUsedUpFranchisePreviewComponent, FnolRequestListComponent, FnolRequestFindComponent, FnolCommentsModalComponent, GeneralConfirmationModalComponent, MfvClaimCalculationComponent, MfvPositionsDetailsComponent, FnolRequestPageComponent, LoadingComponent, InvoiceItemsCoverageCheckComponent, MfvClaimDetailComponent, MfvClaimCalculationDetailComponent, CustomSnackbarErrorComponent, InvoiceParsedEditComponent, VATAndDiscountDetailsComponent, VatAndDiscountPreviewComponent, InvoiceItemsCoverageCheckPreviewComponent, FnolRequestSideBarComponent, TaskListFnolRequestComponent, TaskListModalComponent],
  imports: [
    CommonModule, RouterOutlet, BrowserModule, HttpClientModule, MatSnackBarModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: HandleMissingTranslation
      }
    }),
    BrowserAnimationsModule,
    MatDialogModule,
    AmplifyAuthenticatorModule, ReactiveFormsModule, MatCheckboxModule, MatSelectModule, MatDatepickerModule, MatIconModule, MatListModule, MatNativeDateModule, MatButtonModule, MatInputModule, HeaderComponent, FormsModule, MatSidenavModule, MatProgressSpinnerModule
  ],
  bootstrap: [AppComponent],
  providers: [ApiHttpService, DatePipe, LoadingService, { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }, {provide: MAT_DATE_LOCALE, useValue: 'de-CH'}]
})
export class AppModule {
}
