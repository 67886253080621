<div class="container">
  <div class="info-with-title">
    <div class="total-info" *ngIf="mfvClaims && mfvClaims.length">
      <div class="claim-info">
        <div class="claim-info-title">{{ 'mfv-claim-calculation.total-treated-amount-title' | translate }}</div>
        <div class="claim-info-value">{{ totalTreatedAmount() | number : '1.2-2'}}</div>
      </div>
      <div class="claim-info">
        <div class="claim-info-title">{{ 'mfv-claim-calculation.total-covered-amount-title' | translate }}</div>
        <div class="claim-info-value">{{ totalCoveredAmount() | number : '1.2-2'}}</div>
      </div>
      <div class="claim-info">
        <div class="claim-info-title">{{ 'mfv-claim-calculation.total-deductions-title' | translate }}</div>
        <div class="claim-info-value">{{ totalDeductions() | number : '1.2-2'}}</div>
      </div>
      <div class="claim-info">
        <div class="claim-info-title">{{ 'mfv-claim-calculation.total-benefit-amount-title' | translate }}</div>
        <div class="claim-info-value">{{ totalBenefitAmount() | number : '1.2-2'}}</div>
      </div>
      <div class="claim-info">
        <div class="claim-info-title">{{ 'mfv-claim-calculation.total-non-covered-amount-title' | translate }}</div>
        <div class="claim-info-value">{{ totalNonCoveredAmount() | number : '1.2-2'}}</div>
      </div>
    </div>
  </div>
</div>
