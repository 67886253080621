import {Injectable} from '@angular/core';
import {ClassificationType} from "../models";
import {ApiHttpService} from "./api-http.service";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  private _classificationTypes: ClassificationType = new ClassificationType([], [], [], new Map());
  private $_loaded = new BehaviorSubject(false);

  constructor(private apiHttpService: ApiHttpService) {
  }

  get classificationTypes() {
    return this._classificationTypes;
  }

  set classificationTypes(classificationTypes: ClassificationType) {
    classificationTypes.reasonsNotCovered = new Map((Object.entries(classificationTypes.reasonsNotCovered)) as any);
    this._classificationTypes = classificationTypes;
  }

  get loaded(): Observable<boolean> {
    return this.$_loaded.asObservable();
  }

  set loaded(loaded: boolean) {
    this.$_loaded.next(loaded);
  }
}
