<div class="title-2">{{ 'fnol-details-preview.title-info-summary' | translate }}</div>
<div class="container">
  <div class="info" *ngIf="fnolRequest && expanded">
    <div class="fnol-first-name">
      <label>{{ 'fnol-details-preview.first-name' | translate }}</label>
      <div class="info-value-with-search"><span>{{ fnolRequest.fnolFirstName }}</span>
        <div class="info-search-div" (click)="search('firstName', fnolRequest.fnolFirstName)">
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </div>
    <div class="fnol-last-name">
      <label>{{ 'fnol-details-preview.last-name' | translate }}</label>
      <div class="info-value-with-search"><span>{{ fnolRequest.fnolLastName }}</span>
        <div class="info-search-div" (click)="search('lastName', fnolRequest.fnolLastName)">
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </div>
    <div class="fnol-email">
      <label>{{ 'fnol-details-preview.email' | translate }}</label>
      <div class="info-value-with-search"><span>{{ fnolRequest.fnolEmail }}</span>
        <div class="info-search-div" (click)="search('email', fnolRequest.fnolEmail)">
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </div>
    <div class="fnol-phone-number">
      <label>{{ 'fnol-details-preview.phone-number' | translate }}</label>
      <div class="info-value-with-search"><span>{{ fnolRequest.fnolPhoneNumber }}</span>
        <div class="info-search-div" (click)="search('phone', fnolRequest.fnolPhoneNumber)">
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </div>
    <div class="fnol-preferred-channel">
      <label>{{ 'fnol-details-preview.preferred-channel' | translate }}</label>
      <p>{{ fnolRequest.fnolPreferredChannel }}</p>
    </div>
    <div class="fnol-lang">
      <label>{{ 'fnol-details-preview.language' | translate }}</label>
      <p>{{ fnolRequest.fnolLang }}</p>
    </div>
    <div class="fnol-iban">
      <label>{{ 'fnol-details-preview.iban' | translate }}</label>
      <p>{{ fnolRequest.fnolIban }}</p>
    </div>
    <div class="fnol-pet-name">
      <label>{{ 'fnol-details-preview.pet-name' | translate }}</label>
      <div class="info-value-with-search"><span>{{ fnolRequest.fnolPetName }}</span>
        <div class="info-search-div" (click)="search('petName', fnolRequest.fnolPetName)">
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </div>
    <div class="fnol-condition-previously-treated">
      <label>{{ 'fnol-details-preview.condition-previously-treated' | translate }}</label>
      <p>{{ fnolRequest.fnolConditionPreviouslyTreated }}</p>
    </div>
    <div class="fnol-date-condition-last-treated">
      <label>{{ 'fnol-details-preview.date-condition-last-treated' | translate }}</label>
      <p>{{ fnolRequest.fnolDateConditionLastTreated }}</p>
    </div>
    <div class="fnol-date-condition-first-treated">
      <label>{{ 'fnol-details-preview.date-condition-first-treated' | translate }}</label>
      <p>{{ fnolRequest.fnolDateConditionFirstTreated }}</p>
    </div>
    <div class="fnol-date-condition-noticed">
      <label>{{ 'fnol-details-preview.date-condition-noticed' | translate }}</label>
      <p>{{ fnolRequest.fnolDateConditionNoticed }}</p>
    </div>
    <div class="fnol-date-of-treatment">
      <label>{{ 'fnol-details-preview.date-of-treatment' | translate }}</label>
      <p>{{ fnolRequest.fnolDateOfTreatment }}</p>
    </div>
    <div class="fnol-treated-in-switzerland">
      <label>{{ 'fnol-details-preview.treated-in-switzerland' | translate }}</label>
      <p>{{ fnolRequest.fnolTreatedInSwitzerland }}</p>
    </div>
    <div class="fnol-invoiced-amount">
      <label>{{ 'fnol-details-preview.invoiced-amount' | translate }}</label>
      <p>{{ fnolRequest.fnolInvoicedAmount }}</p>
    </div>
    <div class="fnol-treatment-finished">
      <label>{{ 'fnol-details-preview.treatment-finished' | translate }}</label>
      <p>{{ fnolRequest.fnolTreatmentFinished }}</p>
    </div>
    <div class="fnol-third-party-fault">
      <label>{{ 'fnol-details-preview.third-party-fault' | translate }}</label>
      <p>{{ fnolRequest.fnolThirdPartyFault }}</p>
    </div>
    <div class="fnol-additional-information">
      <label>{{ 'fnol-details-preview.additional-information' | translate }}</label>
      <p>{{ fnolRequest.fnolAdditionalInformation }}</p>
    </div>
    <div>
      <label>{{ 'fnol-details-preview.diagnoses' | translate }}</label>
      <p *ngFor="let diagnosis of fnolRequest.fnolDiagnoses">{{diagnosis.id}} ({{diagnosis.category}})</p>
    </div>
    <div>
      <label>{{ 'fnol-details-preview.notification-date' | translate }}</label>
      <p>{{ isoDateToEuro(fnolRequest.notificationDate) }}</p>
    </div>
  </div>
  <div class="small-info" *ngIf="fnolRequest && !expanded">
    <span>
      <mat-icon>person</mat-icon>
      {{ fnolRequest.fnolFirstName }} {{ fnolRequest.fnolLastName }}
    </span>
    <span>
      <mat-icon>pets</mat-icon>
      {{ fnolRequest.fnolPetName }}
    </span>
    <span>
      <mat-icon>email</mat-icon>
      {{ fnolRequest.fnolEmail }}
    </span>
  </div>
  <div class="actions">
    <mat-icon (click)="toggle()" class="expand-button" [class.expand]="!expanded">expand_less</mat-icon>
  </div>
</div>

<button mat-raised-button color="primary" (click)="generatePDF()">{{ 'fnol-details-preview.generate-pdf' | translate }}</button>
