<div class="container">
  <div class="positions-container">
    <div class="title-2">{{ 'mfv-positions-details.title-2-covered-positions' | translate }}</div>
    <ng-container *ngIf="positions.coveredPositions && positions.coveredPositions.length;else noCoveredPositions">
      <div class="covered-positions">
        <div class="position" *ngFor="let position of positions.coveredPositions">
          <div class="position-title">{{ 'mfv-positions-details.position-title' | translate }}</div>
          <div class="position-value">{{ position.amount | number : '1.2-2' }}</div>
        </div>
        <div class="position total">
          <div class="total-title">{{ 'mfv-positions-details.total-covered-amount' | translate }}</div>
          <div class="total-value">{{ positions.totalCovered | number : '1.2-2' }}</div>
        </div>
      </div>
    </ng-container>
    <ng-template #noCoveredPositions>
      <div class="no-positions">{{ 'mfv-positions-details.no-covered-positions' | translate }}</div>
    </ng-template>
    <div class="title-2">{{ 'mfv-positions-details.title-2-non-covered-positions' | translate }}</div>
    <ng-container *ngIf="positions.nonCoveredPositions && positions.nonCoveredPositions.length;else noNonCoveredPositions">
      <div class="covered-positions">
        <div class="position" *ngFor="let position of positions.nonCoveredPositions">
          <div class="position-title">{{ 'mfv-positions-details.position-title' | translate }}</div>
          <div class="position-value">{{ position.amount | number : '1.2-2' }}</div>
        </div>
        <div class="position total">
          <div class="total-title">{{ 'mfv-positions-details.total-non-covered-amount' | translate }}</div>
          <div class="total-value">{{ positions.totalNonCovered | number : '1.2-2' }}</div>
        </div>
      </div>
    </ng-container>
    <ng-template #noNonCoveredPositions>
      <div class="no-positions">{{ 'mfv-positions-details.no-non-covered-positions' | translate }}</div>
    </ng-template>
  </div>
</div>
