import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MfvClaim, MfvClaimUIExpanded} from "../../../models";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";

@Component({
  selector: 'CPF-mfv-claim-calculation',
  templateUrl: './mfv-claim-calculation.component.html',
  styleUrls: ['./mfv-claim-calculation.component.scss']
})
export class MfvClaimCalculationComponent {
  mfvClaims: Array<MfvClaimUIExpanded> = [];

  @Input() set calculatedMfvClaims(mfvClaims: Array<MfvClaim>) {
    this.mfvClaims = mfvClaims.map(i => ({...i, expanded: false, expandedBig: false}));
  }

  @Output() editClassification = new EventEmitter<void>();

  constructor(private sidebarDetailsService: SidebarDetailsService) {
  }

  showPositions(claim: MfvClaimUIExpanded) {
    this.sidebarDetailsService.setMfvClaimPositions({
      coveredPositions: claim.coveredPositions,
      nonCoveredPositions: claim.nonCoveredPositions,
      totalCovered: claim.totalCoveredAmount,
      totalNonCovered: claim.totalNonCoveredAmount,
    })

    this.sidebarDetailsService.open();
  }

  toggleExpandClaim(claim: MfvClaimUIExpanded) {
    claim.expanded = !claim.expanded;
  }

  totalTreatedAmount() {
    return this.mfvClaims.reduce((acc, claim) =>
        acc + (claim.coveredPositions ? claim.coveredPositions.reduce((acc, position) => acc + position.amount, 0) : 0)
      + (claim.nonCoveredPositions ? claim.nonCoveredPositions.reduce((acc, position) => acc + position.amount, 0) : 0)
      , 0).toFixed(2);
  }

  totalCoveredAmount() {
    return this.mfvClaims.reduce((acc, claim) => acc + claim.totalCoveredAmount, 0).toFixed(2);
  }

  totalDeductions() {
    return this.mfvClaims.reduce((acc, claim) => acc + claim.deductionLimit + claim.deductionYearlyFranchise + claim.deductionPercentageDeductible + claim.deductionPerClaimDeductible, 0).toFixed(2);
  }

  totalBenefitAmount() {
    return this.mfvClaims.reduce((acc, claim) => acc + claim.benefitAmount, 0).toFixed(2);
  }

  totalNonCoveredAmount() {
    return this.mfvClaims.reduce((acc, claim) => acc + claim.totalNonCoveredAmount, 0).toFixed(2);
  }

  editClassificationClick() {
    this.editClassification.emit();
  }
}
