<input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;">
<div class="title-2">{{ 'invoice-parsed-preview.title' | translate }}</div>
<div class="info invoice-metadata">
  <div *ngIf="invoiceDocument">
    <label>{{ 'invoice-parsed-preview.document' | translate }}</label>
    <div class="info-value-with-search"><span>{{ invoiceDocument.s3Id }}</span>
      <div class="info-search-div" (click)="openOrDownload(invoiceDocument.s3Id)" [title]="'invoice-parsed-preview.download' | translate">
        <mat-icon>file_download</mat-icon>
      </div>
      <div class="info-search-div" (click)="uploadNewInvoice(invoiceDocument.s3Id)" [title]="'invoice-parsed-preview.upload' | translate">
        <mat-icon>file_upload</mat-icon>
      </div>
      <div class="info-search-div" (click)="sendForOcrExtraction(invoiceDocument.s3Id)" [title]="'invoice-parsed-preview.send-extraction' | translate">
        <mat-icon>find_in_page</mat-icon>
      </div>
      <div *ngIf="_fnolRequest.ocrExtractionId" class="info-search-div" (click)="pollOcrExtraction()" [title]="'invoice-parsed-preview.get-extraction' | translate">
        <mat-icon>refresh</mat-icon>
      </div>
    </div>
  </div>
</div>
<div class="parsed-invoice" *ngIf="parsedInvoiceData">
  <div class="expanded-parsed-info" *ngIf="expanded">
    <div class="info invoice-metadata">
      <div>
        <label>{{ 'invoice-parsed-preview.issuer-name' | translate }}</label>
        <p>{{ parsedInvoiceData.invoiceMetadata.issuerName }}</p>
      </div>
      <div>
        <label>{{ 'invoice-parsed-preview.issuer-address' | translate }}</label>
        <p>{{ parsedInvoiceData.invoiceMetadata.issuerAddress }}</p>
      </div>
      <div>
        <label>{{ 'invoice-parsed-preview.recipient-name' | translate }}</label>
        <div class="info-value-with-search"><span>{{ parsedInvoiceData.invoiceMetadata.recipientName }}</span>
          <div class="info-search-div" (click)="searchRecipientName(parsedInvoiceData.invoiceMetadata.recipientName)">
            <mat-icon>search</mat-icon>
          </div>
        </div>
      </div>
      <div>
        <label>{{ 'invoice-parsed-preview.recipient-address' | translate }}</label>
        <p>{{ parsedInvoiceData.invoiceMetadata.recipientAddress }}</p>
      </div>
      <div>
        <label>{{ 'invoice-parsed-preview.invoice-number' | translate }}</label>
        <p>{{ parsedInvoiceData.invoiceMetadata.invoiceNumber }}</p>
      </div>
      <div>
        <label>{{ 'invoice-parsed-preview.invoice-date' | translate }}</label>
        <p>{{ parsedInvoiceData.invoiceMetadata.invoiceDate }}</p>
      </div>
      <div>
        <label>{{ 'invoice-parsed-preview.language' | translate }}</label>
        <p>{{ parsedInvoiceData.invoiceMetadata.language }}</p>
      </div>
    </div>
    <div class="info treatment" *ngFor="let treatment of parsedInvoiceData.treatments">
      <div class="treatment-pet-info">
        <div>
          <label>{{ 'invoice-parsed-preview.pet-name' | translate }}</label>
          <div class="info-value-with-search"><span>{{ treatment.petName }}</span>
            <div class="info-search-div" (click)="search('petName',treatment.petName)">
              <mat-icon>search</mat-icon>
            </div>
          </div>
        </div>
        <div>
          <label>{{ 'invoice-parsed-preview.treatment-date' | translate }}</label>
          <p>{{ treatment.date | date:'dd.MM.YYYY' }}</p>
        </div>
        <div>
          <label>{{ 'invoice-parsed-preview.diagnosis' | translate }}</label>
          <p>{{ treatment.diagnosis }}</p>
        </div>
      </div>

      <div class="position-titles info treatment-position" *ngIf="treatment.positions && treatment.positions.length">
        <div class="treatment-name-container">
          <label>{{ 'invoice-parsed-preview.treatment-name' | translate }}</label>
        </div>
        <div>
          <label>{{ 'invoice-parsed-preview.quantity' | translate }}</label>
        </div>
        <div>
          <label>{{ 'invoice-parsed-preview.vat-percentage' | translate }}</label>
        </div>
        <div>
          <label>{{ 'invoice-parsed-preview.amount' | translate }}</label>
        </div>
      </div>


      <div class="info treatment-position" *ngFor="let treatmentPosition of treatment.positions">
        <div class="treatment-name-container">
          <p>{{ treatmentPosition.name }}</p>
        </div>
        <div>
          <p>{{ treatmentPosition.quantity }}</p>
        </div>
        <div>
          <p>{{ treatmentPosition.vatPercentage }}</p>
        </div>
        <div>
          <p>{{ treatmentPosition.amount }}</p>
        </div>
      </div>

      <div>
        <div>
          <label>{{ 'invoice-parsed-preview.subtotal' | translate }}</label>
          <p>{{ calculateSubtotal(treatment)  | number: '1.2-2' }}</p>
        </div>
      </div>
    </div>

    <div class="info">
      <div>
        <label>{{ 'invoice-parsed-preview.vat' | translate }}</label>
        <p>{{ (parsedInvoiceData.sumWithVat - parsedInvoiceData.sumWithoutVat) | number: '1.2-2' }}</p>
      </div>
    </div>

  </div>
  <div class="small-info" *ngIf="!expanded">
    <span><mat-icon>emergency</mat-icon>
      {{ parsedInvoiceData.invoiceMetadata.issuerName }}
    </span>
    <span><mat-icon>local_hospital</mat-icon>
      {{ parsedInvoiceData.invoiceMetadata.issuerAddress }}
    </span>
    <span><mat-icon>person</mat-icon>
      {{ parsedInvoiceData.invoiceMetadata.recipientName }}
    </span>
    <span><mat-icon>home</mat-icon>
      {{ parsedInvoiceData.invoiceMetadata.recipientAddress }}
    </span>
  </div>
  <div class="actions">
    <mat-icon (click)="toggleExpand()" class="expand-button" [class.expand]="!expanded">expand_less</mat-icon>
  </div>
</div>
