import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CognitoUser, FnolRequest, FnolRequestStatus, TasksLabels } from "../../../models";
import { CognitoUsersService } from "../../../services/cognito-users.service";
import { LoadingOverlayRef, LoadingService } from "../../../services/loading.service";
import { ApiHttpService } from "../../../services/api-http.service";
import { debounceTime } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { TaskListModalComponent } from "../../task/task-list-modal/task-list-modal.component";

@Component({
  selector: 'CPF-fnol-request-side-bar',
  templateUrl: './fnol-request-side-bar.component.html',
  styleUrls: ['./fnol-request-side-bar.component.scss']
})
export class FnolRequestSideBarComponent implements OnInit {
  @Input()
  fnolRequest: FnolRequest | null = null;
  public usersList: CognitoUser[] = [];
  private dropdownValueChange: EventEmitter<string> = new EventEmitter();
  public tasksLabels = TasksLabels;

  constructor(private cognitoUsersService: CognitoUsersService, private apiHttpService: ApiHttpService, private loadingService: LoadingService, private dialog: MatDialog) {
  }

  async ngOnInit() {
    this.usersList = await this.cognitoUsersService.getCognitoUsers();

    this.dropdownValueChange.pipe(debounceTime(1000)).subscribe(() => {
      this.save();
    });
  }

  dropdownValueChanged() {
    this.dropdownValueChange.emit();
  }

  isUnclosedTasks() {
    if (!this.fnolRequest || !this.fnolRequest.tasks) {
      return false;
    }

    return this.fnolRequest.tasks.some(t => !t.closingDate);
  }

  isLabelChecked(label: TasksLabels) {
    if (!this.fnolRequest || !this.fnolRequest.labels) {
      return false;
    }

    return this.fnolRequest.labels.includes(label);
  }

  checkLabel(label: TasksLabels) {
    if (!this.fnolRequest) {
      return;
    }

    if (!this.fnolRequest.labels) {
      this.fnolRequest.labels = [];
    }

    if (this.fnolRequest.labels.includes(label)) {
      this.fnolRequest.labels = this.fnolRequest.labels.filter(l => l !== label);
    } else {
      this.fnolRequest.labels.push(label);
    }

    this.dropdownValueChanged();
  }

  async save() {
    if (!this.fnolRequest) {
      return;
    }

    const loadingRef: LoadingOverlayRef = this.loadingService.open();
    try {
      const res = await this.apiHttpService.updateFnolRequest(this.fnolRequest);
    } catch (e) {
      console.error(`Error while saving the FNOL request: ${e}`);
    } finally {
      loadingRef.close();
    }
  }

  openTasksList() {
    if (!this.fnolRequest) {
      return;
    }

    if (!this.fnolRequest.tasks) {
      this.fnolRequest.tasks = [];
    }

    this.fnolRequest!.tasks = this.fnolRequest!.tasks.map(t => ({
      ...t,
      includeInComment: t.includeInComment ?? false
    }));
    const dialogRef = this.dialog.open(TaskListModalComponent, {
      data: this.fnolRequest,
      maxWidth: '1000px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.save();
    });
  }

  completeFNOLRequestWithoutProcessing() {
    if (this.fnolRequest) {
      this.fnolRequest.processingStatus = FnolRequestStatus.NO_PROCESSING;
      this.save();
    }
  }

  reopenFNOLRequest() {
    if (this.fnolRequest) {
      this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_PARSED_CONFIRMED;
      this.save();
    }
  }

}
