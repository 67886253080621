import {Task} from "./utility";
import {Classification} from "./classification";
import {Contract} from "./contract";
import {MfvClaim} from "./mfv-claim";

export enum FnolRequestStatus {
  TODO = 0,
  FNOL_DATA_CONFIRMED = 1,
  INVOICE_PARSED_CONFIRMED = 2,
  VAT_AND_DISCOUNT_SET = 3,
  CONTRACTS_ASSIGNED = 4,
  INVOICE_ITEMS_TO_CONTRACT_ASSIGNED = 5,
  TREATMENT_VALIDATED = 6,
  CLASSIFICATIONS_ASSIGNED = 7,
  USED_UP_FRANCHISE_FILLED = 8,
  PAYMENT_CALCULATED = 9,
  NO_PROCESSING = 98,
  DONE = 99, // left some space for future statuses
}

export enum FnolRequestStatusFilter {
  ALL = 'ALL',
  TODO = 0,
  IN_PROGRESS = 1,
  DONE = 99,
}

export class FnolDetail {
  fnolFirstName: string = '';
  fnolLastName: string = '';
  fnolEmail: string = '';
  fnolPhoneNumber: string = '';
  fnolPreferredChannel: string = 'email'; // Example default
  fnolLang: string = 'en';              // Example default
  fnolIban: string = '';
  fnolPetName: string = '';
  fnolConditionPreviouslyTreated: boolean = false;
  fnolDateConditionLastTreated: string = '';
  fnolDateConditionFirstTreated: string = '';
  fnolDateConditionNoticed: string = '';
  fnolDateOfTreatment: string = '';
  fnolTreatedInSwitzerland: boolean = false;
  fnolInvoicedAmount: number = 0;
  fnolTreatmentFinished: boolean = false;
  fnolThirdPartyFault: boolean = false;
  fnolAdditionalInformation: string = '';
  fnolDiagnoses: Array<{ id: string; category: string }> = [];
  fnolWhitelabelFlavor: string = '';

  constructor(data?: Partial<FnolDetail>) {
    Object.assign(this, data);
  }

  static extractFromFnolRequest(fnolRequest: FnolRequest): FnolDetail {
    return new FnolDetail({
      fnolFirstName: fnolRequest.fnolFirstName,
      fnolLastName: fnolRequest.fnolLastName,
      fnolEmail: fnolRequest.fnolEmail,
      fnolPhoneNumber: fnolRequest.fnolPhoneNumber,
      fnolPreferredChannel: fnolRequest.fnolPreferredChannel,
      fnolLang: fnolRequest.fnolLang,
      fnolIban: fnolRequest.fnolIban,
      fnolPetName: fnolRequest.fnolPetName,
      fnolConditionPreviouslyTreated: fnolRequest.fnolConditionPreviouslyTreated,
      fnolDateConditionLastTreated: fnolRequest.fnolDateConditionLastTreated,
      fnolDateConditionFirstTreated: fnolRequest.fnolDateConditionFirstTreated,
      fnolDateConditionNoticed: fnolRequest.fnolDateConditionNoticed,
      fnolDateOfTreatment: fnolRequest.fnolDateOfTreatment,
      fnolTreatedInSwitzerland: fnolRequest.fnolTreatedInSwitzerland,
      fnolInvoicedAmount: fnolRequest.fnolInvoicedAmount,
      fnolTreatmentFinished: fnolRequest.fnolTreatmentFinished,
      fnolThirdPartyFault: fnolRequest.fnolThirdPartyFault,
      fnolAdditionalInformation: fnolRequest.fnolAdditionalInformation,
      fnolDiagnoses: fnolRequest.fnolDiagnoses,
      fnolWhitelabelFlavor: fnolRequest.fnolWhitelabelFlavor
    });
  }
}

export interface Treatment {
  petName: string;
  date: string;
  diagnosis: string;
  positions: Array<Position>;
  subTotal?: number;
  contract: Contract | null;
  comments: Array<TreatmentCheckComment>;
  petNameValidationStatus?: InvoiceItemsCoverageCheckStatus;
  dateValidationStatus?: InvoiceItemsCoverageCheckStatus;
  invoiceRecipientValidationStatus?: InvoiceItemsCoverageCheckStatus;
  hasOverdueInvoices?: boolean;
  dateOverdueInvoicesChecked?: string;
  // mfvReserveClaimID?: string;
}

export interface TreatmentCheckComment {
  fieldName: string;
  comment: string;
  addedBy: 'system' | 'user';
}

export interface Position {
  name: string;
  quantity: string;
  vatPercentage: string;
  amount: number;
  classification: Partial<Classification> | null | undefined;
}

export interface PositionUI extends Position {
  copySource: boolean;
  copyTarget: boolean;
}

export interface ParsedInvoiceData {
  invoiceMetadata: {
    issuerName: string;
    issuerAddress: string;
    recipientName: string;
    recipientAddress: string;
    invoiceNumber: string;
    invoiceDate: string;
    language: string;
  },
  recognizedVet?: {
    value: boolean,
    needToSetReason: boolean,
  },
  treatments: Array<Treatment>,
  sumWithoutVat: number;
  sumWithVat: number;
  calculatedSum: number;
  calculationInformation?: VatAndDiscountCalculationInformation;
}

export interface FnolRequest extends FnolDetail {
  id: string;
  notifier: string;
  notificationMedium: string;
  notificationDate: string;
  ocrExtractionId: string;
  parsedInvoiceData: ParsedInvoiceData;
  processingStatus: FnolRequestStatus;
  processingAssignee: string;
  tasks: Task[];
  priority:number;
  invoiceDocument: {
    s3Id: string
  };
  mfvClaims?: Array<MfvClaim>;
  labels?: Array<TasksLabels>;
  mfvReserveClaimContractID: string;
  mfvReserveClaimID: string;
}

export enum TasksLabels {
  SIMPLE_CLAIM = 'simple_claim',
  MULTIPLE_CLAIMS = 'multiple_claims',
  WAITING = 'waiting',
  SIMPEGO = 'simpego',
}

export interface ExtractedDocument {
  inQueue: boolean;
  queueStatus: string;
  ocrError: boolean;
  ocrErrorMessage: string;
  parsedInvoiceData?: ParsedInvoiceData;
  errorMessage: string;
}

export enum TreatmentVerificationStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export interface TreatmentUI extends Treatment {
  expanded: boolean;
  positions: Array<PositionUI>;
}

export enum InvoiceItemsCoverageCheckStatus {
  NOT_NECESSARY = 'not_necessary',
  OPEN = 'open',
  PERFORMED = 'performed',
}

export interface VatAndDiscountCalculationInformation {
  vatContainedInPositions: boolean;
  totalInclVat: number;
  totalExclVat: number;
  vatAmountToDistribute: number;
  vatPercentageToDistribute: number;
  applicableDiscountAmount: number;
  forexFactor?: number;
}
