import {Component, Input} from '@angular/core';
import {Contract, ContractPreview, FnolRequest, GeneralConfirmationModalResult, ParsedInvoiceData, Treatment} from "../../../models";
import {MatDialog} from "@angular/material/dialog";
import {
  ContractAssignListModalComponent
} from "../../contract/contract-assign-list-modal/contract-assign-list-modal.component";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";
import { ReserveModalComponent } from '../reserve-modal/reserve-modal.component';
import {ApiHttpService} from "../../../services/api-http.service";
import { contractIdFromContractNumber } from 'src/app/helpers/utility';
import { LoadingOverlayRef, LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'CPF-invoice-assign-items',
  templateUrl: './invoice-assign-items.component.html',
  styleUrls: ['./invoice-assign-items.component.scss']
})
export class InvoiceAssignItemsComponent {
  @Input() fnolRequest: FnolRequest | undefined;

  contracts: Contract[] = [];

  @Input() set contractSelected(contractSelected: ContractPreview[]) {
    this.contracts = contractSelected.map(c => c.contracts).flat();
  }

  constructor(private dialog: MatDialog, private sidebarDetailsService: SidebarDetailsService, private apiService: ApiHttpService, private loadingService: LoadingService) {
  }

  findAssignedContract(fullContractNumber: string) {
    return this.contracts.find(c => c.full_contract_number === fullContractNumber);
  }

  assignContract(treatment: Treatment) {
    const dialogRef = this.dialog.open(ContractAssignListModalComponent, {
      data: {contracts: this.contracts},
      minWidth: '50%',
      width: '100%',
    });

    dialogRef.afterClosed().subscribe((result: Contract | null | 'empty') => {
      if (result) {
        if (result === 'empty') {
          treatment.contract = null;
          return;
        }

        treatment.contract = {...result};
      }
    });
  }

  async setReserve(treatment: Treatment) {
    console.log('passing contract to reserve modal:', treatment.contract)
    const dialogRef = this.dialog.open(ReserveModalComponent, {
      data: {
        contractId: contractIdFromContractNumber(treatment.contract?.contract_number),
        fullContractNumber: treatment.contract?.full_contract_number,
        claimDate: treatment.date,
        amount: this.fnolRequest?.parsedInvoiceData.calculationInformation?.totalInclVat,
        courseOfevents: this.fnolRequest?.fnolAdditionalInformation || '',
        contract: treatment.contract
      },
      minWidth: '50%',
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && this.fnolRequest) {
        const loadingRef: LoadingOverlayRef = this.loadingService.open();
        this.fnolRequest.initialReserveData = result
        try {
          const reserveResult = (await this.apiService.createInitialReserveMfv(this.fnolRequest))
          if (this.fnolRequest.initialReserveData) {
            this.fnolRequest.initialReserveData.claimID = reserveResult.claimID
            this.fnolRequest.initialReserveData.claimNumber = reserveResult.claimNumber
          }
        
          const res = await this.apiService.updateFnolRequest(this.fnolRequest);
        } catch (e) {
          console.error(`Error while creating reserves or saving the FNOL request: ${e}`);
        } finally {
          loadingRef.close();
        }
      }
    })
  }

  openContractDetailsSidebar(contract: Contract) {
    if (!contract) {
      return;
    }

    this.sidebarDetailsService.setContractDetails(contract);
    this.sidebarDetailsService.open();
  }

}
