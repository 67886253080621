import {Component, Input} from '@angular/core';
import {
  Contract,
  FnolRequest,
  InvoiceItemsCoverageCheckStatus,
  MfvClaimUIExpanded,
  Treatment,
  TreatmentCheckComment,
  TreatmentVerificationStatus
} from "../../../models";
import {getAddressCheckStatus, getDateCheckStatus, getPetNameCheckStatus, getRecipientNameCheckStatus} from "../../../helpers";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";
import {FnolCommentsModalComponent} from "../../fnol/fnol-comments-modal/fnol-comments-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'CPF-invoice-items-coverage-check',
  templateUrl: './invoice-items-coverage-check.component.html',
  styleUrls: ['./invoice-items-coverage-check.component.scss']
})
export class InvoiceItemsCoverageCheckComponent {
  public _fnolRequest?: FnolRequest;
  TreatmentVerificationStatus = TreatmentVerificationStatus;
  InvoiceItemsCoverageCheckStatus = InvoiceItemsCoverageCheckStatus;
  protected readonly treatmentVerificationStatus = TreatmentVerificationStatus;

  constructor(public sidebarDetailsService: SidebarDetailsService, private dialog: MatDialog) {
  }

  @Input()
  set fnolRequest(fnolRequest: FnolRequest) {
    this._fnolRequest = fnolRequest;

    if (!this._fnolRequest) {
      return
    }

    this._fnolRequest.parsedInvoiceData.treatments.forEach(treatment => {
      if (treatment.contract) {
        if (!treatment.invoiceRecipientValidationStatus || treatment.invoiceRecipientValidationStatus !== InvoiceItemsCoverageCheckStatus.PERFORMED) {
          const nameVerificationStatus = getRecipientNameCheckStatus(this._fnolRequest!, treatment.contract!);
          const addressVerificationStatus = getAddressCheckStatus(this._fnolRequest!, treatment.contract!);

          if (nameVerificationStatus === TreatmentVerificationStatus.OK && addressVerificationStatus === TreatmentVerificationStatus.OK) {
            treatment.invoiceRecipientValidationStatus = InvoiceItemsCoverageCheckStatus.NOT_NECESSARY;
          } else {
            treatment.invoiceRecipientValidationStatus = InvoiceItemsCoverageCheckStatus.OPEN
          }
        }

        if (!treatment.dateValidationStatus || treatment.dateValidationStatus !== InvoiceItemsCoverageCheckStatus.PERFORMED) {
          const dateVerificationStatus = getDateCheckStatus(treatment, treatment.contract);

          if (dateVerificationStatus === TreatmentVerificationStatus.OK) {
            treatment.dateValidationStatus = InvoiceItemsCoverageCheckStatus.NOT_NECESSARY;
          } else {
            treatment.dateValidationStatus = InvoiceItemsCoverageCheckStatus.OPEN;
          }
        }

        if (!treatment.petNameValidationStatus || treatment.petNameValidationStatus !== InvoiceItemsCoverageCheckStatus.PERFORMED) {
          const petNameVerificationStatus = getPetNameCheckStatus(this._fnolRequest!, treatment, treatment.contract);
          if (petNameVerificationStatus === TreatmentVerificationStatus.OK || petNameVerificationStatus === TreatmentVerificationStatus.WARNING) {
            treatment.petNameValidationStatus = InvoiceItemsCoverageCheckStatus.NOT_NECESSARY;
          } else {
            treatment.petNameValidationStatus = InvoiceItemsCoverageCheckStatus.OPEN;
          }
        }
      } else {
        treatment.invoiceRecipientValidationStatus = InvoiceItemsCoverageCheckStatus.NOT_NECESSARY;
        treatment.petNameValidationStatus = InvoiceItemsCoverageCheckStatus.NOT_NECESSARY;
        treatment.dateValidationStatus = InvoiceItemsCoverageCheckStatus.NOT_NECESSARY;
      }
    });
  }

  openFnolCommentsModal(comments: TreatmentCheckComment[], fieldName: string | undefined) {
    const dialogRef = this.dialog.open(FnolCommentsModalComponent, {
      data: {comments: comments, fieldName: fieldName}
    });
  }

  validateComponent() {
    if (!this._fnolRequest) {
      return false;
    }

    if (this._fnolRequest.parsedInvoiceData.recognizedVet === undefined) {
      return false;
    }

    return this._fnolRequest.parsedInvoiceData.treatments.every(treatment => {
      return treatment.petNameValidationStatus !== InvoiceItemsCoverageCheckStatus.OPEN && treatment.invoiceRecipientValidationStatus !== InvoiceItemsCoverageCheckStatus.OPEN;
    });
  }

  confirmRecipientComment(treatment: Treatment, id: string) {
    const element = document.getElementById(id) as HTMLTextAreaElement;
    const value = element?.value;

    if (treatment && value) {
      treatment.comments.push({fieldName: 'recipientName', comment: value, addedBy: 'user'});

      if (treatment.invoiceRecipientValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN) {
        treatment.invoiceRecipientValidationStatus = InvoiceItemsCoverageCheckStatus.PERFORMED;
      }

      element.value = '';
    }
  }

  confirmRecipientStandardComment(treatment: Treatment, id: string) {
    const element = document.getElementById(id) as HTMLTextAreaElement;

    if (treatment) {
      treatment.comments.push({fieldName: 'recipientName', comment: 'AUTOGENERATED: Empfängername und Adresse unterscheiden sich lediglich in der Schreibweise.', addedBy: 'user'});

      if (treatment.invoiceRecipientValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN) {
        treatment.invoiceRecipientValidationStatus = InvoiceItemsCoverageCheckStatus.PERFORMED;
      }

      element.value = '';
    }
  }

  openContractDetailsSidebar(contract: Contract) {
    if (!contract) {
      return;
    }

    this.sidebarDetailsService.setContractDetails(contract);
    this.sidebarDetailsService.open();
  }

  confirmPetNameComment(treatment: Treatment, id: string) {
    const element = document.getElementById(id) as HTMLTextAreaElement;
    const value = element?.value;

    if (treatment && value) {
      treatment.comments.push({fieldName: 'petName', comment: value, addedBy: 'user'});

      if (treatment.petNameValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN) {
        treatment.petNameValidationStatus = InvoiceItemsCoverageCheckStatus.PERFORMED;
      }

      element.value = '';
    }
  }

  confirmPetNameStandardComment(treatment: Treatment, id: string) {
    const element = document.getElementById(id) as HTMLTextAreaElement;

    if (treatment) {
      treatment.comments.push({fieldName: 'petName', comment: 'AUTOGENERATED: Haustiername unterscheidet sich lediglich in der Schreibweise.', addedBy: 'user'});

      if (treatment.petNameValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN) {
        treatment.petNameValidationStatus = InvoiceItemsCoverageCheckStatus.PERFORMED;
      }

      element.value = '';
    }
  }

  setRecognizedVet(value: boolean) {
    if (value) {
      this._fnolRequest!.parsedInvoiceData!.recognizedVet = {
        value: true,
        needToSetReason: false
      }
    } else {
      this._fnolRequest!.parsedInvoiceData!.recognizedVet = {
        value: false,
        needToSetReason: true
      }
    }
  }

  getButtonColor(b: boolean) {
    return this._fnolRequest!.parsedInvoiceData.recognizedVet && (this._fnolRequest!.parsedInvoiceData.recognizedVet.value == b) ? 'primary' : undefined;
  }

  getOverdueButtonColor(treatment: Treatment, b: boolean) {
    return treatment.hasOverdueInvoices === b ? 'primary' : undefined;
  }

  setOverdue(treatment: Treatment, b: boolean) {
    treatment.hasOverdueInvoices = b;
    //DD.MM.YYYY
    let date = new Date();
    let formatted = date.getDate().toString().padStart(2, '0') + "." + (date.getMonth() + 1).toString().padStart(2, '0') + "." + date.getFullYear();
    treatment.dateOverdueInvoicesChecked = formatted;
  }

  openContactInNewTab(contract: Contract) {
    window.open(this.buildContractLink(contract), '_blank');
  }

  buildContractLink(contract: Contract) {
    //https://webapp.simpego.ch/vorgaenge/vertraege/<SECOND_PART_OF_FULL_CONTRACT_NUMBER>
    return `https://webapp.simpego.ch/vorgaenge/vertraege/${contract.full_contract_number.split('-')[1]}`;
  }

  composeContractAddress(contract: Contract) {
    return `${contract.street} ${contract.houseNumber}, ${contract.city}, ${contract.zip}`;
  }
}
