import {Component, Input} from '@angular/core';
import {FnolRequest, InvoiceItemsCoverageCheckStatus, TreatmentCheckComment} from "../../../models";

@Component({
  selector: 'CPF-invoice-items-coverage-check-preview',
  templateUrl: './invoice-items-coverage-check-preview.component.html',
  styleUrls: ['./invoice-items-coverage-check-preview.component.scss']
})
export class InvoiceItemsCoverageCheckPreviewComponent {
  @Input() fnolRequest!: FnolRequest;

  expanded = false;

  filterCommentsByPetName(comments: TreatmentCheckComment[]): string[] {
    return comments.filter(c => c.addedBy === 'user' && c.fieldName === 'petName' && !c.comment.startsWith('AUTOGENERATED')).map(c => c.comment)
  }

  filterCommentsByRecipientName(comments: TreatmentCheckComment[]): string[] {
    return comments.filter(c => c.addedBy === 'user' && c.fieldName === 'recipientName' && !c.comment.startsWith('AUTOGENERATED')).map(c => c.comment)
  }

  validationStatusToMatIcon(status?: InvoiceItemsCoverageCheckStatus): string {
    if (status !== 'open') {
      return 'done'
    }
    return 'close'
  }

}
