import {ParsedInvoiceData} from "./fnol-request";

export class GeneralConfirmationModalData {
  title: string;
  message: string;
  confirmButton: GeneralConfirmationModalResult | undefined;
  customButton: GeneralConfirmationModalResult | undefined;
  cancelButton: GeneralConfirmationModalResult | undefined;

  constructor(title: string, message: string, confirmButton: GeneralConfirmationModalResult | undefined, cancelButton: GeneralConfirmationModalResult | undefined, customButton: GeneralConfirmationModalResult | undefined) {
    this.title = title;
    this.message = message;
    this.confirmButton = confirmButton;
    this.cancelButton = cancelButton;
    this.customButton = customButton;
  }
}

export enum GeneralConfirmationModalResult {
  None,
  Confirm,
  Custom,
  Cancel
}

export enum ReasonNotCovered {
  AdditionalCoverageNotInsured = "AdditionalCoverageNotInsured",
  CoverageInterrupt = "CoverageInterrupt",
  DamageBeforeContractStart = "DamageBeforeContractStart",
  DivisionNotInsured = "DivisionNotInsured",
  NoCoverageAccordingToGTC = "NoCoverageAccordingToGTC",
  Others = "Others",
  WithinDeductible = "WithinDeductible",
  WithinWaitingPeriod = "WithinWaitingPeriod",
}

export class ClassificationType {
  types: string[] = [];
  subTypes: string[] = [];
  causes: string[] = [];
  reasonsNotCovered: Map<ReasonNotCovered, string> = new Map<ReasonNotCovered, string>();

  constructor(types: string[], subTypes: string[], causes: string[], reasonsNotCovered: Map<ReasonNotCovered, string>) {
    this.types = types;
    this.subTypes = subTypes;
    this.causes = causes;
    this.reasonsNotCovered = reasonsNotCovered;
  }
}

export interface Task {
  openingDate: string;
  title: string;
  description: string; // can be updated over time
  resolution: string;
  closingDate: string;
  selected?: boolean;
  includeInComment: boolean;
}

export interface ExtractedDocumentResponse {
  inQueue: boolean;
  queueStatus: string;
  parsedInvoiceData: ParsedInvoiceData;
  errorMessage: string;
  ocrError: boolean;
  ocrErrorMessage: string;
}
