import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TreatmentCheckComment} from "../../../models";

@Component({
  selector: 'CPF-fnol-comments-modal',
  templateUrl: './fnol-comments-modal.component.html',
  styleUrls: ['./fnol-comments-modal.component.scss']
})
export class FnolCommentsModalComponent {
  comments: Array<TreatmentCheckComment> = [];
  fieldName: string | undefined;
  newComment: string = '';
  fields: { fieldName: string, comment: Array<TreatmentCheckComment> }[] = [];

  constructor(public dialogRef: MatDialogRef<FnolCommentsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    comments: Array<TreatmentCheckComment>,
    fieldName: string | undefined
  }) {
    this.comments = data.comments;
    this.fieldName = data.fieldName;

    this.fields = this.getCommentsToShow();
  }

  addComment() {
    if (this.fieldName) {
      this.comments.push({
        fieldName: this.fieldName,
        comment: this.newComment,
        addedBy: 'user'
      });
      this.newComment = '';
    }

    this.fields = this.getCommentsToShow();
  }

  getCommentsToShow(): { fieldName: string, comment: Array<TreatmentCheckComment>}[] {
    if (this.fieldName) {
      return [ { fieldName: this.fieldName, comment: this.comments.filter(c => c.fieldName === this.fieldName)
      }];
    }
    const fieldNameSet = new Set(this.comments.map(c => c.fieldName));

    return Array.from(fieldNameSet).map(fieldName => {
      return {
        fieldName,
        comment: this.comments.filter(c => c.fieldName === fieldName)
      };
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
