import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { log } from 'console';
import { Contract, InsuranceCoverage } from 'src/app/models';
import { InitialReserveData } from 'src/app/models/fnol-request';
import { DataStorageService } from 'src/app/services/data-storage.service';


@Component({
  selector: 'CPF-reserve-modal',
  templateUrl: './reserve-modal.component.html',
  styleUrls: ['./reserve-modal.component.scss']
})
export class ReserveModalComponent {

  contractId: number = 0
  fullContractNumber: string = ''
  type: string = ''
	claimDate: string = ''
	originalClaimDate: string = ''
	amount: number = 0
	originalAmount: number = 0
	courseOfEvents: string = ''
  contract: Contract | null = null

  public classificationType: any[] = []


  constructor(public dialogRef: MatDialogRef<ReserveModalComponent>, private dataService: DataStorageService, @Inject(MAT_DIALOG_DATA) public data: {
    contractId: number,
    fullContractNumber: string,
    claimDate: string,
    amount: number,
    courseOfevents: string,
    contract: Contract
    
  }) {
    this.contractId = data.contractId;
    this.fullContractNumber = data.fullContractNumber
    this.claimDate = data.claimDate;
    this.originalClaimDate = data.claimDate;
    this.amount = data.amount;
    this.originalAmount = data.amount;
    this.classificationType = this.dataService.classificationTypes.types;
    this.courseOfEvents = data.courseOfevents;
    this.contract = data.contract

    // this.checkClaimDateWithinContractDurationOrWaitingPeriod()
    this.setCommentOfClaimDateInCourseOfEvents()

  }

  checkClaimDateWithinContractDurationOrWaitingPeriod(): number {
    let claimDate = this.parseDate(this.claimDate)
    let contractBeginDate = this.parseDate(this.contract?.start_date)
    let contractEndDate = this.parseDate(this.contract?.end_date)

    if (claimDate === null) {
      console.log('claim date is null')
      return this.originalAmount
    }
    if (contractBeginDate !== null && claimDate < contractBeginDate) {
      console.log(`claim date ${this.formatDate(claimDate)} is before contract begin`)
      console.log(`setting claim date to ${this.formatDate(contractBeginDate)}`)
      this.setClaimDateAfterTimeout(contractBeginDate)
      this.courseOfEvents = "Behandlung vor Vertragsbegin\n\n" + this.courseOfEvents
      return 1
    }
    if (contractBeginDate !== null && claimDate >= contractBeginDate && this.isWithin30Days(contractBeginDate, claimDate) && this.type === 'krankheit') {
      console.log(`claim date ${this.formatDate(claimDate)} is in waiting period for given type`)
      return 1
    }
    if (contractEndDate !== null && claimDate > contractEndDate) {
      this.setClaimDateAfterTimeout(contractEndDate)
      console.log(`claim date ${this.formatDate(claimDate)} is after contract end`)
      console.log(`setting claim date to ${this.formatDate(contractEndDate)}`)
      this.courseOfEvents = "Behandlung nach Vertragsende\n\n" + this.courseOfEvents
      return 1
    }
    return this.originalAmount
  }

  setCommentOfClaimDateInCourseOfEvents() {
    let claimDate = this.parseDate(this.claimDate)
    let contractBeginDate = this.parseDate(this.contract?.start_date)
    let contractEndDate = this.parseDate(this.contract?.end_date)

    if (claimDate === null) {
      return
    }
    if (contractBeginDate !== null && claimDate < contractBeginDate) {
      this.courseOfEvents = "Behandlung vor Vertragsbegin\n\n" + this.courseOfEvents
      return
    }

    if (contractEndDate !== null && claimDate > contractEndDate) {
      this.courseOfEvents = "Behandlung nach Vertragsende\n\n" + this.courseOfEvents
      return
    }
  }

  formatDate(date: Date): string {
    return date.toISOString().split("T")[0]; // Extracts YYYY-MM-DD part
  }

  parseDate(dateStr: string | undefined): Date | null {
    if (!dateStr) {
      return null
    }
    const date = new Date(dateStr);
    return isNaN(date.getTime()) ? null : date;
  }

  public confirmReserve() {
    const ird: InitialReserveData = {
      contractID: this.contractId,
      fullContractNumber: this.fullContractNumber,
      type: this.type,
      claimDate: this.claimDate,
      amount: this.amount,
      courseOfEvents: this.courseOfEvents
    }
    this.dialogRef.close(ird);
  }

  onTypeChange(event: any) {
    console.log(this.contract)
    let type = event.value
    console.log("Selected type:", type);
    let sublimitCap = this.checkAmountWithinSublimitBasedOnType()
    let dateCap = this.checkClaimDateWithinContractDurationOrWaitingPeriod()
    this.amount = Math.min(sublimitCap, dateCap)
  }

  checkAmountWithinSublimitBasedOnType(): number {
    let type = this.type
    if (type === 'krankheit') {
      return this.capAtSumInsured(this.contract?.accident_and_illness_coverage, this.originalAmount)
    } else if (type === 'notfallbetreuung') {
      return this.capAtSumInsured(this.contract?.emergency_minding_coverage, this.originalAmount)
    } else if (type === 'praevention') {
      return this.capAtSumInsured(this.contract?.preventive_care_coverage, this.originalAmount)
    } else if (type === 'unfall') {
      return this.capAtSumInsured(this.contract?.accident_and_illness_coverage, this.originalAmount)
    } else {
      return this.originalAmount
    }
  }

  capAtSumInsured(coverage: InsuranceCoverage | undefined, amount: number) {
    if (!coverage) {
      console.log('coverage is undefined')
      return amount
    }

    if (coverage.coverage_selected === false) {
      console.log('coverage is not selected')
      return 1
    }

    if (coverage.sublimit_sum === undefined || coverage.sublimit_sum < 0 || coverage.sublimit_sum >= amount) {
      console.log('coverage sublimit is undefined or large enought to not matter, returning amount')
      return amount
    }
    console.log('returning coverage sublimit')
    return coverage.sublimit_sum
    
  }

  onDateInput(event: any) {
    let sublimitCap = this.checkAmountWithinSublimitBasedOnType()
    let dateCap = this.checkClaimDateWithinContractDurationOrWaitingPeriod()
    this.amount = Math.min(sublimitCap, dateCap)
  }

  isWithin30Days(earlierDate: Date, laterDate: Date): boolean {
    if (!earlierDate || !laterDate) return false; // Handle invalid/null values
  
    const diffInMs = laterDate.getTime() - earlierDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24); // Convert ms to days
  
    return diffInDays < 30;
  }

  setClaimDateAfterTimeout(date: Date | null) {
    setTimeout(() => {
      if (date !== null) {
        this.claimDate = this.formatDate(date)
      }
    }, 200)
  }

}
