<main>
  <div class="title-2">{{ 'contract-used-up-franchise.title' | translate }}</div>
  <div class="container" *ngFor="let contract of contracts">
    <div class="top-row">
      <div class="top-row-info info">
        <div>
          <p>{{ contract.full_contract_number }} ({{contract.pet_name}}), {{ 'contract-used-up-franchise.year' | translate }} {{ contract.full_contract_number_year_adjusted ? contract.full_contract_number_year_adjusted.charAt(contract.full_contract_number_year_adjusted.length - 1) : '-' }}</p>
        </div>
        <div>
          <a [href]="buildContractLink(contract)" target="_blank">{{ buildContractLink(contract) }}</a>
        </div>
      </div>
      <div class="actions">
        <mat-icon (click)="toggleExpandUsedUpFranchise(contract)" class="expand-button" [class.expand]="!contract.expanded">expand_less</mat-icon>
      </div>
    </div>
    <div class="form" *ngIf="contract.expanded">
      <mat-form-field>
        <mat-label>{{ 'contract-used-up-franchise.overall-limit' | translate }}</mat-label>
        <input [(ngModel)]="contract.used_up_sum_insured" matInput type="number"/>
      </mat-form-field>
      <mat-form-field *ngIf="isContractIncluded(contract, 'krankenhausaufenthalt_des_besitzers')">
        <mat-label>{{ 'contract-used-up-franchise.emergency-minding' | translate }}</mat-label>
        <input matInput [(ngModel)]="contract.emergency_minding_coverage.used_up_sum_insured" type="number"/>
      </mat-form-field>
      <mat-form-field *ngIf="isContractIncluded(contract, 'komplementaermedizinische_behandlung')">
        <mat-label>{{ 'contract-used-up-franchise.complementary-medicine' | translate }}</mat-label>
        <input [(ngModel)]="contract.illness_complementary_treatment_coverage.used_up_sum_insured" matInput type="number"/>
      </mat-form-field>
      <mat-form-field *ngIf="isContractIncluded(contract, 'praeventive_behandlungen')">
        <mat-label>{{ 'contract-used-up-franchise.preventive-care' | translate }}</mat-label>
        <input [(ngModel)]="contract.preventive_care_coverage.used_up_sum_insured" matInput type="number"/>
      </mat-form-field>
      <mat-form-field *ngIf="isContractIncluded(contract, 'medizinalfutter')">
        <mat-label>{{ 'contract-used-up-franchise.medicinal-food' | translate }}</mat-label>
        <input [(ngModel)]="contract.medical_food_coverage.used_up_sum_insured" matInput type="number"/>
      </mat-form-field>
      <mat-form-field *ngIf="isContractIncluded(contract, 'preexisting_conditions_coverage')">
        <mat-label>{{ 'contract-used-up-franchise.preexisting-conditions' | translate }}</mat-label>
        <input [(ngModel)]="contract.preexisting_conditions_coverage.used_up_sum_insured" matInput type="number"/>
      </mat-form-field>
      <mat-form-field *ngIf="isContractIncluded(contract, 'worldwide_protection_coverage')">
        <mat-label>{{ 'contract-used-up-franchise.worldwide-coverage' | translate }}</mat-label>
        <input [(ngModel)]="contract.worldwide_protection_coverage.used_up_sum_insured" matInput type="number"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'contract-used-up-franchise.used-up-franchise' | translate }}</mat-label>
        <input [(ngModel)]="contract.used_up_annual_franchise" matInput type="number"/>
      </mat-form-field>
    </div>
  </div>

  <div class="container" *ngFor="let cluster of preexistingConditionsClusterDeductibleMap.keys()">
    <mat-form-field>
      <mat-label>{{ 'contract-used-up-franchise.preexisting-conditions-cluster' | translate }} {{ cluster }}</mat-label>
      <input [ngModel]="preexistingConditionsClusterDeductibleMap.get(cluster)" (ngModelChange)="preexistingConditionsClusterDeductibleMap.set(cluster, $event)" matInput type="number"/>
    </mat-form-field>
  </div>
</main>
