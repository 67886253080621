import { Component, Input } from '@angular/core';
import { Contract } from "../../../models";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'CPF-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss']
})
export class ContractDetailsComponent {
  @Input()
  public contract: Contract | undefined;

  constructor(private datePipe: DatePipe) {
  }

  dateToString(date: string): string {
    let dateObj = new Date(date);
    if (dateObj.toString() === "Invalid Date" || dateObj.getTime() === 0) {
      return "";
    } else {
      return this.datePipe.transform(dateObj, 'yyyy-MM-dd') || "";
    }
  }

  dateToDMYString(date: string): string {
    let dateObj = new Date(date);
    if (dateObj.toString() === "Invalid Date" || dateObj.getTime() === 0) {
      return "";
    } else {
      return this.datePipe.transform(dateObj, 'dd.MM.yyyy') || "";
    }
  }
}
