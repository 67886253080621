<div class="title-2">{{ 'invoice-assign-items-preview.title-treatment-contract-assignment' | translate }}</div>

<div class="container" *ngIf="contracts.length && _fnolRequest.parsedInvoiceData">
  <div class="content-container" *ngIf="!expanded"></div>
  <div class="content-container" *ngIf="expanded">
    <div class="info-and-assign-container" *ngFor="let treatment of _fnolRequest.parsedInvoiceData.treatments">
      <div class="info-and-assign-details">
        <div class="small-info grid-info">
          <span>
            <mat-icon>pets</mat-icon><mat-icon>receipt_long</mat-icon>
            {{ treatment.petName }}
          </span>
          <span>
            <mat-icon>calendar_today</mat-icon>
            {{ treatment.date | date:'dd.MM.YYYY' }}
          </span>
          <span>
            <mat-icon>medical_services</mat-icon>
            {{ treatment.diagnosis }}
          </span>
          <ng-container class="small-info" *ngIf="treatment.contract">
            <span>
              <mat-icon>description</mat-icon>
              {{ treatment.contract.full_contract_number }}
              <mat-icon class="pointer" (click)="openContractDetailsSidebar(treatment.contract)">open_in_full</mat-icon>
            </span>
            <span>
              <mat-icon>pets</mat-icon><mat-icon>description</mat-icon>
              {{ treatment.contract.pet_name }}
            </span>
            <span>
              <mat-icon>person</mat-icon>
              {{ treatment.contract.firstname }} {{ treatment.contract.lastname }}
            </span>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!treatment.contract">
        <div class="no-contract-selected">
          {{ 'invoice-assign-items-preview.no-contract-selected' | translate }}
        </div>
      </div>
    </div>

  </div>
  <div class="actions">
    <mat-icon (click)="expanded = !expanded" class="expand-button" [class.expand]="!expanded">expand_less</mat-icon>
  </div>
</div>
