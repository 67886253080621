<h2>{{ 'vat-and-discount-preview.edit-vat-and-discount-info' | translate }}</h2>
<div class="details-with-title">
  <h4>{{ 'vat-and-discount-preview.vat-section-title' | translate }}</h4>
  <div class="info">
    <div>
      <label>{{ 'vat-and-discount-preview.vat-contained-in-positions' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.vatContainedInPositions | booleanTranslate}}</p>
    </div>
    <div>
      <label>{{ 'vat-and-discount-preview.total-including-vat' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.totalInclVat}}</p>
    </div>
    <div>
      <label>{{ 'vat-and-discount-preview.total-excluding-vat' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.totalExclVat}}</p>
    </div>
    <div>
      <label>{{ 'vat-and-discount-preview.vat-amount-to-distribute' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.vatAmountToDistribute | number:'1.2-2'}}</p>
    </div>
    <div>
      <label>{{ 'vat-and-discount-preview.vat-percentage-to-distribute' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.vatPercentageToDistribute | number:'1.2-4'}}</p>
    </div>
  </div>
</div>
<div class="details-with-title">
  <h4>{{ 'vat-and-discount-preview.details-section-title' | translate }}</h4>
  <div class="info">
    <div>
      <label>{{ 'vat-and-discount-preview.applicable-discount-amount' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.applicableDiscountAmount}}</p>
    </div>
  </div>
</div>



<div class="details-with-title">
  <h4>{{ 'vat-and-discount-preview.forex-factor-title' | translate }}</h4>
  <div class="info">
    <div>
      <label>{{ 'vat-and-discount-preview.forex-factor' | translate }}</label>
      <p>{{fnolRequest.parsedInvoiceData.calculationInformation?.forexFactor}}</p>
    </div>
  </div>

</div>

