<div class="contracts-container">
  <ng-container *ngIf="contracts.length > 0">
    <div class="contract">
      <div class="empty-contract">
        <div class="empty-contract-title">
          {{ 'contract-assign-list-modal.empty-contract-title' | translate }}
        </div>
        <div class="empty-contract-info">
          <p>{{ 'contract-assign-list-modal.empty-contract-info' | translate }}</p>
        </div>
      </div>
      <div class="actions">
        <button (click)="selectContract('empty')" color="primary" mat-raised-button>
          {{ 'contract-assign-list-modal.select-button' | translate }}
        </button>
      </div>
    </div>

    <div class="contract" *ngFor="let contract of contracts">
      <div class="info">
        <div>
          <label>{{ 'contract-assign-list-modal.pet-name' | translate }}</label>
          <p>{{ contract.pet_name }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.first-name' | translate }}</label>
          <p>{{ contract.firstname }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.last-name' | translate }}</label>
          <p>{{ contract.lastname }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.start-date' | translate }}</label>
          <p>{{ contract.start_date | date }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.end-date' | translate }}</label>
          <p>{{ contract.end_date | date }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.date-of-wish-to-cancel' | translate }}</label>
          <p>{{ contract.date_of_wish_to_cancel | date }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.cancellation-date' | translate }}</label>
          <p>{{ contract.cancellation_date | date }}</p>
        </div>
        <div>
          <label>{{ 'contract-assign-list-modal.status' | translate }}</label>
          <p>{{ getStatusTranslationKey(contract) }}</p>
        </div>
      </div>
      <div class="actions">
        <button (click)="selectContract(contract)" color="primary" mat-raised-button>
          {{ 'contract-assign-list-modal.select-button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
