import {Component, OnInit} from '@angular/core';
import {AuthenticatorMachineOptions} from "@aws-amplify/ui";
import {AuthenticatorService} from "@aws-amplify/ui-angular";
import {Router} from "@angular/router";
import {AccountService} from "../services/account.service";
import {DataStorageService} from "../services/data-storage.service";
import {ApiHttpService} from "../services/api-http.service";

@Component({
  selector: 'CPF-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  signUpFields: AuthenticatorMachineOptions['signUpAttributes'] = ['given_name', 'family_name', 'email'];
  public formFields = {
    signUp: {
      given_name: {
        order: 1
      },
      family_name: {
        order: 2
      },
      email: {
        order: 4
      },
      password: {
        order: 5
      },
      confirm_password: {
        order: 6
      }
    },
  }

  constructor(public authenticator: AuthenticatorService,
              public router: Router,
              public accountService: AccountService,
              public dataStorageService: DataStorageService,
              public apiHttpService: ApiHttpService) {
  }

  ngOnInit() {
    this.authenticator.subscribe(async (authState) => {
      if (authState.authStatus === 'authenticated') {
        this.accountService.handleFetchUserAttributes();

        if (this.dataStorageService.classificationTypes.types.length === 0 && this.dataStorageService.classificationTypes.subTypes.length === 0 && this.dataStorageService.classificationTypes.causes.length === 0) {
          this.dataStorageService.classificationTypes = await this.apiHttpService.getClassificationTypes();
          this.dataStorageService.loaded = true;
        }

        this.router.navigate(['platform', 'fnol-request-list']);
      }
    });
  }
}
