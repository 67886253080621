<h2>{{ 'find-contract.title'|translate }}</h2>
<h4>{{ 'find-contract.subtitle'|translate }}</h4>
<div class="search-form">
  <mat-form-field>
    <mat-label>{{ 'find-contract.contract-number'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.contract-number-placeholder'|translate }}" [(ngModel)]="search.contractNumber">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'find-contract.pet-name'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.pet-name-placeholder'|translate }}" [(ngModel)]="search.petName">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'find-contract.pet-birth-date'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.pet-birth-date-placeholder'|translate }}" [(ngModel)]="search.petBirthDate">
    <mat-hint>{{ 'find-contract.pet-birth-date-hint' |translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'find-contract.first-name'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.first-name-placeholder'|translate }}" [(ngModel)]="search.firstName">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'find-contract.last-name'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.last-name-placeholder'|translate }}" [(ngModel)]="search.lastName">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'find-contract.email'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.email-placeholder'|translate }}" [(ngModel)]="search.email">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'find-contract.phone'|translate }}</mat-label>
    <input matInput placeholder="{{ 'find-contract.phone-placeholder'|translate }}" [(ngModel)]="search.phone">
  </mat-form-field>

</div>

<button mat-raised-button color="primary" (click)="searchContracts()">{{ 'find-contract.search'|translate }}</button>

<div class="contracts-container">
  <ng-container *ngIf="contracts.length > 0; else noContracts">
    <div class="contract" *ngFor="let contract of contracts">
      <div class="info">
        <div class="contract-number">
          <label>{{ 'find-contract.contract-number' | translate }}</label>
          <p>{{ contract.contractNumberFirstPart }}</p>
        </div>

        <div class="pet-name">
          <label>{{ 'find-contract.pet-name' | translate }}</label>
          <p>{{ contract.pet_name }}</p>
        </div>

        <div class="first-name">
          <label>{{ 'find-contract.first-name' | translate }}</label>
          <p>{{ contract.firstname }}</p>
        </div>

        <div class="last-name">
          <label>{{ 'find-contract.last-name' | translate }}</label>
          <p>{{ contract.lastname }}</p>
        </div>

        <div class="email">
          <label>{{ 'find-contract.email' | translate }}</label>
          <p>{{ contract.email }}</p>
        </div>

        <div class="phone">
          <label>{{ 'find-contract.phone' | translate }}</label>
          <p>{{ contract.phoneNumber }}</p>
        </div>

        <div class="pet-birth-date">
          <label>{{ 'find-contract.pet-birth-date' | translate }}</label>
          <p>{{ contract.birthdate | date }}</p>
        </div>
      </div>
      <div class="actions">
        <div class="status" [class]="getStatusClass(contract)">{{ getStatusTranslationKey(contract) |translate }}</div>
        <button mat-raised-button color="primary" *ngIf="!isContractSelected(contract)"
                (click)="selectContract(contract)">{{ 'find-contract.select'|translate }}
        </button>
        <button mat-raised-button color="warn" *ngIf="isContractSelected(contract)"
                (click)="deselectContract(contract)">{{ 'find-contract.remove'|translate }}
        </button>
        <button mat-raised-button color="primary" (click)="detailsContract(contract)">{{ 'find-contract.details'|translate }}</button>
      </div>
    </div>
  </ng-container>
  <ng-template #noContracts>
    <div class="title-2 no-contracts">{{ 'find-contract.no-contracts'|translate }}</div>
  </ng-template>
</div>
