import {Contract} from "./contract";
import { Task } from "./utility";

export class CoveredPosition {
  name: string;
  quantity: number;
  vatPercentage: number;
  amount: number;

  constructor(name: string, quantity: number, vatPercentage: number, amount: number) {
    this.name = name;
    this.quantity = quantity;
    this.vatPercentage = vatPercentage;
    this.amount = amount;
  }
}

export class MfvClaim {
  mfvClaimID: string;
  // mfvReserveClaimID: string;
  cmsFnolRequestID: string;
  cmsClusterID: string;
  invoicePetName: string;
  claimDate: string;
  treatmentDate: string;
  notificationDate: string;
  type: string;
  subtype: string;
  cause: string;
  preexistingCondition: boolean;
  emergencyAbroad: boolean;
  chronicDisease: boolean;
  obligationsFulfilled: boolean;
  followUpTreatmentClaimID: string;
  followUpTreatmentClaim: any;
  followUpTreatmentCluster: string;
  furtherClarificationComment: string;
  covered: boolean;
  invoiceDate: string;
  reasonWhyNotCovered: string;
  courseOfEvents: string;
  genericComment: string;
  coveredPositions: CoveredPosition[];
  nonCoveredPositions: CoveredPosition[];
  contract: Contract;
  costCoveragePercentage: number;
  totalCoveredAmount: number;
  totalNonCoveredAmount: number;
  deductionYearlyFranchise: number;
  deductionPercentageDeductible: number;
  deductionPerClaimDeductible: number;
  deductionLimit: number;
  benefitAmount: number;
  commentForMfv: string;
  initialRemainingFranchise: number;
  initialRemainingLimit: number;
  tasks: Task[];
  coverageCheckComment: string;


  constructor(mfvClaimID: string, cmsFnolRequestID: string, cmsClusterID: string, invoicePetName: string, claimDate: string, treatmentDate: string, notificationDate: string, type: string, subtype: string, cause: string, preexistingCondition: boolean, emergencyAbroad: boolean, chronicDisease: boolean, obligationsFulfilled: boolean, followUpTreatmentClaimID: string, followUpTreatmentClaim: any, followUpTreatmentCluster: string, furtherClarificationComment: string, covered: boolean, reasonWhyNotCovered: string, courseOfEvents: string, genericComment: string, coveredPositions: any, nonCoveredPositions: any, contract: any, costCoveragePercentage: number, totalCoveredAmount: number, totalNonCoveredAmount: number, deductionYearlyFranchise: number, deductionPercentageDeductible: number, deductionPerClaimDeductible: number, deductionLimit: number, benefitAmount: number, commentForMfv: string, invoiceDate:string, initialRemainingFranchise: number, initialRemainingLimit: number, tasks: Task[], coverageCheckComment: string) {
    this.mfvClaimID = mfvClaimID;
    this.cmsFnolRequestID = cmsFnolRequestID;
    this.cmsClusterID = cmsClusterID;
    this.invoicePetName = invoicePetName
    this.claimDate = claimDate;
    this.treatmentDate = treatmentDate
    this.notificationDate = notificationDate;
    this.type = type;
    this.subtype = subtype;
    this.cause = cause;
    this.preexistingCondition = preexistingCondition;
    this.emergencyAbroad = emergencyAbroad;
    this.chronicDisease = chronicDisease;
    this.obligationsFulfilled = obligationsFulfilled;
    this.followUpTreatmentClaimID = followUpTreatmentClaimID;
    this.followUpTreatmentClaim = followUpTreatmentClaim;
    this.followUpTreatmentCluster = followUpTreatmentCluster;
    this.furtherClarificationComment = furtherClarificationComment;
    this.covered = covered;
    this.reasonWhyNotCovered = reasonWhyNotCovered;
    this.courseOfEvents = courseOfEvents;
    this.genericComment = genericComment;
    this.coveredPositions = coveredPositions;
    this.nonCoveredPositions = nonCoveredPositions;
    this.contract = contract;
    this.costCoveragePercentage = costCoveragePercentage;
    this.totalCoveredAmount = totalCoveredAmount;
    this.totalNonCoveredAmount = totalNonCoveredAmount;
    this.deductionYearlyFranchise = deductionYearlyFranchise;
    this.deductionPercentageDeductible = deductionPercentageDeductible;
    this.deductionPerClaimDeductible = deductionPerClaimDeductible;
    this.deductionLimit = deductionLimit;
    this.benefitAmount = benefitAmount;
    this.commentForMfv = commentForMfv;
    this.invoiceDate = invoiceDate;
    this.initialRemainingFranchise = initialRemainingFranchise;
    this.initialRemainingLimit = initialRemainingLimit;
    this.tasks = tasks;
    this.coverageCheckComment = coverageCheckComment;
  }
}

export type MfvClaimUIExpanded = MfvClaim & { expanded: boolean; expandedBig: boolean; };
