import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Amplify} from "aws-amplify";
import {fetchAuthSession} from "aws-amplify/auth";
import {get, post} from "aws-amplify/api";
import {ClassificationType, Contract, ExtractedDocumentResponse, FnolRequest, MfvClaim, ReserveResponseData} from "../models";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomSnackbarErrorComponent} from "../components/custom-snackbar-error/custom-snackbar-error.component";

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  BASE_URL = environment.apiBaseUrl;

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) {

    const amplifyConfig = Amplify.getConfig();

    // Maybe better to move this somewhere else, but here it's work
    Amplify.configure({
      ...amplifyConfig,
      API: {
        ...amplifyConfig.API,
        REST: {
          ...amplifyConfig.API?.REST,
          'download-file': {
            endpoint: `${this.BASE_URL}/download-file`,
            region: 'eu-central-1'
          },
          'upload-file': {
            endpoint: `${this.BASE_URL}/upload-file`,
            region: 'eu-central-1'
          },
          'find-contract': {
            endpoint: `${this.BASE_URL}/find-contract`,
            region: 'eu-central-1'
          },
          'calculate-payment': {
            endpoint: `${this.BASE_URL}/calculate-payment`,
            region: 'eu-central-1'
          },
          'no-auth-test': {
            endpoint: `${this.BASE_URL}/no-auth-test`,
            region: 'eu-central-1'
          },
          'get-users': {
            endpoint: `${this.BASE_URL}/get-users`,
            region: 'eu-central-1'
          },
          'find-fnol-request': {
            endpoint: `${this.BASE_URL}/find-fnol-request`,
            region: 'eu-central-1'
          },
          'get-classification-types': {
            endpoint: `${this.BASE_URL}/get-classification-types`,
            region: 'eu-central-1'
          },
          'get-fnol-request': {
            endpoint: `${this.BASE_URL}/get-fnol-request`,
            region: 'eu-central-1'
          },
          'update-fnol-request': {
            endpoint: `${this.BASE_URL}/update-fnol-request`,
            region: 'eu-central-1'
          },
          'generate-comment': {
            endpoint: `${this.BASE_URL}/generate-comment`,
            region: 'eu-central-1'
          },
          'extract-document': {
            endpoint: `${this.BASE_URL}/extract-document`,
            region: 'eu-central-1'
          },
          'get-extracted-document': {
            endpoint: `${this.BASE_URL}/get-extracted-document`,
            region: 'eu-central-1'
          },
          'create-initial-reserve-mfv': {
            endpoint: `${this.BASE_URL}/create-initial-reserve-mfv`,
            region: 'eu-central-1'
          },
          'duplicate-fnol-request': {
            endpoint: `${this.BASE_URL}/duplicate-fnol-request`,
            region: 'eu-central-1'
          }
        }
      }
    })
  }

  showSnackBarError(message: string) {
    this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
      data: {
        error: message
      }
    });
  }

  async getFileDownloadUrl(fnolRequestId: string, s3Id: string) {
    //`${this.BASE_URL}/download-file/${fnolRequestId}/${s3Id}`;
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'download-file',
        path: `/${fnolRequestId}/${s3Id}`,
        options: {
          headers: {
            Authorization: token
          }
        },
      }).response).body.json();
    } catch (e: any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as any;
  }

  async getFileUploadUrl(fnolRequestId: string, s3Id: string) {
    //`${this.BASE_URL}/upload-file/${fnolRequestId}/${s3Id}`;
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'upload-file',
        path: `/${fnolRequestId}/${s3Id}`,
        options: {
          headers: {
            Authorization: token
          }
        },
      }).response).body.json();
    } catch (e: any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as any;
  }

  async findContractAPI(params: {
    petName?: string | null,
    petBirthDate?: string | null,
    contractNumber?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null
  }) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let queryParameters: { [key: string]: string } = {}
    if (params.petName) queryParameters['petName'] = params.petName
    if (params.petBirthDate) queryParameters['petBirthDate'] = params.petBirthDate
    if (params.contractNumber) queryParameters['contractNumber'] = params.contractNumber
    if (params.firstName) queryParameters['firstName'] = params.firstName
    if (params.lastName) queryParameters['lastName'] = params.lastName
    if (params.email) queryParameters['email'] = params.email
    if (params.phone) queryParameters['phone'] = params.phone

    let res;
    try {
      res = (await get({
        apiName: 'find-contract',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          queryParams: queryParameters
        },
      }).response).body.json()
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as Contract[];
  }

  async calculatePaymentAPI(fnolRequest: FnolRequest) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await post({
        apiName: 'calculate-payment',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          body: JSON.stringify({
            fnolRequest,
          })
        },
      }).response).body.json()
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as MfvClaim[]
  }

  async getUsers() {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'get-users',
        path: '',
        options: {
          headers: {
            Authorization: token
          }
        },
      }).response).body.json()
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res
  }

  async findFnolRequestAPI(params: {
    progress?: string | null,
    assignee?: string | null,
    fnolFirstName?: string | null,
    fnolLastName?: string | null,
    fnolEmail?: string | null,
    fnolPetName?: string | null,
    firstDate?: string | null,
    lastDate?: string | null,
    oldestFirst?: boolean | null
    lastEvaluatedKey?: any
  }) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let queryParameters: { [key: string]: string } = {}
    if (params.progress) queryParameters['progress'] = String(params.progress)
    if (params.assignee) queryParameters['assignee'] = params.assignee
    if (params.fnolFirstName) queryParameters['fnolFirstName'] = params.fnolFirstName
    if (params.fnolLastName) queryParameters['fnolLastName'] = params.fnolLastName
    if (params.fnolEmail) queryParameters['fnolEmail'] = params.fnolEmail
    if (params.fnolPetName) queryParameters['fnolPetName'] = params.fnolPetName
    if (params.firstDate) queryParameters['firstDate'] = new Date(params.firstDate).toISOString()
    if (params.lastDate) {
      const tmpLastDate = new Date(params.lastDate)
      tmpLastDate.setUTCHours(24) // just set to the first instant of the next day to get everything including the lastDate
      queryParameters['lastDate'] = tmpLastDate.toISOString()
    }
    if (params.oldestFirst) queryParameters['oldestFirst'] = params.oldestFirst? 'true' : ''
    if (params.lastEvaluatedKey) queryParameters['lastEvaluatedKey'] = params.lastEvaluatedKey

    let res;
    try {
      res = (await get({
        apiName: 'find-fnol-request',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          queryParams: queryParameters
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as { fnolRequests: FnolRequest[]; lastEvaluatedKey: any }
  }

  async getClassificationTypes() {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'get-classification-types',
        path: '',
        options: {
          headers: {
            Authorization: token
          }
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as ClassificationType;
  }

  async getFnolRequest(fnolRequestId: string) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'get-fnol-request',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          queryParams: {
            fnolRequestId
          }
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as FnolRequest;
  }

  async updateFnolRequest(fnolRequest: FnolRequest) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await post({
        apiName: 'update-fnol-request',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          body: JSON.stringify({
            ...fnolRequest,
          })
        },
      }).response)
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res ? res.statusCode === 200 : false;
  }

  async generateComments(mfvClaims: MfvClaim[]) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await post({
        apiName: 'generate-comment',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          body: JSON.stringify({
            mfvClaims,
          })
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as MfvClaim[];
  }

  async extractDocument(fnolRequestId: string, s3Id: string) {
    //`${this.BASE_URL}/download-file/${fnolRequestId}/${s3Id}`;
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'extract-document',
        path: `/${fnolRequestId}/${s3Id}`,
        options: {
          headers: {
            Authorization: token
          }
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as any;
  }

  async getExtractedDocument(documentId: string) {
    //`${this.BASE_URL}/download-file/${fnolRequestId}/${s3Id}`;
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await get({
        apiName: 'get-extracted-document',
        path: `/${documentId}`,
        options: {
          headers: {
            Authorization: token
          }
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as ExtractedDocumentResponse;
  }

  async createInitialReserveMfv(fnolRequest: FnolRequest) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await post({
        apiName: 'create-initial-reserve-mfv',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          body: JSON.stringify({
            ...fnolRequest,
          })
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as ReserveResponseData
  }

  async duplicateFnolRequest(id: string) {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString() || ''

    let res;
    try {
      res = (await post({
        apiName: 'duplicate-fnol-request',
        path: '',
        options: {
          headers: {
            Authorization: token
          },
          body: {
            id: id,
          }
        },
      }).response).body.json();
    } catch (e:any) {
      this.showSnackBarError(e.message || 'Unknown error');
    }

    return res as unknown as FnolRequest
  }
}
