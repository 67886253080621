import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Contract} from "../../../models";

@Component({
  selector: 'CPF-contract-details-modal',
  templateUrl: './contract-details-modal.component.html',
  styleUrls: ['./contract-details-modal.component.scss']
})
export class ContractDetailsModalComponent {
  public contract: Contract | undefined;
  constructor(public dialogRef: MatDialogRef<ContractDetailsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { contract: Contract }) {
    this.contract = data.contract;
  }
}
