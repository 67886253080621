import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GeneralConfirmationModalData, GeneralConfirmationModalResult} from "../../models";

@Component({
  selector: 'CPF-general-confirmation-modal',
  templateUrl: './general-confirmation-modal.component.html',
  styleUrls: ['./general-confirmation-modal.component.scss']
})
export class GeneralConfirmationModalComponent {
  public GeneralConfirmationModalResult = GeneralConfirmationModalResult;
  constructor(public dialogRef: MatDialogRef<GeneralConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GeneralConfirmationModalData
  ) { }

  close(result: GeneralConfirmationModalResult) {
    this.dialogRef.close(result);
  }
}
