import {Component, Input} from '@angular/core';
import {FnolRequest} from "../../../models";

@Component({
  selector: 'CPF-vat-and-discount-details',
  templateUrl: './vat-and-discount-details.component.html',
  styleUrls: ['./vat-and-discount-details.component.scss']
})
export class VATAndDiscountDetailsComponent {
  _fnolRequest!: FnolRequest;

  @Input() set fnolRequest(value: FnolRequest) {
    if (value.parsedInvoiceData) {
      value.parsedInvoiceData.calculationInformation = value.parsedInvoiceData.calculationInformation || {
        vatContainedInPositions: false,
        totalInclVat: 0,
        totalExclVat: 0,
        vatAmountToDistribute: 0,
        vatPercentageToDistribute: 0,
        applicableDiscountAmount: 0
      }
    } 

    this._fnolRequest = value;

    if (!this._fnolRequest.parsedInvoiceData.calculationInformation!.vatAmountToDistribute && !this._fnolRequest.parsedInvoiceData.calculationInformation!.vatPercentageToDistribute && !this._fnolRequest.parsedInvoiceData.calculationInformation!.vatAmountToDistribute) {
      this.calculateToDistribute();
    }
  }

  constructor() {
  }

  calculateToDistribute() {
    if (!this._fnolRequest || !this._fnolRequest.parsedInvoiceData.calculationInformation) {
      return;
    }

    this._fnolRequest.parsedInvoiceData.calculationInformation.vatAmountToDistribute = this._fnolRequest.parsedInvoiceData.calculationInformation.totalInclVat - this._fnolRequest.parsedInvoiceData.calculationInformation.totalExclVat;
    this._fnolRequest.parsedInvoiceData.calculationInformation.vatPercentageToDistribute = +(this._fnolRequest.parsedInvoiceData.calculationInformation.vatAmountToDistribute / this._fnolRequest.parsedInvoiceData.calculationInformation.totalExclVat).toFixed(4);
    this._fnolRequest.parsedInvoiceData.calculationInformation.vatAmountToDistribute = +this._fnolRequest.parsedInvoiceData.calculationInformation.vatAmountToDistribute.toFixed(2);
  }
}
