import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ContactSearch, Contract, ContractPreview, ContractPreviewUIStatus, ContractStatus} from "../../../models";
import {ApiHttpService} from "../../../services/api-http.service";
import {ContractDetailsModalComponent} from "../contract-details-modal/contract-details-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ContractPreviewSelectModalComponent} from "../contract-preview-select-modal/contract-preview-select-modal.component";
import {convertContractsToContractPreview} from "../../../helpers";

@Component({
  selector: 'CPF-find-contract',
  templateUrl: './find-contract.component.html',
  styleUrls: ['./find-contract.component.scss']
})
export class FindContractComponent {
  @Input()
  public isExpanded: boolean = true;

  @Input() contractSelected: ContractPreview[] = [];

  @Output()
  public contractsSelected: EventEmitter<ContractPreview> = new EventEmitter<ContractPreview>();

  @Output()
  public contractDeselected: EventEmitter<ContractPreview> = new EventEmitter<ContractPreview>();

  public search: ContactSearch = {
    petName: 'Amali',
    petBirthDate: '',
    contractNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }

  contracts: ContractPreviewUIStatus[] = [];

  constructor(private apiService: ApiHttpService, private dialog: MatDialog) {
  }

  @HostBinding('class.expanded')
  get expanded() {
    return this.isExpanded;
  }


  @Input()
  public set searchValue(value: Partial<ContactSearch>) {
    this.search = {...this.search, ...value};
    this.searchContracts();
  }

  public searchContracts() {
    //Check if search is not empty and at least 2 chars
    if (!Object.values(this.search).some(value => value && value.length > 1)) {
      return;
    }

    this.apiService.findContractAPI(this.search).then(r => {
      if (r) {
        const contracts = convertContractsToContractPreview(r as unknown as Contract[]);

        this.contracts = contracts.map(c => ({
          ...c,
          status: c.contracts.some(contract => contract.status === ContractStatus.ACTIVE) ? ContractStatus.ACTIVE : ContractStatus.EXPIRED
        })) as ContractPreviewUIStatus[];
      } else {
        this.contracts = [];
      }
    })
  }

  getStatusClass(contract: ContractPreviewUIStatus) {
    return contract.status === ContractStatus.ACTIVE ? 'contract-active' : 'contract-expired';
  }

  getStatusTranslationKey(contract: ContractPreviewUIStatus) {
    return contract.status === ContractStatus.ACTIVE ? 'find-contract.status.active' : 'find-contract.status.expired';
  }

  public selectContract(contractPreview: ContractPreview) {
    this.contractsSelected.emit(contractPreview);
  }

  public deselectContract(contractPreview: ContractPreview) {
    this.contractDeselected.emit(contractPreview);
  }

  public detailsContract(previewContract: ContractPreview) {
    if (previewContract.contracts.length === 1) {
      this.dialog.open(ContractDetailsModalComponent, {
        data: {contract: previewContract.contracts[0]},
        minWidth: '50%',
        width: '100%',
      });
    } else {
      const dialogRef = this.dialog.open(ContractPreviewSelectModalComponent, {
        data: {contractPreviews: previewContract.contracts},
        minWidth: '50%',
        width: '100%',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialog.open(ContractDetailsModalComponent, {
            data: {contract: result},
            minWidth: '50%',
            width: '100%',
          });
        }
      });
    }
  }

  isContractSelected(contract: ContractPreview) {
    return this.contractSelected.some(c => c.contractNumberFirstPart === contract.contractNumberFirstPart);
  }
}
