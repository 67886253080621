<h3>{{ 'fnol-comments-modal.comments-title' | translate }}</h3>

<div class="fields-container">
  <div class="field" *ngFor="let field of fields">
    <div class="field-name">
      <span>{{ field.fieldName }}</span>
    </div>

    <ul class="comments-container">
      <li class="comment" *ngFor="let comment of field.comment">
        <div class="comment-text">
          <span>{{ comment.comment }}</span>
        </div>
      </li>
    </ul>

    <!--<mat-form-field>
      <mat-label>Add comment</mat-label>
      <input matInput [(ngModel)]="newComment"/>
      <button mat-button matSuffix (click)="addComment()">Add</button>
    </mat-form-field>-->
  </div>
</div>
