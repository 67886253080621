export class CognitoAuthenticatedUser {
  sub?: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  email: string;

  constructor(email_verified: boolean, given_name: string, family_name: string, email: string, sub?: string) {
    this.sub = sub;
    this.email_verified = email_verified;
    this.given_name = given_name;
    this.family_name = family_name;
    this.email = email;
  }
}
