import {Component, Input} from '@angular/core';
import {Contract, ContractPreview, Treatment, TreatmentUI} from "../../../models";

@Component({
  selector: 'CPF-classification-list-preview',
  templateUrl: './classification-list-preview.component.html',
  styleUrls: ['./classification-list-preview.component.scss']
})
export class ClassificationListPreviewComponent {
  treatments: TreatmentUI[] = [];
  contracts: Contract[] = [];

  @Input() set treatmentsValue(treatments: Treatment[]) {
    this.treatments = treatments.map(t => (
        {
          ...t,
          expanded: false,
          positions: t.positions.map(p => (
            {
              ...p,
              copySource: false,
              copyTarget: false,
              classification: p.classification || {}
            }
          ))
        }
      )
    );
  }

  @Input() set contractSelected(contractSelected: ContractPreview[]) {
    this.contracts = contractSelected.map(c => c.contracts).flat();
  }

  toggleExpandTreatment(treatment: TreatmentUI) {
    treatment.expanded = !treatment.expanded;
  }
}
