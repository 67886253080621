import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FnolRequest, fnolRequestExample} from "../../../models";
import {jsPDF} from 'jspdf';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'CPF-fnol-details-preview',
  templateUrl: './fnol-details-preview.component.html',
  styleUrls: ['./fnol-details-preview.component.scss']
})
export class FnolDetailsPreviewComponent {
  @Input()
  public fnolRequest: FnolRequest | undefined = fnolRequestExample;

  @Output()
  searchContract = new EventEmitter<{ fieldName: string, value: string }[]>();

  expanded: boolean = true;

  constructor(private datePipe: DatePipe) {
  }

  search(fieldName: string, value: string) {
    this.searchContract.emit([{fieldName, value}]);
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  generatePDF() {
    const doc = new jsPDF();

    // Define mappings from JSON keys to label texts
    const mappings: { [key: string]: string } = {
      notificationDate: 'Meldedatum',
      fnolFirstName: 'Vorname',
      fnolLastName: 'Nachname',
      fnolEmail: 'Email',
      fnolPhoneNumber: 'Telefonnummer',
      fnolPreferredChannel: 'Bevorzugter Kommunikationskanal',
      fnolLang: 'Sprache',
      fnolIban: 'IBAN',
      fnolPetName: 'Haustiername',
      fnolConditionPreviouslyTreated: 'Wurde das Leiden bereits zuvor behandelt',
      fnolDateConditionFirstTreated: 'Datum der ersten Behandlung',
      fnolDateConditionLastTreated: 'Datum der letzten Behandlung',
      fnolDateConditionNoticed: 'Datum wann das Leiden bemerkt wurde',
      fnolDateOfTreatment: 'Datum der Behandlung',
      fnolThirdPartyFault: 'War eine Drittpartei verantwortlich',
      fnolAdditionalInformation: 'Weitere Informationen',
    };

    let y = 10; // Vertical position on the PDF
    const lineHeight = 10;

    if (!this.fnolRequest) {
      console.warn('cannot create pdf of fnol request data as fnolRequest is null or undefined')
      return
    }

    // Go through each key-value pair in the data and map to the PDF
    for (const [key, label] of Object.entries(mappings)) {
      const value = this.fnolRequest[key as keyof FnolRequest];
      let displayValue = value == null ? '-' : value.toString();

      // Convert booleans to "Ja" or "Nein"
      if (typeof value === 'boolean') {
        displayValue = value ? 'Ja' : 'Nein';
      }
      // Convert -1 or "--" for specific fields as needed
      if (value === -1 || value === "--") {
        displayValue = '--';
      }

      // Wrap the value text if it's too long
      const wrappedText = doc.splitTextToSize(`${label}: ${displayValue}`, 180);
      doc.text(wrappedText, 10, y);
      y += wrappedText.length * lineHeight;
    }

    // Handling the Diagnosen array
    doc.text('Diagnosen:', 10, y);
    y += lineHeight;
    if (this.fnolRequest.fnolDiagnoses && this.fnolRequest.fnolDiagnoses.length > 0) {
      this.fnolRequest.fnolDiagnoses.forEach(diagnosis => {
        const diagnosisText = `- ${diagnosis.id} (Category: ${diagnosis.category})`;
        const wrappedDiagnosis = doc.splitTextToSize(diagnosisText, 180);
        doc.text(wrappedDiagnosis, 20, y); // Indent for readability
        y += wrappedDiagnosis.length * lineHeight;
      });
    } else {
      doc.text('-', 20, y);
      y += lineHeight;
    }

    // Save the PDF
    let docName = 'claim-details_' + this.fnolRequest.fnolFirstName + '_' + this.fnolRequest.fnolPetName + '_' + this.fnolRequest.notificationDate
    doc.save(docName);
  }

  isoDateToEuro(date: string): string {
    let dateObj = new Date(date);
    if (dateObj.toString() === "Invalid Date" || dateObj.getTime() === 0) {
      return "";
    } else {
      return this.datePipe.transform(dateObj, 'dd.MM.yyyy') || "";
    }
  }

}
