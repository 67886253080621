import {Component, Input} from '@angular/core';
import {ContractPreview, ContractPreviewUIExpandable} from "../../../models";

@Component({
  selector: 'CPF-contract-details-preview',
  templateUrl: './contract-details-preview.component.html',
  styleUrls: ['./contract-details-preview.component.scss']
})
export class ContractDetailsPreviewComponent {
  @Input()
  contracts: ContractPreviewUIExpandable[] = [];

  constructor() {
  }

  toggleExpand(contract: ContractPreviewUIExpandable) {
    contract.expanded = !contract.expanded;
  }
}
