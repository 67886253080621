import {Component, Input} from '@angular/core';
import {FnolRequest, Task} from "../../../models";

@Component({
  selector: 'CPF-task-list-fnol-request',
  templateUrl: './task-list-fnol-request.component.html',
  styleUrls: ['./task-list-fnol-request.component.scss']
})
export class TaskListFnolRequestComponent {
  @Input() fnolRequest!: FnolRequest;

  selectedTask?: Task;

  constructor() {
  }

  selectTask(task: Task) {
    this.fnolRequest.tasks.forEach(t => t.selected = false);
    task.selected = true;
    this.selectedTask = JSON.parse(JSON.stringify(task));
  }

  isTaskClosed(task: Task) {
    return !!task.closingDate;
  }

  resolveTask() {
    this.selectedTask!.closingDate = new Date().toISOString();
  }

  reopenTask() {
    this.selectedTask!.closingDate = "";
  }

  confirmTask() {
    const selectedTask = this.fnolRequest.tasks.find(t => t.selected);
    if (selectedTask) {
      selectedTask.selected = false;
      selectedTask.closingDate = this.selectedTask!.closingDate;
      selectedTask.title = this.selectedTask!.title;
      selectedTask.description = this.selectedTask!.description;
      selectedTask.openingDate = this.selectedTask!.openingDate;
      selectedTask.resolution = this.selectedTask!.resolution;
      selectedTask.includeInComment = this.selectedTask!.includeInComment;
      this.selectedTask = undefined;
    } else {
      this.selectedTask!.selected = false;
      this.fnolRequest.tasks.push(this.selectedTask!);
      this.selectedTask = undefined;
    }
  }

  closeTask() {
    this.fnolRequest.tasks.forEach(t => t.selected = false);
    this.selectedTask = undefined;
  }

  createNewTask() {
    this.fnolRequest.tasks.forEach(t => t.selected = false);

    this.selectedTask = {
      title: "",
      description: "",
      openingDate: new Date().toISOString(),
      closingDate: "",
      resolution: "",
      selected: true,
      includeInComment: false
    }
  }
}
