import {Component, Input} from '@angular/core';
import {FnolRequest} from "../../../models";

@Component({
  selector: 'CPF-vat-and-discount-preview',
  templateUrl: './vat-and-discount-preview.component.html',
  styleUrls: ['./vat-and-discount-preview.component.scss']
})
export class VatAndDiscountPreviewComponent {
  @Input() fnolRequest!: FnolRequest;
}
