import {Component, Inject} from '@angular/core';
import {ClaimInfo} from "../../../models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'CPF-contract-claims-list-modal',
  templateUrl: './contract-claims-list-modal.component.html',
  styleUrls: ['./contract-claims-list-modal.component.scss']
})
export class ContractClaimsListModalComponent {
  claims: ClaimInfo[] | undefined;

  constructor(public dialogRef: MatDialogRef<ContractClaimsListModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { claims: ClaimInfo[] }) {
    this.claims = data.claims;
  }

  claimSelected(claimId: string): void {
    this.dialogRef.close(claimId);
  }
}
