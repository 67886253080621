export interface Classification {
  type: string;
  subtype: string;
  cause: string;
  clusterAssignment: string;
  followUpTreatment: boolean;
  followUpTreatmentClaim: string;
  followUpTreatmentCluster: string;
  preexistingCondition: boolean;
  emergencyAbroad: boolean;
  chronicDisease: boolean;
  obligationsFulfilled: boolean;
  furtherClarificationComment: string;
  covered: boolean;
  reasonNotCovered: string;
  courseOfEvents: string;
  genericComment: string;
  preexistingConditionsUsedUpDeductible: number | undefined
  accidentDate: string | null
  ignoreWaitingPeriod: boolean
}

export enum ClassificationReasonNotCovered {
  WithinDeductible = 'within-deductible',
  WithinWaitingPeriod = 'within-waiting-period',
  NoCoverageAccordingToGTC = 'no-coverage-according-to-gtc',
  DamageBeforeContractStart = 'damage-before-contract-start',
  CoverageInterrupt = 'coverage-interrupt',
  DivisionNotInsured = 'division-not-insured',
  AdditionalCoverageNotInsured = 'additional-coverage-not-insured',
  Others = 'others',
}
