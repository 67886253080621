<main>
  <div class="logo-container">
    <img src="assets/logo.svg" alt="logo" class="logo">
  </div>

  <div class="header-placeholder"></div>

  <div>
    <button (click)="goToFnolRequestList()" *ngIf="user$ | async" mat-raised-button>{{ 'header.go-to-list' | translate }}</button>
  </div>

  <div class="user-info">
    <div class="user-info" *ngIf="user$ | async as user else noUser">
      {{ 'header.user'| translate: {name: user?.given_name + ' ' + user?.family_name} }}
    </div>

    <ng-template #noUser>
      <div class="user-info">
        {{ ('header.no-user' | translate) }}
      </div>
    </ng-template>

    <div class="logout" *ngIf="user$ | async">
      <button class="logout" mat-raised-button (click)="logout()">{{ 'header.logout' | translate }}</button>
    </div>

    <div class="lang-container">
      <p class="active-lang">{{ currentLang }}</p>

      <div class="lang-list">
        <div (click)="changeLang(lang)" *ngFor="let lang of availableLangs" class="lang">{{ lang }}</div>
      </div>
    </div>
  </div>
</main>
