<!--
    <h3>{{ 'reserve-modal.title' | translate }}</h3>
-->

<div class="container">
    <mat-form-field>
        <mat-label>{{ 'reserve-modal.contract-number'|translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="this.contractId">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'reserve-modal.claim-type' | translate }}</mat-label>
        <mat-select [(ngModel)]="this.type" (selectionChange)="onTypeChange($event)">
          <mat-option *ngFor="let type of classificationType" [value]="type">{{ type }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'reserve-modal.amount'|translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="this.amount">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label> {{ 'reserve-modal.claim-date' | translate }}</mat-label>
        <input matInput [(ngModel)]="this.claimDate" [matDatepicker]="pickerClaimDate"
               (focus)="pickerClaimDate.open()" (dateInput)="onDateInput($event)">
        <mat-datepicker-toggle matPrefix [for]="pickerClaimDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerClaimDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="multiline">
        <mat-label>{{ 'reserve-modal.course-of-events'|translate }}</mat-label>
        <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="8"
            [(ngModel)]="this.courseOfEvents"></textarea>
    </mat-form-field>

</div>

<div class="actions">
    <button (click)="confirmReserve()" color="primary" mat-raised-button>
      {{ 'reserve-modal.confirm-button' | translate }}
    </button>
</div>