<CPF-header></CPF-header>
<mat-drawer-container class="drawer-container">
  <div class="scrollable">
    <router-outlet></router-outlet>
  </div>

  <mat-drawer #drawer class="drawer-rightside" mode="side" position="end">
    <div class="drawer-actions">
      <button mat-icon-button (click)="drawer.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <ng-container [ngSwitch]="sidebarType">
      <CPF-contract-details *ngSwitchCase="'contract'" [contract]="selectedContractDetails"></CPF-contract-details>

      <ng-container *ngSwitchCase="'fnolRequest'">
        <CPF-fnol-request *ngIf="selectedFnolRequest" [fnolRequest]="selectedFnolRequest" [editable]="false"></CPF-fnol-request>
      </ng-container>

      <ng-container *ngSwitchCase="'mfvClaimPositions'">
        <CPF-mfv-positions-details *ngIf="selectedMfvClaimPositions" [positions]="selectedMfvClaimPositions"></CPF-mfv-positions-details>
      </ng-container>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
