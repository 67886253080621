<main>
  <div class="title-2">{{ 'classification-list-preview.title' | translate }}</div>
  <div class="container" *ngFor="let treatment of treatments">
    <ng-container *ngIf="treatment.contract as selectedContract">
      <div class="top-row">
        <div class="top-row-info info">
          <div>
            <label>{{ 'classification-list-preview.pet-name' | translate }}</label>
            <p>{{ treatment.petName }}</p>
          </div>
          <div>
            <label>{{ 'classification-list-preview.date' | translate }}</label>
            <p>{{ treatment.date | date:'dd.MM.YYYY' }}</p>
          </div>
          <div>
            <label>{{ 'classification-list-preview.contract' | translate }}</label>
            <p>{{ selectedContract.full_contract_number }}</p>
          </div>
        </div>
        <div class="actions">
          <mat-icon (click)="toggleExpandTreatment(treatment)" class="expand-button" [class.expand]="!treatment.expanded">expand_less</mat-icon>
        </div>
      </div>
      <div class="treatment-list" *ngIf="treatment.expanded">
        <div class="treatment" *ngFor="let position of treatment.positions">
          <div class="info info-treatment">
            <div>
              <label>{{ 'classification-list-preview.position-name' | translate }}</label>
              <p>{{ position.name }}</p>
            </div>
          </div>
          <ng-container *ngIf="position.classification">
            <div class="info info-treatment">
              <div>
                <label>{{ 'classification-list-preview.classification-cause' | translate }}</label>
                <p>{{ position.classification.cause }}</p>
              </div>
            </div>
            <div class="info info-treatment">
              <div>
                <label>{{ 'classification-list-preview.classification-follow-up-treatment' | translate }}</label>
                <p>{{ position.classification.followUpTreatment | booleanTranslate }}</p>
              </div>
            </div>
            <div class="info info-treatment">
              <div *ngIf="position.classification.followUpTreatment">
                <label>{{ 'classification-list-preview.classification-follow-up-treatment-claim' | translate }}</label>
                <p>{{ position.classification.followUpTreatmentClaim }}</p>
              </div>
            </div>
            <div class="info info-treatment">
              <div *ngIf="position.classification.followUpTreatment">
                <label>{{ 'classification-list-preview.classification-follow-up-treatment-cluster' | translate }}</label>
                <p>{{ position.classification.followUpTreatmentCluster }}</p>
              </div>
            </div>
            <div class="info info-treatment">
              <div>
                <label>{{ 'classification-list-preview.classification-covered' | translate }}</label>
                <p>{{ position.classification.covered | booleanTranslate }}</p>
              </div>
              <div *ngIf="!position.classification.covered">
                <label>{{ 'classification-list-preview.classification-reason-not-covered' | translate }}</label>
                <p>{{ position.classification.reasonNotCovered }}</p>
              </div>
            </div>
            <div>
              <label>{{ 'classification-list-preview.classification-cluster' | translate }}</label>
              <p>{{ position.classification.clusterAssignment }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</main>
