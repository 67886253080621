<div class="contracts-container">
  <div class="title-1">
    {{ 'contract-preview.title' | translate:{petName, firstName, lastName} }}
  </div>

  <ng-container *ngIf="contracts.length > 0">
    <div class="contract" *ngFor="let contract of contracts">
      <div class="info">
        <div class="pet-birth-date">
          <label>{{ 'contract-preview.start-date' | translate }}</label>
          <p>{{ contract.start_date | date }}</p>
        </div>

        <div class="pet-birth-date">
          <label>{{ 'contract-preview.end_date' | translate }}</label>
          <p>{{ contract.end_date | date }}</p>
        </div>

        <div class="pet-birth-date">
          <label>{{ 'contract-preview.date_of_wish_to_cancel' | translate }}</label>
          <p>{{ contract.date_of_wish_to_cancel | date }}</p>
        </div>

        <div class="pet-birth-date">
          <label>{{ 'contract-preview.cancellation_date' | translate }}</label>
          <p>{{ contract.cancellation_date | date }}</p>
        </div>
      </div>
      <div class="actions">
        <div class="status" [class]="getStatusClass(contract)">{{ getStatusTranslationKey(contract) |translate }}</div>
        <button mat-raised-button color="primary" (click)="selectContract(contract)">{{ 'contract-preview.select'|translate }}</button>
      </div>
    </div>
  </ng-container>
</div>
