// @ts-nocheck
import {FnolRequest} from "./fnol-request";

export const fnolRequestExample: FnolRequest = {
  "id": "test-key-1",
  "notifier": "customer",
  "notificationMedium": "fnol",
  "notificationDate": "2023-02-05",
  "processingStatus": 0,
  "processingAssignee":"pablo.lorenceau@calingo.ch",
  "tasks": null,
  "invoiceDocument": {
    "s3Id": "test-s3-key.pdf"
  },
  "parsedInvoiceData": {
    "invoiceMetadata": {
      "issuerName": "Marigin Zentrum für Tiermedizin",
      "issuerAddress": "Unterortsstrasse 75 . 8804 Au",
      "recipientName": "FrancoKölliker",
      "recipientAddress": "8820 Wädenswil",
      "invoiceNumber": "None",
      "invoiceDate": "02.02.2024",
      "language": "German"
    },
    "treatments": [
      {
        // "petName": "Casey (Wildsong's All Just in Case)",
        "petName": "Casey",
        "date": "02.02.2024",
        "diagnosis": "Impfung",
        "positions": [
          {
            "name": "Kombinierte Impfung Pi und Lepto 6",
            "quantity": 1,
            "vatPercentage": 8.1,
            "amount": 85.1,
            "classification": null
          },
          {
            "name": "Nexgard 68 L (10-25kg) Packung à 3 Tabletten",
            "quantity": 1,
            "vatPercentage": 2.6,
            "amount": 55.55,
            "classification": null
          }
        ],
        "contract": null
      },
      {
        "petName": "Casey (Wildsong's All Just in Case)",
        "date": "09.02.2024",
        "diagnosis": "Impfung",
        "positions": [
          {
            "name": "Kombinierte Impfung Pi und Lepto 6",
            "quantity": 1,
            "vatPercentage": 8.1,
            "amount": 85.1,
            "classification": null
          },
          {
            "name": "Nexgard 68 L (10-25kg) Packung à 3 Tabletten",
            "quantity": 1,
            "vatPercentage": 2.6,
            "amount": 55.55,
            "classification": null
          }
        ],
        "contract": null
      },
      {
        "petName": "Bello",
        "date": "09.02.2024",
        "diagnosis": "Impfung",
        "positions": [
          {
            "name": "Kombinierte Impfung Pi und Lepto 6",
            "quantity": 1,
            "vatPercentage": 8.1,
            "amount": 85.1,
            "classification": null
          },
          {
            "name": "Nexgard 68 L (10-25kg) Packung à 3 Tabletten",
            "quantity": 1,
            "vatPercentage": 2.6,
            "amount": 55.55,
            "classification": null
          }
        ],
        "contract": null
      }
    ],
    "sumWithoutVat": 140.65,
    "sumWithVat": 149,
    "calculatedSum": 140.65
  },
  "fnolFirstName": "Sabine",
  "fnolLastName": "Burgener",
  "fnolEmail": "sabineb.francok@gmail.com",
  "fnolPhoneNumber": "0788545172",
  "fnolPreferredChannel": "phone",
  "fnolLang": "de",
  "fnolIban": "",
  "fnolPetName": "Casey",
  "fnolConditionPreviouslyTreated": false,
  "fnolDateConditionLastTreated": "",
  "fnolDateConditionFirstTreated": "2024-02-02",
  "fnolDateConditionNoticed": "2024-02-02",
  "fnolDateOfTreatment": "",
  "fnolTreatedInSwitzerland": true,
  "fnolInvoicedAmount": 149,
  "fnolTreatmentFinished": true,
  "fnolThirdPartyFault": false,
  "fnolAdditionalInformation": "",
  "fnolDiagnoses": [
    {
      "id": "impfung",
      "category": "PREVENTION"
    }
  ],
  "fnolWhitelabelFlavor": ""
};

export const fnolRequestExampleFull: FnolRequest = {
  "id": "test-key-1",
  "notifier": "customer",
  "notificationMedium": "fnol",
  "notificationDate": "2023-02-05",
  "processingStatus": 4,
  "processingAssignee":"stephantsov@ukr.net",
  "tasks": null,
  "invoiceDocument": {
    "s3Id": "test-s3-key.pdf"
  },
  "parsedInvoiceData": {
    "invoiceMetadata": {
      "issuerName": "Marigin Zentrum für Tiermedizin",
      "issuerAddress": "Unterortsstrasse 75 . 8804 Au",
      "recipientName": "FrancoKölliker",
      "recipientAddress": "8820 Wädenswil",
      "invoiceNumber": "None",
      "invoiceDate": "02.02.2024",
      "language": "German"
    },
    "treatments": [
      {
        "petName": "Casey",
        "date": "02.02.2024",
        "diagnosis": "Impfung",
        "comments": [],
        "positions": [
          {
            "name": "Kombinierte Impfung Pi und Lepto 6",
            "quantity": 1,
            "vatPercentage": 8.1,
            "amount": 85.1,
            "classification": {
              "type": "krankheit",
              "subtype": "medizinalfutter",
              "cause": "allgemeine_tieraerztliche_behandlung",
              "clusterAssignment": "1",
              "preexistingCondition": false,
              "emergencyAbroad": false,
              "chronicDisease": false,
              "obligationsFulfilled": false,
              "followUpTreatment": false,
              "covered": true,
              "furtherClarificationComment": "11",
              "genericComment": "12",
              "courseOfEvents": "13"
            }
          },
          {
            "name": "Nexgard 68 L (10-25kg) Packung à 3 Tabletten",
            "quantity": 1,
            "vatPercentage": 2.6,
            "amount": 55.55,
            "classification": {
              "type": "krankheit",
              "subtype": "allgemeine_tieraerztliche_behandlung",
              "cause": "allgemeine_tieraerztliche_behandlung",
              "clusterAssignment": "2",
              "preexistingCondition": false,
              "emergencyAbroad": false,
              "chronicDisease": false,
              "obligationsFulfilled": false,
              "followUpTreatment": false,
              "covered": true,
              "furtherClarificationComment": "11",
              "genericComment": "12",
              "courseOfEvents": "13"
            }
          }
        ],
        "contract": {
          "pet_name": "Casey",
          "pet_species": "dog",
          "pet_gender": "male",
          "breed_type": false,
          "breed": "australian_shepherd",
          "birthdate": "2020-10-15T00:00:00Z",
          "neutered": false,
          "firstname": "Sabine",
          "lastname": "Burgener",
          "email": "sabineb.francok@gmail.com",
          "street": "Rötibodenholzstrasse",
          "houseNumber": "19",
          "zip": "8820",
          "city": "Wädenswil",
          "language": "DE",
          "phoneNumber": "0041788545172",
          "ibanPresent": true,
          "full_contract_number": "PET1084964-1084964",
          "contract_number": "PET1084964",
          "status": "policiert",
          "start_date": "2023-02-02T00:00:00Z",
          "end_date": "2025-02-01T00:00:00Z",
          "cancellation_date": "1970-01-01T00:00:00Z",
          "date_of_wish_to_cancel": "1970-01-01T00:00:00Z",
          "yearly_gross_premium": 801,
          "yearly_net_premium": 762.6,
          "payment_interval": "monatlich",
          "taxes": 38.4,
          "product_bundle": "S",
          "yearly_net_premium_base": 747.6,
          "annual_franchise": 0,
          "used_up_annual_franchise": 0,
          "deductible_percentage_until_9_years": 0,
          "deductible_percentage_after_9_years": 20,
          "overall_sum_insured": 3000,
          "used_up_sum_insured": 0,
          "accident_and_illness_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "illness_advanced_treatment_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "illness_complementary_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 1000,
            "sublimit_percentage": -1
          },
          "illness_behavioural_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "accident_dental_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "illness_dental_treatment_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "emergency_minding_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 1000,
            "sublimit_percentage": -1
          },
          "emergency_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "medical_food_coverage": {
            "coverage_selected": false
          },
          "preventive_care_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "preexisting_conditions_coverage": {
            "yearly_net_premium": 0,
            "sum_insured_current_year": 700,
            "preexisting_conditions": false
          },
          "worldwide_protection_coverage": {
            "yearly_net_premium": 15,
            "sum_insured": 3000,
            "worldwide_protection": true
          },
          "claims": [
            {
              "identifier": "2023/1084964/1-1T",
              "claim_date": "2023-04-17T00:00:00Z",
              "paid_amount": 234.15,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-04-20T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 234.15
            },
            {
              "identifier": "2023/1084964/2-1T",
              "claim_date": "2023-07-04T00:00:00Z",
              "paid_amount": 831.45,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-07-25T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 831.45
            },
            {
              "identifier": "2023/1084964/3-1T",
              "claim_date": "2023-07-04T00:00:00Z",
              "paid_amount": 753.65,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-07-25T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 753.65
            }
          ]
        }
      },
      {
        "petName": "Casey (Wildsong's All Just in Case)",
        "date": "09.02.2024",
        "diagnosis": "Impfung",
        "comments": [],
        "positions": [
          {
            "name": "Kombinierte Impfung Pi und Lepto 6",
            "quantity": 1,
            "vatPercentage": 8.1,
            "amount": 85.1,
            "classification": {
              "type": "notfallbetreuung",
              "subtype": "komplementaermedizinische_behandlung",
              "cause": "behandlung_ausserhalb_der_sprechzeiten",
              "clusterAssignment": "3",
              "preexistingCondition": false,
              "emergencyAbroad": true,
              "chronicDisease": true,
              "obligationsFulfilled": false,
              "followUpTreatment": true,
              "followUpTreatmentClaim": "2023/1084964/1-1T",
              "covered": false,
              "reasonNotCovered": "NoCoverageAccordingToGTC",
              "furtherClarificationComment": "21",
              "genericComment": "22",
              "courseOfEvents": "23"
            }
          },
          {
            "name": "Nexgard 68 L (10-25kg) Packung à 3 Tabletten",
            "quantity": 1,
            "vatPercentage": 2.6,
            "amount": 55.55,
            "classification": {
              "type": "notfallbetreuung",
              "subtype": "komplementaermedizinische_behandlung",
              "cause": "behandlung_ausserhalb_der_sprechzeiten",
              "clusterAssignment": "4",
              "preexistingCondition": false,
              "emergencyAbroad": true,
              "chronicDisease": true,
              "obligationsFulfilled": false,
              "followUpTreatment": true,
              "followUpTreatmentClaim": "2023/1084964/1-1T",
              "covered": false,
              "reasonNotCovered": "NoCoverageAccordingToGTC",
              "furtherClarificationComment": "21",
              "genericComment": "22",
              "courseOfEvents": "23"
            }
          }
        ],
        "contract": {
          "pet_name": "Casey",
          "pet_species": "dog",
          "pet_gender": "male",
          "breed_type": false,
          "breed": "australian_shepherd",
          "birthdate": "2020-10-15T00:00:00Z",
          "neutered": false,
          "firstname": "Sabine",
          "lastname": "Burgener",
          "email": "sabineb.francok@gmail.com",
          "street": "Rötibodenholzstrasse",
          "houseNumber": "19",
          "zip": "8820",
          "city": "Wädenswil",
          "language": "DE",
          "phoneNumber": "0041788545172",
          "ibanPresent": true,
          "full_contract_number": "PET1084964-1084964",
          "contract_number": "PET1084964",
          "status": "policiert",
          "start_date": "2023-02-02T00:00:00Z",
          "end_date": "2025-02-01T00:00:00Z",
          "cancellation_date": "1970-01-01T00:00:00Z",
          "date_of_wish_to_cancel": "1970-01-01T00:00:00Z",
          "yearly_gross_premium": 801,
          "yearly_net_premium": 762.6,
          "payment_interval": "monatlich",
          "taxes": 38.4,
          "product_bundle": "S",
          "yearly_net_premium_base": 747.6,
          "annual_franchise": 0,
          "used_up_annual_franchise": 0,
          "deductible_percentage_until_9_years": 0,
          "deductible_percentage_after_9_years": 20,
          "overall_sum_insured": 3000,
          "used_up_sum_insured": 0,
          "accident_and_illness_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "illness_advanced_treatment_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "illness_complementary_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 1000,
            "sublimit_percentage": -1
          },
          "illness_behavioural_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "accident_dental_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "illness_dental_treatment_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "emergency_minding_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 1000,
            "sublimit_percentage": -1
          },
          "emergency_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "medical_food_coverage": {
            "coverage_selected": false
          },
          "preventive_care_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "preexisting_conditions_coverage": {
            "yearly_net_premium": 0,
            "sum_insured_current_year": 700,
            "preexisting_conditions": false
          },
          "worldwide_protection_coverage": {
            "yearly_net_premium": 15,
            "sum_insured": 3000,
            "worldwide_protection": true
          },
          "claims": [
            {
              "identifier": "2023/1084964/1-1T",
              "claim_date": "2023-04-17T00:00:00Z",
              "paid_amount": 234.15,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-04-20T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 234.15
            },
            {
              "identifier": "2023/1084964/2-1T",
              "claim_date": "2023-07-04T00:00:00Z",
              "paid_amount": 831.45,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-07-25T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 831.45
            },
            {
              "identifier": "2023/1084964/3-1T",
              "claim_date": "2023-07-04T00:00:00Z",
              "paid_amount": 753.65,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-07-25T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 753.65
            }
          ]
        }
      },
      {
        "petName": "Bello",
        "date": "09.02.2024",
        "diagnosis": "Impfung",
        "comments": [],
        "positions": [
          {
            "name": "Kombinierte Impfung Pi und Lepto 6",
            "quantity": 1,
            "vatPercentage": 8.1,
            "amount": 85.1,
            "classification": {
              "type": "praevention",
              "subtype": "medizinalfutter",
              "cause": "hausbesuch_durch_tierarzt",
              "clusterAssignment": "5",
              "preexistingCondition": false,
              "emergencyAbroad": false,
              "obligationsFulfilled": true,
              "chronicDisease": false,
              "followUpTreatment": false,
              "covered": true,
              "furtherClarificationComment": "31",
              "genericComment": "32",
              "courseOfEvents": "33"
            }
          },
          {
            "name": "Nexgard 68 L (10-25kg) Packung à 3 Tabletten",
            "quantity": 1,
            "vatPercentage": 2.6,
            "amount": 55.55,
            "classification": {
              "type": "praevention",
              "subtype": "medizinalfutter",
              "cause": "hausbesuch_durch_tierarzt",
              "clusterAssignment": "6",
              "preexistingCondition": false,
              "emergencyAbroad": false,
              "obligationsFulfilled": true,
              "chronicDisease": false,
              "followUpTreatment": false,
              "covered": true,
              "furtherClarificationComment": "31",
              "genericComment": "32",
              "courseOfEvents": "33"
            }
          }
        ],
        "contract": {
          "pet_name": "Casey",
          "pet_species": "dog",
          "pet_gender": "male",
          "breed_type": false,
          "breed": "australian_shepherd",
          "birthdate": "2020-10-15T00:00:00Z",
          "neutered": false,
          "firstname": "Sabine",
          "lastname": "Burgener",
          "email": "sabineb.francok@gmail.com",
          "street": "Rötibodenholzstrasse",
          "houseNumber": "19",
          "zip": "8820",
          "city": "Wädenswil",
          "language": "DE",
          "phoneNumber": "0041788545172",
          "ibanPresent": true,
          "full_contract_number": "PET1084964-1084964",
          "contract_number": "PET1084964",
          "status": "policiert",
          "start_date": "2023-02-02T00:00:00Z",
          "end_date": "2025-02-01T00:00:00Z",
          "cancellation_date": "1970-01-01T00:00:00Z",
          "date_of_wish_to_cancel": "1970-01-01T00:00:00Z",
          "yearly_gross_premium": 801,
          "yearly_net_premium": 762.6,
          "payment_interval": "monatlich",
          "taxes": 38.4,
          "product_bundle": "S",
          "yearly_net_premium_base": 747.6,
          "annual_franchise": 0,
          "used_up_annual_franchise": 0,
          "deductible_percentage_until_9_years": 0,
          "deductible_percentage_after_9_years": 20,
          "overall_sum_insured": 3000,
          "used_up_sum_insured": 0,
          "accident_and_illness_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "illness_advanced_treatment_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "illness_complementary_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 1000,
            "sublimit_percentage": -1
          },
          "illness_behavioural_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "accident_dental_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "illness_dental_treatment_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "emergency_minding_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 1000,
            "sublimit_percentage": -1
          },
          "emergency_treatment_coverage": {
            "coverage_selected": true,
            "sublimit_sum": 3000,
            "sublimit_percentage": -1
          },
          "medical_food_coverage": {
            "coverage_selected": false
          },
          "preventive_care_coverage": {
            "coverage_selected": false,
            "sublimit_percentage": -1
          },
          "preexisting_conditions_coverage": {
            "yearly_net_premium": 0,
            "sum_insured_current_year": 700,
            "preexisting_conditions": false
          },
          "worldwide_protection_coverage": {
            "yearly_net_premium": 15,
            "sum_insured": 3000,
            "worldwide_protection": true
          },
          "claims": [
            {
              "identifier": "2023/1084964/1-1T",
              "claim_date": "2023-04-17T00:00:00Z",
              "paid_amount": 234.15,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-04-20T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 234.15
            },
            {
              "identifier": "2023/1084964/2-1T",
              "claim_date": "2023-07-04T00:00:00Z",
              "paid_amount": 831.45,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-07-25T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 831.45
            },
            {
              "identifier": "2023/1084964/3-1T",
              "claim_date": "2023-07-04T00:00:00Z",
              "paid_amount": 753.65,
              "expense": 0,
              "used_franchsie": 0,
              "status": "Geschlossen",
              "closing_reason": "Erledigt",
              "closing_date": "2023-07-25T00:00:00Z",
              "type": "Krankheit",
              "sub_type": "Allgemeine tierärztliche Behandlung",
              "cause": "-",
              "insured_event": true,
              "reason_why_not_insured": "",
              "deduction_yearly_franchise": 0,
              "deduction_percentage_deductible": 0,
              "deduction_deductible": 0,
              "benefit_amount": 753.65
            }
          ]
        }
      }
    ],
    "sumWithoutVat": 140.65,
    "sumWithVat": 149,
    "calculatedSum": 140.65
  },
  "fnolFirstName": "Sabine",
  "fnolLastName": "Burgener",
  "fnolEmail": "sabineb.francok@gmail.com",
  "fnolPhoneNumber": "0788545172",
  "fnolPreferredChannel": "phone",
  "fnolLang": "de",
  "fnolIban": "",
  "fnolPetName": "Casey",
  "fnolConditionPreviouslyTreated": false,
  "fnolDateConditionLastTreated": "",
  "fnolDateConditionFirstTreated": "2024-02-02",
  "fnolDateConditionNoticed": "2024-02-02",
  "fnolDateOfTreatment": "",
  "fnolTreatedInSwitzerland": true,
  "fnolInvoicedAmount": 149,
  "fnolTreatmentFinished": true,
  "fnolThirdPartyFault": false,
  "fnolAdditionalInformation": "",
  "fnolDiagnoses": [
    {
      "id": "impfung",
      "category": "PREVENTION"
    }
  ],
  "fnolWhitelabelFlavor": ""
}
export const contractSelectedExample: unknown[] = [
  {
    "full_contract_number": "PET1084964-1084964",
    "contractNumberFirstPart": "PET1084964",
    "pet_name": "Casey",
    "firstname": "Sabine",
    "lastname": "Burgener",
    "email": "sabineb.francok@gmail.com",
    "phoneNumber": "0788545172",
    "birthdate": "2020-10-15T00:00:00Z",
    "contracts": [
      {
        "pet_name": "Casey",
        "pet_species": "dog",
        "pet_gender": "male",
        "breed_type": false,
        "breed": "australian_shepherd",
        "birthdate": "2020-10-15T00:00:00Z",
        "neutered": false,
        "firstname": "Sabine",
        "lastname": "Burgener",
        "email": "sabineb.francok@gmail.com",
        "street": "Rötibodenholzstrasse",
        "houseNumber": "19",
        "zip": "8820",
        "city": "Wädenswil",
        "language": "DE",
        "phoneNumber": "0788545172",
        "ibanPresent": true,
        "full_contract_number": "PET1084964-1084964",
        "contract_number": "PET1084964",
        "status": "policiert",
        "start_date": "2023-02-02T00:00:00Z",
        "end_date": "2025-02-01T00:00:00Z",
        "cancellation_date": "1970-01-01T00:00:00Z",
        "date_of_wish_to_cancel": "1970-01-01T00:00:00Z",
        "yearly_gross_premium": 801,
        "yearly_net_premium": 762.6,
        "payment_interval": "monatlich",
        "taxes": 38.4,
        "product_bundle": "S",
        "yearly_net_premium_base": 747.6,
        "annual_franchise": 0,
        "used_up_annual_franchise": -1,
        "deductible_percentage_until_9_years": 0,
        "deductible_percentage_after_9_years": 20,
        "overall_sum_insured": 3000,
        "used_up_sum_insured": -1,
        "accident_and_illness_coverage": {
          "coverage_selected": true,
          "sublimit_sum": 3000,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "illness_advanced_treatment_coverage": {
          "coverage_selected": false,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "illness_complementary_treatment_coverage": {
          "coverage_selected": true,
          "sublimit_sum": 1000,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "illness_behavioural_treatment_coverage": {
          "coverage_selected": true,
          "sublimit_sum": 3000,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "accident_dental_treatment_coverage": {
          "coverage_selected": true,
          "sublimit_sum": 3000,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "illness_dental_treatment_coverage": {
          "coverage_selected": false,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "emergency_minding_coverage": {
          "coverage_selected": true,
          "sublimit_sum": 1000,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "emergency_treatment_coverage": {
          "coverage_selected": true,
          "sublimit_sum": 3000,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "medical_food_coverage": {
          "coverage_selected": false,
          "used_up_sum_insured": -1
        },
        "preventive_care_coverage": {
          "coverage_selected": false,
          "sublimit_percentage": -1,
          "used_up_sum_insured": -1
        },
        "preexisting_conditions_coverage": {
          "yearly_net_premium": 0,
          "sum_insured_current_year": 700,
          "preexisting_conditions": false
        },
        "worldwide_protection_coverage": {
          "yearly_net_premium": 15,
          "sum_insured": 3000,
          "worldwide_protection": true
        },
        "claims": [
          {
            "identifier": "2023/1084964/1-1T",
            "claim_date": "2023-04-17T00:00:00Z",
            "paid_amount": 234.15,
            "expense": 0,
            "used_franchsie": 0,
            "status": "Geschlossen",
            "closing_reason": "Erledigt",
            "closing_date": "2023-04-20T00:00:00Z",
            "type": "Krankheit",
            "sub_type": "Allgemeine tierärztliche Behandlung",
            "cause": "-",
            "insured_event": true,
            "reason_why_not_insured": "",
            "deduction_yearly_franchise": 0,
            "deduction_percentage_deductible": 0,
            "deduction_deductible": 0,
            "benefit_amount": 234.15
          },
          {
            "identifier": "2023/1084964/2-1T",
            "claim_date": "2023-07-04T00:00:00Z",
            "paid_amount": 831.45,
            "expense": 0,
            "used_franchsie": 0,
            "status": "Geschlossen",
            "closing_reason": "Erledigt",
            "closing_date": "2023-07-25T00:00:00Z",
            "type": "Krankheit",
            "sub_type": "Allgemeine tierärztliche Behandlung",
            "cause": "-",
            "insured_event": true,
            "reason_why_not_insured": "",
            "deduction_yearly_franchise": 0,
            "deduction_percentage_deductible": 0,
            "deduction_deductible": 0,
            "benefit_amount": 831.45
          },
          {
            "identifier": "2023/1084964/3-1T",
            "claim_date": "2023-07-04T00:00:00Z",
            "paid_amount": 753.65,
            "expense": 0,
            "used_franchsie": 0,
            "status": "Geschlossen",
            "closing_reason": "Erledigt",
            "closing_date": "2023-07-25T00:00:00Z",
            "type": "Krankheit",
            "sub_type": "Allgemeine tierärztliche Behandlung",
            "cause": "-",
            "insured_event": true,
            "reason_why_not_insured": "",
            "deduction_yearly_franchise": 0,
            "deduction_percentage_deductible": 0,
            "deduction_deductible": 0,
            "benefit_amount": 753.65
          }
        ]
      }
    ],
    "status": "policiert"
  }
]
