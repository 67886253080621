<div class="modal-header">
  <h3 class="modal-title">{{ data.title }}</h3>
  <button (click)="close(GeneralConfirmationModalResult.None)" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ data.message }}</p>
</div>
<div class="modal-footer">
  <button (click)="close(GeneralConfirmationModalResult.Confirm)" *ngIf="data.confirmButton" color="primary"
          mat-raised-button>{{ 'general-confirmation-modal.modal-footer.confirm-button' | translate }}
  </button>
  <button (click)="close(GeneralConfirmationModalResult.Custom)" *ngIf="data.customButton" color="warn"
          mat-raised-button>{{ 'general-confirmation-modal.modal-footer.custom-button' | translate }}
  </button>
  <button (click)="close(GeneralConfirmationModalResult.Cancel)" *ngIf="data.cancelButton" color="accent"
          mat-raised-button>{{ 'general-confirmation-modal.modal-footer.cancel-button' | translate }}
  </button>
</div>
