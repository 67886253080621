import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FnolRequest, MfvClaim, MfvClaimUIExpanded } from "../../../models";
import { ApiHttpService } from "../../../services/api-http.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { jsPDF } from "jspdf";
import { euclidCircularbBoldFontBinaryString, euclidCircularbRegularFontBinaryString } from "../../../helpers";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'CPF-mfv-claim-detail',
  templateUrl: './mfv-claim-detail.component.html',
  styleUrls: ['./mfv-claim-detail.component.scss']
})
export class MfvClaimDetailComponent {
  public _mfvClaims: Array<MfvClaimUIExpanded> = [];
  @Output()
  completeRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(public apiHttp: ApiHttpService, private _snackBar: MatSnackBar, private translate: TranslateService, private datePipe: DatePipe) {
  }

  public _fnolRequest?: FnolRequest;
  tables?: string
  tableLang?: string
  languages: string[] = ['de', 'en', 'fr', 'it'];

  @Input() set fnolRequest(fnolRequest: FnolRequest) {
    this._fnolRequest = fnolRequest;
    this.tableLang = fnolRequest.fnolLang
  }

  @Input() set calculatedMfvClaims(mfvClaims: Array<MfvClaim>) {
    this._mfvClaims = mfvClaims.map((i, index) => ({ ...i, expanded: false, expandedBig: index === 0 }));
  }

  toggleExpandClaim(claim: MfvClaimUIExpanded) {
    claim.expandedBig = !claim.expandedBig;
  }

  buildContractLink(claim: MfvClaimUIExpanded) {
    //https://webapp.simpego.ch/vorgaenge/vertraege/<SECOND_PART_OF_FULL_CONTRACT_NUMBER>
    return `https://webapp.simpego.ch/vorgaenge/vertraege/${claim.contract.full_contract_number.split('-')[1]}`;
  }

  mfvClaimIDSet(claim: MfvClaimUIExpanded) {
    this._mfvClaims.filter(c => c.followUpTreatmentCluster === claim.cmsClusterID).forEach(c => c.followUpTreatmentClaimID = claim.mfvClaimID);
  }

  async generateComments() {
    const res = await this.apiHttp.generateComments(this._mfvClaims);
    if (res) {
      this._mfvClaims = res.map((i, index) => ({
        ...i,
        expanded: false,
        expandedBig: this._mfvClaims[index].expandedBig
      }));
    }
  }

  allClaimsValid() {
    return this._mfvClaims.every(claim => claim.mfvClaimID || !claim.contract);
  }

  formatVetFromInvoice() {
    return `${this._fnolRequest?.parsedInvoiceData.invoiceMetadata.issuerName} (${this._fnolRequest?.parsedInvoiceData.invoiceMetadata.issuerAddress})`
  }

  openContactInNewTab(claim: MfvClaimUIExpanded) {
    window.open(this.buildContractLink(claim), '_blank');
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    this._snackBar.open('Copied to clipboard', 'Close', {
      duration: 1000,
    });
  }

  showClusterWarning(claim: MfvClaimUIExpanded) {
    return !claim.followUpTreatmentClaimID && !!claim.followUpTreatmentCluster;
  }

  onTabPress(claim: MfvClaimUIExpanded) {
    const nextClaim = this._mfvClaims[this._mfvClaims.indexOf(claim) + 1];
    if (nextClaim) {
      nextClaim.expandedBig = true;
    }
  }

  roundToTwoDecimals(v: number): string {
    let value = v ? v : 0;
    value = isNaN(value) ? 0 : value;
    return value.toFixed(2);
  }

  isoDateToEuro(date: string): string {
    let dateObj = new Date(date);
    if (dateObj.toString() === "Invalid Date" || dateObj.getTime() === 0) {
      return "";
    } else {
      return this.datePipe.transform(dateObj, 'dd.MM.yyyy') || "";
    }
  }

  async generatePDF() {
    let tables = [];

    const translationObjectPdf:any = (await firstValueFrom(this.translate.getTranslation(this.tableLang || 'de')))['pdf-generation'] || {};
    const translationObjectClassificationTypes:any = (await firstValueFrom(this.translate.getTranslation(this.tableLang || 'de')))['classification-types'] || {};

    for (let i = 0; i < this._mfvClaims.length; i++) {
      let tableContainer = document.createElement('div');
      let claim = this._mfvClaims[i];
      let table = document.createElement('table');
      let tbody = document.createElement('tbody');

      // Add class and id to div
      tableContainer.className = 'table-container';
      tableContainer.id = 'table';

      //Add logo image
      let img = document.createElement('img');
      img.className = 'logo';
      img.src = 'assets/logo_calingo_rgb_petrol.png';
      img.width = 259;
      img.height = 94;
      tableContainer.appendChild(img);

      //Add title
      let title = document.createElement('h2');
      title.textContent = translationObjectPdf['title'] + ' ' + this.isoDateToEuro(claim.notificationDate);
      tableContainer.appendChild(title);

      // Append table and tbody to div and table respectively
      tableContainer.appendChild(table);
      table.appendChild(tbody);

      // Rows data
      let rows = [
        { className: 'empty', cells: [''] },
        { className: 'no-borders case-name', cells: [translationObjectPdf['claim-id'] + ' ' +  claim.mfvClaimID] },
        { className: 'no-borders', cells: [translationObjectPdf['animal'], claim.contract.pet_name] },
        { className: 'no-borders', cells: [translationObjectPdf['treatment-date'], this.isoDateToEuro(claim.treatmentDate)] },
        { className: 'no-borders', cells: [translationObjectPdf['category'], translationObjectClassificationTypes['claim-types'][claim.type]] },
        { className: 'empty', cells: [''] },
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['total-amount'], this.isoDateToEuro(claim.invoiceDate), translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.totalCoveredAmount + claim.totalNonCoveredAmount)]
        },// total covered amount + total non covered amount. "Middle field": mfvClaim.claimDate
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['total-covered'], translationObjectClassificationTypes['claim-causes'][claim.cause], translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.totalCoveredAmount)]
        },// total covered amount. Middle field: Type
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['total-not-covered'], translationObjectClassificationTypes['reasons-not-covered'][claim.reasonWhyNotCovered] || '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.totalNonCoveredAmount)]
        },// total non covered amount. Middle field: mfcClaim.reasonNotCovered. TO CHECK: If the claim subtype is medical food, can we add a hint that only 25% or 33% of the amount is covered (this info is on  mfvClaim.AssignedContract.MedicalFoodCoverage.SublimitPercentage)? Also, when various positions in the claim are not covered, show a comma seperated list of reasons why.
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['initial-remaining-franchise'], '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.initialRemainingFranchise)]
        },// initialRemainingFranchise
        {
          className: 'border-outside',
          cells: ['', '', translationObjectPdf['CHF'], this.roundToTwoDecimals(-claim.deductionYearlyFranchise)]
        },// deductionYearlyFranchise
        {
          className: 'border-outside',
          cells: [translationObjectPdf['remaining-franchise'], '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.initialRemainingFranchise - claim.deductionYearlyFranchise)]
        },// initialRemainingFranchise - deductionYearlyFranchise
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['per-claim-deductible'], '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.deductionPerClaimDeductible)]
        },// deductionPerClaimDeductible
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['deductible'], 100-claim.costCoveragePercentage + '%', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.deductionPercentageDeductible)]
        },// the amount is from deductionPercentageDeductible, middle field: the percentage number is from 100 - costCoveragePercentage
        {
          className: 'border-outside border-top',
          cells: [translationObjectPdf['initial-remaining-limit'], '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.initialRemainingLimit)]
        },// initialRemainingLimit
        { className: 'border-outside', cells: ['', '', translationObjectPdf['CHF'], this.roundToTwoDecimals(-claim.benefitAmount)] },// benefit amount
        {
          className: 'border-outside',
          cells: [translationObjectPdf['remaining-limit'], '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.initialRemainingLimit - claim.benefitAmount)]
        },// initial remaining limit - benefit amount
        {
          className: 'border-outside border-top border-bottom',
          cells: [translationObjectPdf['benefit-amount'], '', translationObjectPdf['CHF'], this.roundToTwoDecimals(claim.benefitAmount)]
        },// benefit amount
        { className: 'empty', cells: [''] },
        { className: 'empty', cells: [''] },
        { className: 'empty', cells: [''] },
        { className: 'empty', cells: [''] }, //some spacing after table
      ]

      if (i % 2 === 0) {
        rows.push({ className: 'empty', cells: [''] });
        rows.push({ className: 'empty', cells: [''] });
      }

      // Create rows with cells
      for (let row of rows) {
        let tr = document.createElement('tr');
        tr.className = row.className;

        for (let cellData of row.cells) {
          let td = document.createElement('td');
          td.textContent = cellData ? cellData.toString() : '';

          tr.appendChild(td);
        }

        tbody.appendChild(tr);
      }

      tables.push(tableContainer);
    }

    let doc = new jsPDF('p', 'pt', 'a4');
    doc.addFileToVFS('EuclidCircularB-Regular-normal.ttf', euclidCircularbRegularFontBinaryString);
    doc.addFileToVFS('EuclidCircularB-Bold-normal.ttf', euclidCircularbBoldFontBinaryString);
    doc.addFont('EuclidCircularB-Regular-normal.ttf', 'euclid-circularb-regular', 'normal');
    doc.addFont('EuclidCircularB-Bold-normal.ttf', 'euclid-circularb-regular', 'bold');
    // Add tables to doc
    for (let i = tables.length - 1; i >= 0; i--) {
      let table = tables[i];
      await doc.html(table, {
        autoPaging: 'text',
        callback: (doc: jsPDF) => {
          doc.insertPage(1)
        }
      });
    }

    doc.deletePage(1);
    let docName = this._mfvClaims[0].contract.firstname + '_' + this._mfvClaims[0].contract.pet_name + '_' + this._mfvClaims[0].notificationDate
    doc.save(docName);
    this.tables = tables.map(t => {return t.outerHTML}).join('')
  }

  completeFNOLRequest() {
    this._fnolRequest!.mfvClaims = this._mfvClaims;
    this.completeRequest.emit();
  }

  isCommentGenerated() {
    return this._mfvClaims.every(claim => claim.commentForMfv);
  }

  /*    console.log('html:', tableContainer.outerHTML);

      doc.html(tableContainer, {
        autoPaging: 'text',
        callback: (doc: jsPDF) => {
          doc.save('pdf-export');
        }
      })*/
}
