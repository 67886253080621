<div #petFormEl>
  <form [formGroup]="petForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="petName">Pet Name:</label>
      <input type="text" id="petName" formControlName="petName" class="form-control">
    </div>

    <div class="form-group">
      <label for="petBirthDate">Pet Birth Date:</label>
      <input type="date" id="petBirthDate" formControlName="petBirthDate" class="form-control">
    </div>

    <div class="form-group">
      <label for="contractNumber">Contract Number:</label>
      <input type="text" id="contractNumber" formControlName="contractNumber" class="form-control">
    </div>

    <div class="form-group">
      <label for="firstName">First Name:</label>
      <input type="text" id="firstName" formControlName="firstName" class="form-control">
    </div>

    <div class="form-group">
      <label for="lastName">Last Name:</label>
      <input type="text" id="lastName" formControlName="lastName" class="form-control">
    </div>

    <div class="form-group">
      <label for="email">Email:</label>
      <input type="email" id="email" formControlName="email" class="form-control">
    </div>

    <div class="form-group">
      <label for="phone">Phone:</label>
      <input type="tel" id="phone" formControlName="phone" class="form-control">
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!petForm.valid">Submit</button>
  </form>
</div>

<mat-divider></mat-divider>

<div #table class="table-container">
  <table>
    <tbody>
    <tr class="no-borders case-name">
      <td>Case of damage 2024/1350537/7-1T</td>
    </tr>

    <tr class="no-borders">
      <td>Animal</td>
      <td>Gusti</td>
    </tr>

    <tr class="no-borders">
      <td>Treatment date</td>
      <td>22.05.2024</td>
    </tr>

    <tr class="no-borders">
      <td>Category</td>
      <td>Krankheit</td>
    </tr>

    <tr class="empty">
      <td></td>
    </tr>

    <tr class="border-outside">
      <td>Claim according to invoice dated</td>
      <td>31.05.2024</td>
      <td>CHF</td>
      <td>760,20</td>
    </tr>

    <tr class="border-outside">
      <td>Thereof covered</td>
      <td>Krankheit</td>
      <td>CHF</td>
      <td>760,20</td>
    </tr>

    <tr class="border-outside">
      <td>Of which uncovered</td>
      <td>Innerhalb Selbstbehalt</td>
      <td>CHF</td>
      <td>-</td>
    </tr>

    <tr class="border-outside border-bottom">
      <td>Franchise per year</td>
      <td></td>
      <td>CHF</td>
      <td>238,20</td>
    </tr>

    <tr></tr>
    </tbody>
  </table>
</div>

<button mat-raised-button color="primary" (click)="generatePDF()">Generate PDF</button>

<amplify-authenticator>
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut"
  >
    <button (click)="signOut()">Sign Out</button>
  </ng-template>
</amplify-authenticator>
