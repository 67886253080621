<h2>{{ 'fnol-details.title'|translate }}</h2>
<h4>{{ 'fnol-details.subtitle'|translate }}</h4>

<div *ngIf="fnolDetail">
  <div class="fnol-form-text">

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-first-name'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-first-name-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolFirstName">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-last-name'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-last-name-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolLastName">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-email'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-email-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolEmail">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-phone-number'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-phone-number-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolPhoneNumber">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-preferred-channel'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-preferred-channel-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolPreferredChannel">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-lang'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-lang-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolLang">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-iban'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-iban-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolIban">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-pet-name'|translate }}</mat-label>
      <input matInput placeholder="{{ 'fnol-details.fnol-pet-name-placeholder'|translate }}" [(ngModel)]="fnolDetail.fnolPetName">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-invoiced-amount'|translate }}</mat-label>
      <input matInput [(ngModel)]="fnolDetail.fnolInvoicedAmount">
    </mat-form-field>
  </div>
  <div class="fnol-form-date">

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-date-condition-first-treated'|translate }}</mat-label>
      <input matInput [(ngModel)]="fnolDetail.fnolDateConditionFirstTreated" [matDatepicker]="dateConditionFirstTreatedPicker">
      <mat-hint>{{ 'fnol-details.date-format-hint'|translate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="dateConditionFirstTreatedPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateConditionFirstTreatedPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-date-condition-last-treated'|translate }}</mat-label>
      <input matInput [(ngModel)]="fnolDetail.fnolDateConditionLastTreated" [matDatepicker]="dateConditionLastTreatedPicker">
      <mat-hint>{{ 'fnol-details.date-format-hint'|translate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="dateConditionLastTreatedPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateConditionLastTreatedPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-date-condition-noticed'|translate }}</mat-label>
      <input matInput [(ngModel)]="fnolDetail.fnolDateConditionNoticed" [matDatepicker]="dateConditionNoticedPicker">
      <mat-hint>{{ 'fnol-details.date-format-hint'|translate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="dateConditionNoticedPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateConditionNoticedPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-date-of-treatment'|translate }}</mat-label>
      <input matInput [(ngModel)]="fnolDetail.fnolDateOfTreatment" [matDatepicker]="dateOfTreatmentPicker">
      <mat-hint>{{ 'fnol-details.date-format-hint'|translate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="dateOfTreatmentPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateOfTreatmentPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'fnol-details.fnol-additional-information'|translate }}</mat-label>
      <textarea matInput [(ngModel)]="fnolDetail.fnolAdditionalInformation"></textarea>
    </mat-form-field>

    <section>
      <mat-label>{{ 'fnol-details.fnol-condition-previously-treated'|translate }}</mat-label>
      <mat-checkbox [(ngModel)]="fnolDetail.fnolConditionPreviouslyTreated"></mat-checkbox>
    </section>

    <section>
      <mat-label>{{ 'fnol-details.fnol-treated-in-switzerland'|translate }}</mat-label>
      <mat-checkbox [(ngModel)]="fnolDetail.fnolTreatedInSwitzerland"></mat-checkbox>
    </section>

    <section>
      <mat-label>{{ 'fnol-details.fnol-treatment-finished'|translate }}</mat-label>
      <mat-checkbox [(ngModel)]="fnolDetail.fnolTreatmentFinished"></mat-checkbox>
    </section>

    <section>
      <mat-label>{{ 'fnol-details.fnol-third-party-fault'|translate }}</mat-label>
      <mat-checkbox [(ngModel)]="fnolDetail.fnolThirdPartyFault"></mat-checkbox>
    </section>

  </div>
</div>
