<h2>{{ 'invoice-parsed-edit.edit-title' | translate }}</h2>
<div *ngIf="parsedInvoiceData">
  <div class="details-form-text">
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.issuer-name' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.issuerName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.issuer-address' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.issuerAddress">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.recipient-name' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.recipientName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.recipient-address' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.recipientAddress">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.invoice-number' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.invoiceNumber">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.language' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.language">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'invoice-parsed-edit.invoice-date' | translate }}</mat-label>
      <input matInput [(ngModel)]="parsedInvoiceData.invoiceMetadata.invoiceDate" [matDatepicker]="invoiceDatePicker">
      <mat-hint>{{ 'invoice-parsed-edit.date-hint' | translate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #invoiceDatePicker></mat-datepicker>
    </mat-form-field>
    <div class="treatment" *ngFor="let treatment of parsedInvoiceData.treatments">
      <div class="treatment-pet-info">
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.pet-name' | translate }}</mat-label>
          <input matInput [(ngModel)]="treatment.petName">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.treatment-date' | translate }}</mat-label>
          <input matInput [(ngModel)]="treatment.date" [matDatepicker]="treatmentDatePicker">
          <mat-hint>{{ 'invoice-parsed-edit.date-hint' | translate }}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="treatmentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #treatmentDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.diagnosis' | translate }}</mat-label>
          <input matInput [(ngModel)]="treatment.diagnosis">
        </mat-form-field>
      </div>
      <div class="position" *ngFor="let position of treatment.positions">
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.position-name' | translate }}</mat-label>
          <input matInput [(ngModel)]="position.name">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.quantity' | translate }}</mat-label>
          <input matInput [(ngModel)]="position.quantity">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.vat-percentage' | translate }}</mat-label>
          <input matInput [(ngModel)]="position.vatPercentage">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'invoice-parsed-edit.amount' | translate }}</mat-label>
          <input matInput type="number" [(ngModel)]="position.amount">
        </mat-form-field>
        <button class="delete-button" mat-raised-button color="warn" *ngIf="treatment.positions.length>1" (click)="removePosition(treatment, position)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div class="actions">
        <button (click)="removeTreatment(treatment)" *ngIf="parsedInvoiceData.treatments.length>1" color="warn" mat-raised-button>
          {{ 'invoice-parsed-edit.remove-treatment' | translate }}
        </button>
        <button (click)="addPosition(treatment)" color="primary" mat-raised-button>
          {{ 'invoice-parsed-edit.add-position' | translate }}
        </button>
      </div>
    </div>
    <div class="actions">
      <button (click)="addTreatment()" color="primary" mat-raised-button>
        {{ 'invoice-parsed-edit.add-treatment' | translate }}
      </button>
    </div>
  </div>
</div>
