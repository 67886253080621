<div class="claim" *ngIf="claim">
  <div class="top-row">
    <div class="text">
      {{ 'mfv-claim-calculation-detail.claim-title'|translate }} {{ index + 1 }}
      ({{ claim.contract && claim.contract.pet_name ? claim.contract.pet_name : claim.invoicePetName }}, {{ claim.claimDate | date:'dd.MM.yyyy' }}
      , {{ claim.contract && claim.contract.full_contract_number ? claim.contract.full_contract_number : ('mfv-claim-calculation-detail.default-contract'|translate) }}
      , {{ claim.type }}, {{ claim.subtype }}, {{ claim.cause }})
    </div>
    <div class="actions">
      <mat-icon (click)="toggleExpandClaim(claim)" class="expand-button" [class.expand]="!claim.expanded">expand_less</mat-icon>
    </div>
  </div>
  <div *ngIf="claim && claim.expanded" class="claim-expanded">
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.total-covered-amount' | translate }}</div>
      <div class="claim-info-value">{{ claim.totalCoveredAmount | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.deduction-yearly-franchise' | translate }}</div>
      <div class="claim-info-value">{{ claim.deductionYearlyFranchise | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.deduction-percentage-deductible' | translate }}</div>
      <div class="claim-info-value">{{ claim.deductionPercentageDeductible | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.deduction-per-claim-deductible' | translate }}</div>
      <div class="claim-info-value">{{ claim.deductionPerClaimDeductible | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.deduction-limit' | translate }}</div>
      <div class="claim-info-value">{{ claim.deductionLimit | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.benefit-amount' | translate }}</div>
      <div class="claim-info-value">{{ claim.benefitAmount | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      <div class="claim-info-title">{{ 'mfv-claim-calculation-detail.total-non-covered-amount' | translate }}</div>
      <div class="claim-info-value">{{ claim.totalNonCoveredAmount | number : '1.2-2' }}</div>
    </div>
    <div class="claim-info">
      {{ claim.coverageCalculationNotes }}
    </div>
    <div class="actions" *ngIf="showActions">
      <button (click)="editClassificationClick()" color="accent"
              mat-raised-button>{{ 'mfv-claim-calculation-detail.edit-classification' | translate }}
      </button>
      <button (click)="showPositions(claim)" color="primary" mat-raised-button>{{ 'mfv-claim-calculation-detail.show-positions' | translate }}
      </button>
    </div>
  </div>
</div>
