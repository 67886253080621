import {Component, Input} from '@angular/core';
import {FnolRequest} from "../../../models";

@Component({
  selector: 'CPF-invoice-items-coverage-check-preview',
  templateUrl: './invoice-items-coverage-check-preview.component.html',
  styleUrls: ['./invoice-items-coverage-check-preview.component.scss']
})
export class InvoiceItemsCoverageCheckPreviewComponent {
  @Input() fnolRequest!: FnolRequest;
}
