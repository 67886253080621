import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MfvClaimUIExpanded} from "../../../models";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";

@Component({
  selector: 'CPF-mfv-claim-calculation-detail',
  templateUrl: './mfv-claim-calculation-detail.component.html',
  styleUrls: ['./mfv-claim-calculation-detail.component.scss']
})
export class MfvClaimCalculationDetailComponent {
  @Input() claim: MfvClaimUIExpanded | undefined;

  @Input() index: number = 0;

  @Input() showActions: boolean = false;

  @Output() editClassification = new EventEmitter<void>();

  constructor(public sidebarDetailsService: SidebarDetailsService) {

  }

  showPositions(claim: MfvClaimUIExpanded) {
    this.sidebarDetailsService.setMfvClaimPositions({
      coveredPositions: claim.coveredPositions,
      nonCoveredPositions: claim.nonCoveredPositions,
      totalCovered: claim.totalCoveredAmount,
      totalNonCovered: claim.totalNonCoveredAmount,
    })

    this.sidebarDetailsService.open();
  }

  toggleExpandClaim(claim: MfvClaimUIExpanded) {
    claim.expanded = !claim.expanded;
  }

  editClassificationClick() {
    this.editClassification.emit();
  }
}
