import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  ClassificationType,
  ContactSearch,
  Contract,
  ContractPreview,
  contractSelectedExample,
  ContractUIUsedUpFranchise,
  fnolRequestExample,
  fnolRequestExampleFull,
  FnolRequestStatus, GeneralConfirmationModalResult, MfvClaim,
  Treatment,
  TreatmentVerificationStatus
} from "../../../models";
import {FindContractComponent} from "../../contract/find-contract/find-contract.component";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";
import {ClassificationListComponent} from "../../classification/classification-list/classification-list.component";
import {ApiHttpService} from "../../../services/api-http.service";
import {ContractUsedUpFranchiseComponent} from "../../contract/contract-used-up-franchise/contract-used-up-franchise.component";
import {
  ContractUsedUpFranchisePreviewComponent
} from "../../contract/contract-used-up-franchise-preview/contract-used-up-franchise-preview.component";
import {MatDialog} from "@angular/material/dialog";
import {GeneralConfirmationModalComponent} from "../../general-confirmation-modal/general-confirmation-modal.component";
import {DataStorageService} from "../../../services/data-storage.service";
import {ActivatedRoute} from "@angular/router";
import {LoadingOverlayRef, LoadingService} from "../../../services/loading.service";
import {convertContractsToContractPreview} from "../../../helpers";
import {InvoiceItemsCoverageCheckComponent} from "../../invoice/invoice-items-coverage-check/invoice-items-coverage-check.component";
import {AccountService} from "../../../services/account.service";
import {firstValueFrom, take} from "rxjs";
import {InvoiceParsedEditComponent} from "../../invoice/invoice-parsed-edit/invoice-parsed-edit.component";

const USE_MOCK = false;

@Component({
  selector: 'CPF-fnol-request',
  templateUrl: './fnol-request.component.html',
  styleUrls: ['./fnol-request.component.scss']
})
export class FnolRequestComponent implements OnInit {
  @Input()
  public fnolRequest = fnolRequestExample;

  @Input()
  public editable: boolean = true;

  @ViewChild(InvoiceParsedEditComponent, {static: false})
  invoiceParsedEditComponent: InvoiceParsedEditComponent | undefined;

  @ViewChild(FindContractComponent, {static: false})
  findContractComponent: FindContractComponent | undefined;

  @ViewChild(InvoiceItemsCoverageCheckComponent, {static: false})
  invoiceItemsCoverageCheckComponent: InvoiceItemsCoverageCheckComponent | undefined;

  @ViewChild(ClassificationListComponent, {static: false})
  classificationListComponent: ClassificationListComponent | undefined;

  @ViewChild(ContractUsedUpFranchiseComponent, {static: false})
  usedUpFranchiseComponent: ContractUsedUpFranchiseComponent | undefined;

  @ViewChild(ContractUsedUpFranchisePreviewComponent, {static: false})
  usedUpFranchisePreviewComponent: ContractUsedUpFranchisePreviewComponent | undefined;

  public searchContractValue: Partial<ContactSearch> = {
    petName: '',
    petBirthDate: '',
    contractNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }
  public contractSelected: ContractPreview[] = [];
  public FnolRequestStatusEnum = FnolRequestStatus;
  public treatmentVerificationStatus = TreatmentVerificationStatus;
  public contractsUiUsedUpFranchise: ContractUIUsedUpFranchise[] = [];
  public classificationTypes!: ClassificationType;
  public calculatedMfvClaims: MfvClaim[] = [];
  private user = firstValueFrom(this.accountService.user$);

  constructor(private dialog: MatDialog,
              private sidebarDetailsService: SidebarDetailsService,
              private apiHttpService: ApiHttpService,
              private dataStorageService: DataStorageService,
              private accountService: AccountService,
              private loadingService: LoadingService,) {
    if (USE_MOCK) {
      this.fnolRequest = fnolRequestExampleFull;
      this.contractSelected = contractSelectedExample as ContractPreview[];
    } else {
      this.fnolRequest = fnolRequestExample;
      this.contractSelected = [];
    }

    this.classificationTypes = this.dataStorageService.classificationTypes;
  }

  async ngOnInit() {
    const contactsAssigned = this.fnolRequest.parsedInvoiceData?.treatments
      ?.filter(treatment => treatment.contract)
      ?.map(treatment => treatment.contract)
      ?.reduce((acc: any, contract) => {
        if (!contract) {
          return
        }
        if (!acc.find((c: Contract) => c.full_contract_number === contract.full_contract_number)) {
          acc.push(contract);
        }
        return acc as Contract[];
      }, [] as Contract[]) || [];
    this.contractSelected = [...this.contractSelected, ...convertContractsToContractPreview(contactsAssigned)];
    this.calculatedMfvClaims = this.fnolRequest.mfvClaims || [];

    setTimeout(async () => {
      if (this.fnolRequest && this.isStepAfter(FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED)) {
        this.groupContractForUsedUpFranchise();
      }
    }, 100); // Timeout is needed to wait for the usedUpFranchiseComponent to be initialized
  }

  async sendFnolRequest() {
    const res: MfvClaim[] = await this.apiHttpService.calculatePaymentAPI(this.fnolRequest);

    if (res) {
      this.fnolRequest.processingStatus = FnolRequestStatus.PAYMENT_CALCULATED;

      this.calculatedMfvClaims = res;
    }
  }

  async save() {
    const loadingRef: LoadingOverlayRef = this.loadingService.open();
    try {
      const user = await this.user;
      this.fnolRequest.processingAssignee = user?.email || '-';
      const res = await this.apiHttpService.updateFnolRequest(this.fnolRequest);
    } catch (e) {
      console.error(`Error while saving the FNOL request: ${e}`);
    } finally {
      loadingRef.close();
    }
  }

  findContractsByField(args: { fieldName: string, value: string }[]) {
    this.searchContractValue = {
      petName: '',
      petBirthDate: '',
      contractNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };

    args.forEach(({fieldName, value}) => {
      // @ts-ignore
      this.searchContractValue[fieldName] = value;
    });

    if (this.findContractComponent) {
      this.findContractComponent.searchValue = this.searchContractValue;
    }
  }

  validateInvoiceDetails() {
    return this.invoiceParsedEditComponent?.validate() || false;
  }

  preassignContracts() {
    const flatContracts = this.contractSelected.map(c => c.contracts).flat();

    this.fnolRequest.parsedInvoiceData.treatments.forEach(item => {
      const availableContracts = flatContracts.filter(contract => this.checkIfContractCanAssignTreatment(contract, item));

      if (availableContracts.length === 1) {
        item.contract = availableContracts[0];
      }
    });
  }

  checkIfContractCanAssignTreatment(contract: Contract, treatment: Treatment) {
    return this.compareNames(contract.pet_name, treatment.petName) && this.checkIfTreatmentDateIsBetweenContractDates(contract.start_date, contract.end_date, treatment.date);
  }

  compareNames(name1: string, name2: string) {
    return name1.trim().toLowerCase() === name2.trim().toLowerCase();
  }

  checkIfTreatmentDateIsBetweenContractDates(contractStartDate: string, contractEndDate: string, treatmentDate: string) {
    return new Date(treatmentDate).getTime() >= new Date(contractStartDate).getTime() && new Date(treatmentDate).getTime() <= new Date(contractEndDate).getTime();
  }

  confirmContracts() {
    this.fnolRequest.processingStatus = FnolRequestStatus.CONTRACTS_ASSIGNED;
    this.preassignContracts();
  }

  confirmFNOLDetails() {
    this.searchContractValue = {
      ...this.searchContractValue,
      email: this.fnolRequest.fnolEmail
    }
    this.fnolRequest.processingStatus = FnolRequestStatus.FNOL_DATA_CONFIRMED;

    this.save();
  }

  cancelInvoiceDetails() {
    const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
      data: {
        title: 'Cancel Invoice Details changes',
        message: 'You are about to cancel the changes made to the invoice details. Are you sure you want to continue?',
        confirmButton: GeneralConfirmationModalResult.Confirm,
        cancelButton: GeneralConfirmationModalResult.Cancel,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === GeneralConfirmationModalResult.Confirm) {
        this.invoiceParsedEditComponent?.cancelChanges();
      }
    });
  }

  confirmInvoiceDetails() {
    if (!this.validateInvoiceDetails()) {
      return;
    }

    this.fnolRequest.parsedInvoiceData = JSON.parse(JSON.stringify(this.invoiceParsedEditComponent!.parsedInvoiceData));

    this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_PARSED_CONFIRMED;

    this.save();
  }

  confirmVATAndDiscount() {
    this.fnolRequest.processingStatus = FnolRequestStatus.VAT_AND_DISCOUNT_SET;

    this.save();
  }

  contractsSelectedEvent(contractSelected: ContractPreview) {
    this.contractSelected.push(contractSelected);
  }

  contractDeselectedEvent(contractSelected: ContractPreview) {
    this.contractSelected = this.contractSelected.filter(c => c.contractNumberFirstPart !== contractSelected.contractNumberFirstPart);
  }

  editFNOLDetails() {
    if (this.isStepAfter(FnolRequestStatus.FNOL_DATA_CONFIRMED)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit FNOL Details',
          message: 'If you edit the FNOL details, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          this.undoUsedUpFranchise();
          this.undoClassificationAssignments();
          this.undoCoverageCheck();
          this.undoContractsAssignments();
          this.undoContractsSearch();
          this.undoVATAndDiscount();
          this.fnolRequest.processingStatus = FnolRequestStatus.TODO;

          this.save();
        }
      });

      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.TODO;
    this.save();
  }

  editInvoiceDetails() {
    if (this.isStepAfter(FnolRequestStatus.INVOICE_PARSED_CONFIRMED)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit Invoice Details',
          message: 'If you edit the invoice details, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          this.undoUsedUpFranchise();
          this.undoClassificationAssignments();
          this.undoCoverageCheck();
          this.undoContractsAssignments();
          this.undoContractsSearch();
          this.undoVATAndDiscount();
          this.fnolRequest.processingStatus = FnolRequestStatus.FNOL_DATA_CONFIRMED;

          this.save();
        }
      });

      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.FNOL_DATA_CONFIRMED;
    this.save();
  }

  editVATAndDiscount() {
    if (this.isStepAfter(FnolRequestStatus.VAT_AND_DISCOUNT_SET)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit VAT and Discount',
          message: 'If you edit the VAT and Discount, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          // not resetting any data here, as typically, this is
          // this.undoUsedUpFranchise();
          // this.undoClassificationAssignments();
          // this.undoCoverageCheck();
          // this.undoContractsAssignments();
          this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_PARSED_CONFIRMED;

          this.save();
        }
      });

      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_PARSED_CONFIRMED;
    this.save();
  }

  editContracts() {
    if (this.isStepAfter(FnolRequestStatus.CONTRACTS_ASSIGNED)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit Contracts',
          message: 'If you edit the contracts, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          this.undoUsedUpFranchise();
          // this.undoClassificationAssignments(); // not undoing classification, as it is quite labourous to redo
          this.undoCoverageCheck();
          this.undoContractsAssignments();
          this.fnolRequest.processingStatus = FnolRequestStatus.VAT_AND_DISCOUNT_SET;

          this.save();
        }
      });

      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.VAT_AND_DISCOUNT_SET;
    this.save();
  }

  confirmAssignments() {
    this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED;

    this.save();
  }

  editAssignments() {
    if (this.isStepAfter(FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit Assignments',
          message: 'If you edit the assignments, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          this.undoUsedUpFranchise();
          // this.undoClassificationAssignments(); // not undoing classification, as it is quite labourous to redo
          this.undoCoverageCheck();
          this.fnolRequest.processingStatus = FnolRequestStatus.CONTRACTS_ASSIGNED;

          this.save();
        }
      });

      return;
    }
    this.fnolRequest.processingStatus = FnolRequestStatus.CONTRACTS_ASSIGNED;
    this.save();
  }

  confirmStatusCheck() {
    if (!this.invoiceItemsCoverageCheckComponent || !this.invoiceItemsCoverageCheckComponent.validateComponent()) {
      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.TREATMENT_VALIDATED;

    this.save();
  }

  editStatusCheck() {
    if (this.isStepAfter(FnolRequestStatus.TREATMENT_VALIDATED)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit Assignments',
          message: 'If you edit the assignments, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          this.undoUsedUpFranchise();
          // this.undoClassificationAssignments(); // not undoing classification, as it is quite labourous to redo
          this.undoCoverageCheck();
          this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED;

          this.save();
        }
      });

      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED;
    this.save();
  }

  confirmClassificationAssignments() {
    if (!this.classificationListComponent || !this.validateClassificationAssignments()) {
      return;
    }

    this.fnolRequest.parsedInvoiceData.treatments = this.classificationListComponent.treatmentsFilled;

    this.fnolRequest.processingStatus = FnolRequestStatus.CLASSIFICATIONS_ASSIGNED;

    this.save();

    this.groupContractForUsedUpFranchise()
  }

  validateClassificationAssignments() {
    return this.classificationListComponent?.validate() || false;
  }

  editClassificationAssignments() {
    if (this.isStepAfter(FnolRequestStatus.CLASSIFICATIONS_ASSIGNED)) {
      const dialogRef = this.dialog.open(GeneralConfirmationModalComponent, {
        data: {
          title: 'Edit Classification Assignments',
          message: 'If you edit the classification assignments, all the progress for next steps will be lost. Are you sure you want to continue?',
          confirmButton: GeneralConfirmationModalResult.Confirm,
          cancelButton: GeneralConfirmationModalResult.Cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === GeneralConfirmationModalResult.Confirm) {
          this.undoUsedUpFranchise();
          this.fnolRequest.processingStatus = FnolRequestStatus.TREATMENT_VALIDATED;

          this.save();
        }
      });

      return;
    }

    this.fnolRequest.processingStatus = FnolRequestStatus.TREATMENT_VALIDATED;
    this.save();
  }

  groupContractForUsedUpFranchise() {
    this.fnolRequest.parsedInvoiceData.treatments.forEach(treatment => {
      if (!treatment.contract) {
        return;
      }
      treatment.contract.full_contract_number_year_adjusted = treatment.contract.full_contract_number + '-' + this.getContractYear(treatment, treatment.contract)

      const findContract = this.contractsUiUsedUpFranchise.find(c => c.full_contract_number_year_adjusted === treatment.contract?.full_contract_number_year_adjusted);
      let contract: ContractUIUsedUpFranchise;

      if (findContract) {
        contract = findContract;
      } else {
        contract = {...treatment.contract, expanded: false, franchiseTypes: []};
      }

      treatment.positions.forEach(position => {
        if (position.classification && position.classification.subtype) {
          contract.franchiseTypes.push(position.classification.subtype);
        }
        if (position.classification && position.classification.preexistingCondition) {
          contract.franchiseTypes.push('preexisting_conditions_coverage');
        }
        if (position.classification && position.classification.emergencyAbroad) {
          contract.franchiseTypes.push('worldwide_protection_coverage');
        }
      });

      if (!findContract) {
        this.contractsUiUsedUpFranchise.push({...contract});
      }
    });
  }

  getContractYear(treatment: Treatment, contract: Contract) {
    const contractStart = new Date(contract.start_date);
    const treatmentDate = new Date(treatment.date);
  
    // Ensure valid date objects
    if (isNaN(contractStart.getTime()) || isNaN(treatmentDate.getTime())) {
      throw new Error('Invalid date format. Expected format: yyyy-mm-dd');
    }
  
    // Calculate the difference in years
    const yearDifference = treatmentDate.getFullYear() - contractStart.getFullYear();
  
    // Check if the treatment is within the same contract year
    const contractStartMonthDay = new Date(
      treatmentDate.getFullYear(),
      contractStart.getMonth(),
      contractStart.getDate()
    );
  
    // If the treatment date occurs before the contract start month/day in the same year
    if (treatmentDate < contractStartMonthDay) {
      return yearDifference; // Still within the previous year
    }
  
    return yearDifference + 1; // Move to the next year of the contract
  }

  confirmUsedUpFranchise() {
    const contracts = this.usedUpFranchiseComponent?.contractsWithUsedUpFranchise || [];
    const preexistingConditionsMap = this.usedUpFranchiseComponent?.preexistingConditionsMap;

    this.fnolRequest.parsedInvoiceData.treatments.forEach(treatment => {
      if (!treatment.contract) {
        return;
      }

      const contract = contracts.find(c => c.full_contract_number_year_adjusted === treatment.contract?.full_contract_number_year_adjusted);

      if (contract) {
        treatment.contract = {...contract};
      }

      treatment.positions.forEach(position => {
        if (preexistingConditionsMap && position.classification?.clusterAssignment && preexistingConditionsMap.has(position.classification.clusterAssignment)) {
          position.classification.preexistingConditionsUsedUpDeductible = preexistingConditionsMap.get(position.classification.clusterAssignment)
        }
      })
    });

    this.fnolRequest.processingStatus = FnolRequestStatus.USED_UP_FRANCHISE_FILLED;
  }

  editUsedUpFranchise() {
    this.fnolRequest.processingStatus = FnolRequestStatus.CLASSIFICATIONS_ASSIGNED;

    this.groupContractForUsedUpFranchise()
  }

  undoUsedUpFranchise() {
    this.fnolRequest.processingStatus = FnolRequestStatus.CLASSIFICATIONS_ASSIGNED;

    this.contractsUiUsedUpFranchise = [];

    if (this.fnolRequest.parsedInvoiceData?.treatments) {
      this.fnolRequest.parsedInvoiceData.treatments.forEach(item => {
        const findContract = this.contractSelected.map(c => c.contracts).flat().find(c => c.full_contract_number === this.fnolRequest.parsedInvoiceData.treatments[0].contract?.full_contract_number);
        item.contract = ({...findContract} as Contract) || null;
      });
    }

  }

  undoClassificationAssignments() {
    this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED;

    if (this.fnolRequest.parsedInvoiceData?.treatments) {
      this.fnolRequest.parsedInvoiceData.treatments.forEach(item => {
        item.positions.forEach(position => {
          position.classification = null;
        });
      });
    }

  }

  undoCoverageCheck() {
    this.fnolRequest.processingStatus = FnolRequestStatus.TREATMENT_VALIDATED;

    if (this.fnolRequest.parsedInvoiceData) {
      this.fnolRequest.parsedInvoiceData.recognizedVet = undefined;
    }

    if (this.fnolRequest.parsedInvoiceData?.treatments) {
      this.fnolRequest.parsedInvoiceData.treatments.forEach(item => {
        item.comments = item.comments?.filter(comment=>comment.addedBy === 'system');
        item.invoiceRecipientValidationStatus = undefined;
        item.dateValidationStatus = undefined;
        item.hasOverdueInvoices = undefined;
        item.dateOverdueInvoicesChecked = undefined;
        item.petNameValidationStatus = undefined;
      });
    }

  }

  undoContractsAssignments() {
    this.fnolRequest.processingStatus = FnolRequestStatus.VAT_AND_DISCOUNT_SET;

    if (this.fnolRequest.parsedInvoiceData?.treatments) {
      this.fnolRequest.parsedInvoiceData.treatments.forEach(item => {
        item.comments = [];
        item.contract = null;
      });
    }

  }

  undoContractsSearch() {
    this.fnolRequest.processingStatus = FnolRequestStatus.TODO;

    this.contractSelected = [];
  }

  undoVATAndDiscount() {
    this.fnolRequest.processingStatus = FnolRequestStatus.INVOICE_PARSED_CONFIRMED;
    if (this.fnolRequest.parsedInvoiceData) {
      this.fnolRequest.parsedInvoiceData.calculationInformation = undefined;
    }
  }

  isStepAfter(status: FnolRequestStatus) {
    return this.fnolRequest.processingStatus > status;
  }

  completeFNOLRequest() {
    this.fnolRequest.processingStatus = FnolRequestStatus.DONE;
    this.save();
  }
}
