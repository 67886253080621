<div class="claims">
  <div class="claim" *ngFor="let claim of _mfvClaims; let i = index">
    <div class="claim-info">
      <div class="claim-title">
        {{ claim.contract && claim.contract.full_contract_number ? ("mfv-claim-detail.contract-prefix" | translate) + claim.contract.full_contract_number : ('mfv-claim-detail.no-contract-assigned' | translate) }}
        ,
        {{ ("mfv-claim-detail.cluster-prefix" | translate) + claim.cmsClusterID }},
        {{ ("mfv-claim-detail.mfv-claim-id-prefix" | translate) + claim.mfvClaimID }}
      </div>
      <ng-container *ngIf="claim.expandedBig">
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.assigned-contract' | translate }}</div>
          <div class="claim-info-value">
            <mat-icon class="contract-link" (click)="openContactInNewTab(claim)">
              link
            </mat-icon>
            {{ claim.contract && claim.contract.full_contract_number ? claim.contract.full_contract_number : ('mfv-claim-detail.no-contract-assigned' | translate) }}
          </div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.mfv-claim-id' | translate }}</div>
          <mat-form-field class="claim-info-value" *ngIf="claim.contract">
            <input matInput [(ngModel)]="claim.mfvClaimID" [tabIndex]="20+i" (blur)="mfvClaimIDSet(claim)" (keydown.tab)="onTabPress(claim)">
          </mat-form-field>
          <div class="claim-info-value" *ngIf="!claim.contract">--</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.cluster' | translate }}</div>
          <div class="claim-info-value">{{ claim.cmsClusterID }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.claim-date' | translate }}</div>
          <div class="claim-info-value">{{ claim.claimDate | date:'dd.MM.YYYY' }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.precedent-cluster-id' | translate }}</div>
          <div class="claim-info-value">{{ claim.followUpTreatmentCluster }} <mat-icon class="cluster-warn" color="warn" *ngIf="showClusterWarning(claim)">
            warning
          </mat-icon></div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.precedent-claim-id' | translate }}</div>
          <div class="claim-info-value">{{ claim.followUpTreatmentClaimID }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.notification-date' | translate }}</div>
          <div class="claim-info-value">{{ claim.notificationDate | date:'dd.MM.YYYY' }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.course-of-events' | translate }}</div>
          <div class="claim-info-value">
            <mat-icon *ngIf="claim.courseOfEvents" [title]="'mfv-claim-detail.copy-to-clipboard' | translate" class="copy-to-clipboard"
                      (click)="copyToClipboard(claim.courseOfEvents)">
              content_copy
            </mat-icon>
            {{ claim.courseOfEvents }}
          </div>
        </div>
        <hr>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.type' | translate }}</div>
          <div class="claim-info-value">{{ claim.type }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.subtype' | translate }}</div>
          <div class="claim-info-value">{{ claim.subtype }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.cause' | translate }}</div>
          <div class="claim-info-value">{{ claim.cause }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.preexisting-conditions' | translate }}</div>
          <div class="claim-info-value">{{ claim.preexistingCondition | booleanTranslate }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.emergency-abroad' | translate }}</div>
          <div class="claim-info-value">{{ claim.emergencyAbroad | booleanTranslate }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.chronic-disease' | translate }}</div>
          <div class="claim-info-value">{{ claim.chronicDisease | booleanTranslate }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.obligation-fulfilled' | translate }}</div>
          <div class="claim-info-value">{{ claim.obligationsFulfilled | booleanTranslate }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.covered' | translate }}</div>
          <div class="claim-info-value">{{ claim.covered | booleanTranslate }}</div>
        </div>
        <div class="item" *ngIf="!claim.covered">
          <div class="claim-info-title">{{ 'mfv-claim-detail.reason-not-covered' | translate }}</div>
          <div class="claim-info-value">{{ claim.reasonWhyNotCovered }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.cost-coverage-percentage' | translate }}</div>
          <div class="claim-info-value">{{ claim.costCoveragePercentage }}</div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.further-clarification-comment' | translate }}</div>
          <div class="claim-info-value">
            <mat-icon *ngIf="claim.furtherClarificationComment" [title]="'mfv-claim-detail.copy-to-clipboard' | translate" class="copy-to-clipboard"
                      (click)="copyToClipboard(claim.furtherClarificationComment)">
              content_copy
            </mat-icon>
            <span>{{ claim.furtherClarificationComment }}</span>
          </div>
        </div>
        <div class="item">
          <div class="claim-info-title">{{ 'mfv-claim-detail.vet-from-invoice' | translate }}</div>
          <div class="claim-info-value">{{ formatVetFromInvoice() }}</div>
        </div>
        <div>
          <CPF-mfv-claim-calculation-detail [claim]="claim" [index]="i">
          </CPF-mfv-claim-calculation-detail>
        </div>
        <div class="item comment">
          <div class="claim-info-title">{{ 'mfv-claim-detail.comment' | translate }}</div>
          <div class="claim-info-value">
            <mat-icon (click)="copyToClipboard(claim.commentForMfv)" *ngIf="claim.commentForMfv" [title]="'mfv-claim-detail.copy-to-clipboard' | translate"
                      class="copy-to-clipboard">
              content_copy
            </mat-icon>
            <span>{{ claim.commentForMfv }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="actions">
      <mat-icon (click)="toggleExpandClaim(claim)" class="expand-button" [class.expand]="!claim.expandedBig">expand_less</mat-icon>
    </div>
  </div>
</div>

<div class="claims-actions">
  <button (click)="completeFNOLRequest()" [disabled]="!isCommentGenerated() || !allClaimsValid()" color="primary" mat-raised-button>{{ 'mfv-claim-detail.complete' | translate }}</button>

  <button (click)="generateComments()" [disabled]="!allClaimsValid()" color="primary"
          mat-raised-button>{{ 'mfv-claim-detail.generate-comments' | translate }}
  </button>

  <button (click)="generatePDF()" [disabled]="!isCommentGenerated() || !allClaimsValid()" color="accent" mat-raised-button>{{ 'mfv-claim-detail.generate-tables-for-email' | translate }}</button>
  <div *ngIf="isCommentGenerated() && allClaimsValid()">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="tableLang">
        <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div id="pdf-html-container" [innerHTML]="this.tables">
</div>