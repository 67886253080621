import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'breedTypeTranslate'
})
export class BreedTypeTranslatePipe implements PipeTransform {

  constructor(public translator: TranslateService) {
  }
  transform(value: any, ...args: unknown[]): unknown {
    if (['true','false'].includes(value!.toString().toLowerCase())){
      return this.translator.instant('common.breed-type.'+value.toString().toLowerCase());
    }else{
      return value;
    }
  }

}
