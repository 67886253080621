import {Component, Input} from '@angular/core';
import {FnolRequest, ParsedInvoiceData, Position, Treatment, TreatmentCheckComment} from "../../../models";
import { createEmptyParsedInvoiceData } from 'src/app/helpers/utility';

@Component({
  selector: 'CPF-invoice-parsed-edit',
  templateUrl: './invoice-parsed-edit.component.html',
  styleUrls: ['./invoice-parsed-edit.component.scss']
})
export class InvoiceParsedEditComponent {
  _fnolRequest!: FnolRequest

  @Input()
  set fnolRequest(fnolRequest: FnolRequest) {
    this._fnolRequest = fnolRequest;
    if (!fnolRequest.parsedInvoiceData) {
      fnolRequest.parsedInvoiceData = createEmptyParsedInvoiceData()
    }
    this.parsedInvoiceData = JSON.parse(JSON.stringify(fnolRequest.parsedInvoiceData));
  }

  parsedInvoiceData!: ParsedInvoiceData

  constructor() {
  }

  cancelChanges() {
    this.parsedInvoiceData = JSON.parse(JSON.stringify(this._fnolRequest.parsedInvoiceData));
  }

  addPosition(treatment: Treatment) {
    treatment.positions.push({
      name: 'New position ' + treatment.positions.length,
      quantity: '1',
      amount: 1,
      vatPercentage: '0',
      classification: undefined,
    });
  }

  removePosition(treatment: Treatment, position: Position) {
    const index = treatment.positions.indexOf(position);
    treatment.positions.splice(index, 1);
  }

  addTreatment() {
    if (!this.parsedInvoiceData.treatments) {
      this.parsedInvoiceData.treatments = []
    }
    this.parsedInvoiceData.treatments.push({
      comments: new Array<TreatmentCheckComment>(),
      contract: null,
      date: "",
      diagnosis: "",
      petName: "New pet " + this.parsedInvoiceData.treatments.length,
      positions: [{
        name: 'New position 1',
        quantity: '1',
        amount: 1,
        vatPercentage: '0',
        classification: undefined,
      }]
    });
  }

  removeTreatment(treatment: Treatment) {
    const index = this.parsedInvoiceData.treatments.indexOf(treatment);
    this.parsedInvoiceData.treatments.splice(index, 1);
  }

  validate() {
    if (!this.parsedInvoiceData?.invoiceMetadata?.issuerName) {
      return false;
    }

    /*if (!this.parsedInvoiceData.invoiceMetadata.issuerAddress) {
      return false;
    }

    if (!this.parsedInvoiceData.invoiceMetadata.recipientName) {
      return false;
    }

    if (!this.parsedInvoiceData.invoiceMetadata.recipientAddress) {
      return false;
    }

    if (!this.parsedInvoiceData.invoiceMetadata.invoiceNumber) {
      return false;
    }

    if (!this.parsedInvoiceData.invoiceMetadata.invoiceDate) {
      return false;
    }

    if (!this.parsedInvoiceData.invoiceMetadata.language) {
      return false;
    }*/

    if (!this.parsedInvoiceData.treatments) {
      return false;
    }

    if (!this.parsedInvoiceData.treatments.every(treatment => {
      if (!treatment.date) {
        return false;
      }

      /*
      if (!treatment.diagnosis) {
        return false;
      }
      */

      if (!treatment.petName) {
        return false;
      }

      if (!treatment.positions.every(position => {
        if (!position.hasOwnProperty('amount') || position.amount === null) {
          return false;
        }

        if (!position.name) {
          return false;
        }

        /*
        if (!position.quantity) {
          return false;
        }
        */

        /*
        if (!position.vatPercentage) {
          return false;
        }
        */

        return true;
      })) {
        return false;
      }

      return true;
    })) {
      return false;
    }

    return true;
  }
}
