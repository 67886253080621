import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'CPF-custom-snackbar-error',
  templateUrl: './custom-snackbar-error.component.html',
  styleUrls: ['./custom-snackbar-error.component.scss']
})
export class CustomSnackbarErrorComponent {
  constructor(private matSnackBarRef:MatSnackBarRef<CustomSnackbarErrorComponent>,@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  copyError() {
    navigator.clipboard.writeText(this.data.error);
    this.matSnackBarRef.dismiss();
  }
}
