<main>
  <div class="container">
    <h2>{{ 'fnol-request-find.title' | translate }}</h2>
    <!--
    <div class="top-row">
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.progress-label' | translate }}</mat-label>
        <mat-select [(ngModel)]="search.progress">
          <mat-option value="Any">{{ 'fnol-request-find.progress-any' | translate }}</mat-option>
          <mat-option value="New">{{ 'fnol-request-find.progress-new' | translate }}</mat-option>
          <mat-option value="InProgress">{{ 'fnol-request-find.progress-in-progress' | translate }}</mat-option>
          <mat-option value="Closed">{{ 'fnol-request-find.progress-closed' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.assignee-label' | translate }}</mat-label>
        <mat-select [(ngModel)]="search.assignee">
          <mat-option value="Any">{{ 'fnol-request-find.assignee-any' | translate }}</mat-option>
          <mat-option value="Unassigned">{{ 'fnol-request-find.assignee-unassigned' | translate }}</mat-option>
          <mat-option [value]="user.email" *ngFor="let user of users">{{ user.firstname }} {{ user.lastname }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    -->
    <div class="search-form">
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.firstname-label' | translate }}</mat-label>
        <input matInput [(ngModel)]="search.fnolFirstName"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.lastname-label' | translate }}</mat-label>
        <input matInput [(ngModel)]="search.fnolLastName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.email-label' | translate }}</mat-label>
        <input matInput [(ngModel)]="search.fnolEmail">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.phone-label' | translate }}</mat-label>
        <input matInput [(ngModel)]="search.fnolPhone">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'fnol-request-find.pet-name-label' | translate }}</mat-label>
        <input matInput [(ngModel)]="search.fnolPetName">
      </mat-form-field>
    </div>
    <div class="actions">
      <button (click)="clearAll()" color="primary" mat-raised-button>{{ 'fnol-request-find.clear-button' | translate }}</button>
      <button (click)="searchFnolRequests()" color="primary" mat-raised-button>{{ 'fnol-request-find.search-button' | translate }}</button>
    </div>
  </div>
</main>
