import {Component, Input} from '@angular/core';
import {CoveredPosition} from "../../../models";

@Component({
  selector: 'CPF-mfv-positions-details',
  templateUrl: './mfv-positions-details.component.html',
  styleUrls: ['./mfv-positions-details.component.scss']
})
export class MfvPositionsDetailsComponent {
  @Input()
  positions: {
    coveredPositions: CoveredPosition[],
    nonCoveredPositions: CoveredPosition[],
    totalCovered: number,
    totalNonCovered: number
  } = {coveredPositions: [], nonCoveredPositions: [], totalCovered: 0, totalNonCovered: 0};

  constructor() {
  }
}
