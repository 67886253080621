import { Component, OnInit } from '@angular/core';
import {
  CognitoUser,
  FnolRequest,
  fnolRequestExample,
  fnolRequestExampleFull,
  FnolRequestStatus,
  FnolRequestStatusFilter, TasksLabels
} from "../../../models";
import { MatDialog } from "@angular/material/dialog";
import { FnolRequestFindComponent } from "../fnol-request-find/fnol-request-find.component";
import { CognitoUsersService } from "../../../services/cognito-users.service";
import { ApiHttpService } from "../../../services/api-http.service";
import { SidebarDetailsService } from "../../../services/sidebar-details.service";
import { Router } from "@angular/router";

const USE_MOCK = false;

@Component({
  selector: 'CPF-fnol-request-list',
  templateUrl: './fnol-request-list.component.html',
  styleUrls: ['./fnol-request-list.component.scss']
})
export class FnolRequestListComponent implements OnInit {
  public fnolRequests: FnolRequest[] = []
  public filteredFnolRequests: FnolRequest[] = [];
  public usersList: CognitoUser[] = [];
  public assigneeFilter: string = 'Any';
  public progressFilter: FnolRequestStatusFilter = FnolRequestStatusFilter.TODO;
  public labelFilter: TasksLabels[] = []
  public listSort: string = '+notifiDate';

  public TasksLabels = TasksLabels;
  public FnolRequestStatusFilter = FnolRequestStatusFilter;

  constructor(private cognitoUsersService: CognitoUsersService,
              private dialog: MatDialog,
              private apiHttpService: ApiHttpService,
              private sidebarDetailsService: SidebarDetailsService,
              private router: Router) {
    if (USE_MOCK) {
      this.fnolRequests = [fnolRequestExample, fnolRequestExampleFull];
      this.filteredFnolRequests = this.fnolRequests;
    }
  }

  async ngOnInit() {
    this.usersList = await this.cognitoUsersService.getCognitoUsers();

    this.assigneeFilter = localStorage.getItem('assigneeFilter') || 'Any';
    this.labelFilter = JSON.parse(localStorage.getItem('labelFilter') || '[]');
    this.listSort = localStorage.getItem('listSort') || '+notifiDate';

    // Retrieve the progressFilter from localStorage when the component is initialized
    const storedValue = localStorage.getItem('progressFilter');
    if (storedValue) {
      // If stored value is a string (i.e., 'ALL'), treat it as a string value from the enum
      if (storedValue === FnolRequestStatusFilter.ALL) {
        this.progressFilter = FnolRequestStatusFilter.ALL;
      } else {
        // For numeric values, parse them as integers
        this.progressFilter = parseInt(storedValue) as FnolRequestStatusFilter;
      }
    }


    this.apiHttpService.findFnolRequestAPI({
      progress: FnolRequestStatusFilter.TODO.valueOf(),
      assignee: 'Any',
    }).then((fnolRequests: FnolRequest[]) => {
      this.fnolRequests = fnolRequests;
      this.filteredFnolRequests = this.fnolRequests;
      this.filterByProgressAndAssigneeAndLabels(this.progressFilter, this.assigneeFilter, this.labelFilter);
      this.sortList();
    });
  }

  ngOnDestroy(): void {
    // Save state to localStorage
    localStorage.setItem('assigneeFilter', this.assigneeFilter);
    localStorage.setItem('progressFilter', this.progressFilter.toString());
    localStorage.setItem('labelFilter', JSON.stringify(this.labelFilter));
    localStorage.setItem('listSort', this.listSort);
  }

  getUserByEmail(email: string): CognitoUser | undefined {
    return this.usersList.find(user => user.email === email);
  }

  statusString(status: FnolRequestStatus): string {
    switch (status) {
      case FnolRequestStatus.TODO:
        return 'fnol-request-list.statuses.TODO';
      case FnolRequestStatus.FNOL_DATA_CONFIRMED:
        return 'fnol-request-list.statuses.FNOL_DATA_CONFIRMED';
      case FnolRequestStatus.INVOICE_PARSED_CONFIRMED:
        return 'fnol-request-list.statuses.INVOICE_PARSED_CONFIRMED';
      case FnolRequestStatus.VAT_AND_DISCOUNT_SET:
        return 'fnol-request-list.statuses.VAT_AND_DISCOUNT_SET';
      case FnolRequestStatus.CONTRACTS_ASSIGNED:
        return 'fnol-request-list.statuses.CONTRACTS_ASSIGNED';
      case FnolRequestStatus.TREATMENT_VALIDATED:
        return 'fnol-request-list.statuses.TREATMENT_VALIDATED';
      case FnolRequestStatus.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED:
        return 'fnol-request-list.statuses.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED';
      case FnolRequestStatus.CLASSIFICATIONS_ASSIGNED:
        return 'fnol-request-list.statuses.CLASSIFICATIONS_ASSIGNED';
      case FnolRequestStatus.USED_UP_FRANCHISE_FILLED:
        return 'fnol-request-list.statuses.USED_UP_FRANCHISE_FILLED';
      case FnolRequestStatus.PAYMENT_CALCULATED:
        return 'fnol-request-list.statuses.PAYMENT_CALCULATED';
      case FnolRequestStatus.DONE:
        return 'fnol-request-list.statuses.DONE';
      case FnolRequestStatus.NO_PROCESSING:
        return 'fnol-request-list.statuses.NO_PROCESSING'
      default:
        return 'fnol-request-list.statuses.UNKNOWN';
    }
  }

  isUnclosedTasks(fnolRequest: FnolRequest) {
    if (!fnolRequest || !fnolRequest.tasks) {
      return false;
    }

    return fnolRequest.tasks.some(t => !t.closingDate);
  }

  hasReseverClaim(fnolRequest: FnolRequest): boolean {
    return fnolRequest && !!fnolRequest.mfvReserveClaimID
  }

  assigneeFilterChange(assigneeFilter: string) {
    this.filterByProgressAndAssigneeAndLabels(this.progressFilter, assigneeFilter, this.labelFilter);
  }

  progressFilterChange(progressFilter: FnolRequestStatusFilter) {
    this.progressFilter = progressFilter;
    this.filterByProgressAndAssigneeAndLabels(progressFilter, this.assigneeFilter, this.labelFilter);
  }

  labelFilterChange(labelFilter: TasksLabels[]) {
    this.labelFilter = labelFilter;
    this.filterByProgressAndAssigneeAndLabels(this.progressFilter, this.assigneeFilter, labelFilter);
  }

  formatAssignee(assignee: string): string {
    return assignee ? assignee.split('@')[0] : 'Unassigned';
  }

  toggleLabelInFilter(label: TasksLabels) {
    if (this.labelFilter.includes(label)) {
      this.labelFilter = this.labelFilter.filter(l => l !== label);
    } else {
      this.labelFilter.push(label)
    }

    this.filterByProgressAndAssigneeAndLabels(this.progressFilter, this.assigneeFilter, this.labelFilter);
  }

  isLabelFiltered(label: TasksLabels) {
    return this.labelFilter.includes(label)
  }

  isLabelChecked(label: TasksLabels, fnolRequest: FnolRequest) {
    if (!fnolRequest || !fnolRequest.labels) {
      return false;
    }

    return fnolRequest.labels.includes(label);
  }

  filterByProgressAndAssigneeAndLabels(progress: FnolRequestStatusFilter, assignee: string, labels: TasksLabels[]) {
    this.filteredFnolRequests = this.fnolRequests.filter(fnolRequest => {
      return this.checkAssignee(fnolRequest, assignee) && this.checkProgress(fnolRequest, progress) && this.checkLabels(fnolRequest, labels);
    });
    this.sortList()
  }

  checkAssignee(fnolRequest: FnolRequest, assignee: string) {
    if (assignee === 'Any') {
      return true;
    }

    if (assignee === 'Unassigned' && (fnolRequest.processingAssignee === '' || !fnolRequest.processingAssignee)) {
      return true;
    }

    if (fnolRequest.processingAssignee === assignee) {
      return true;
    }

    return false;
  }

  checkProgress(fnolRequest: FnolRequest, progress: FnolRequestStatusFilter): boolean {
    if (progress == FnolRequestStatusFilter.ALL) {
      return true;
    }

    //Consider fnol data confirmed and invoice parsed confirmed as initial status of fnol request
    if (fnolRequest.processingStatus.valueOf() >= 0 && fnolRequest.processingStatus.valueOf() <= 2 && progress == FnolRequestStatusFilter.TODO) {
      return true;
    }

    if (fnolRequest.processingStatus.valueOf() > 2 && fnolRequest.processingStatus.valueOf() < 98 && progress == FnolRequestStatusFilter.IN_PROGRESS) {
      return true;
    }

    if ((fnolRequest.processingStatus == FnolRequestStatus.DONE || fnolRequest.processingStatus == FnolRequestStatus.NO_PROCESSING) && progress == FnolRequestStatusFilter.DONE) {
      return true;
    }

    return false;
  }

  checkLabels(fnolRequest: FnolRequest, labels: TasksLabels[]): boolean {
    if (labels.length === 0) {
      return true
    }

    if (!fnolRequest.labels || fnolRequest.labels.length === 0) {
      return false
    }

    return labels.every(label => fnolRequest.labels ? fnolRequest.labels.includes(label) : false)
  }

  openDetails(fnolRequest: FnolRequest) {
    this.router.navigate(['platform', 'fnol-request', fnolRequest.id]);
  }

  openFindModal() {
    const dialogRef = this.dialog.open(FnolRequestFindComponent, {
      minWidth: '50%',
      width: '100%',
      data: { users: this.usersList }
    });

    dialogRef.afterClosed().subscribe(result => {
      let search = result;

      this.apiHttpService.findFnolRequestAPI(search).then((fnolRequests: FnolRequest[]) => {
        this.listSort = '';
        this.progressFilter = FnolRequestStatusFilter.ALL;
        this.fnolRequests = fnolRequests || [];
        this.filteredFnolRequests = this.fnolRequests;
      });
    });
  }

  openSideDetails(fnolRequest: FnolRequest) {
    this.sidebarDetailsService.setFnolRequest(fnolRequest);
    this.sidebarDetailsService.open();
  }

  sortBy(field: 'prio' | 'notifiDate') {
    if (!this.listSort || this.listSort.indexOf(field) === -1) {
      this.listSort = '+' + field;
    } else if (this.listSort.indexOf('+') >= 0) {
      this.listSort = '-' + field;
    } else {
      this.listSort = '';
    }

    this.sortList();
  }

  sortList() {
    if (!this.listSort) {
      return;
    }

    const direction = this.listSort.indexOf('+') >= 0 ? -1 : 1;
    const fieldShort = this.listSort.slice(1);
    const field = fieldShort === 'prio' ? 'priority' : 'notificationDate';

    if (field === 'priority') {
      this.filteredFnolRequests = this.filteredFnolRequests.sort((a, b) => {
        if (a.priority < b.priority) {
          return -1 * direction;
        }

        if (a.priority > b.priority) {
          return 1 * direction;
        }

        if (new Date(a.notificationDate).getTime() < new Date(b.notificationDate).getTime()) {
          return -1 * direction;
        }

        if (new Date(a.notificationDate).getTime() > new Date(b.notificationDate).getTime()) {
          return 1 * direction;
        }

        return 0;
      });
    }

    if (field === 'notificationDate') {
      this.filteredFnolRequests = this.filteredFnolRequests.sort((a, b) => {
        if (new Date(a.notificationDate).getTime() < new Date(b.notificationDate).getTime()) {
          return -1 * direction;
        }

        if (new Date(a.notificationDate).getTime() > new Date(b.notificationDate).getTime()) {
          return 1 * direction;
        }

        if (a.priority < b.priority) {
          return -1 * direction;
        }

        if (a.priority > b.priority) {
          return 1 * direction;
        }

        return 0;
      });
    }
  }

  countFnolRequestsInProgress(progress: FnolRequestStatusFilter): number {
    return this.fnolRequests.reduce((acc, fr) => {
      if (this.checkProgress(fr, progress)) {
        return acc + 1
      } else {
        return acc
      }
    }, 0)
  }
}
