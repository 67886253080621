import {Component, Inject} from '@angular/core';
import {Contract, ContractStatus} from "../../../models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'CPF-contract-assign-list-modal',
  templateUrl: './contract-assign-list-modal.component.html',
  styleUrls: ['./contract-assign-list-modal.component.scss']
})
export class ContractAssignListModalComponent {
  public contracts: Contract[] = [];

  constructor(public dialogRef: MatDialogRef<ContractAssignListModalComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    contracts: Contract[]
  }) {
    this.contracts = data.contracts;
  }

  public selectContract(contract: Contract | "empty") {
    this.dialogRef.close(contract);
  }

  getStatusTranslationKey(contract: Contract) {
    return contract.status === ContractStatus.ACTIVE ? 'active' : 'expired';
  }
}
