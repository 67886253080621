import {Injectable} from '@angular/core';
import {ApiHttpService} from "./api-http.service";
import {CognitoUser} from "../models";

@Injectable({
  providedIn: 'root'
})
export class CognitoUsersService {
  private _cognitoUsers: CognitoUser[] = [];

  constructor(private apiHttpService: ApiHttpService) {
  }

  async getCognitoUsers():Promise<CognitoUser[]> {
    if (this._cognitoUsers.length === 0) {
      const users = await this.apiHttpService.getUsers() as unknown as CognitoUser[]; // cast to any[]

      if (!users) {
        return [];
      }

      this._cognitoUsers = users;
      return this._cognitoUsers;
    } else {
      return this._cognitoUsers;
    }
  }
}
