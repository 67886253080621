<div class="title-2">{{ 'invoice-items-coverage-check-preview.coverage-check-performed-title' | translate }}</div>

<div class="container" *ngIf="fnolRequest.parsedInvoiceData">
    <div class="content-container" *ngIf="!expanded"></div>
    <div class="content-container" *ngIf="expanded">
      <div class="info-and-assign-container" *ngFor="let treatment of fnolRequest.parsedInvoiceData.treatments">
        <div class="info-and-assign-details">
          <div class="small-info grid-info">
            <span>
                <mat-icon>calendar_today</mat-icon>
                {{ treatment.date | date:'dd.MM.YYYY' }}
            </span>
            <span>
                <mat-icon>pets</mat-icon><mat-icon>receipt_long</mat-icon>
                {{ treatment.petName }}
            </span>
            <span>
                <div>
                    <span>
                      <mat-icon>pets</mat-icon><mat-icon>description</mat-icon>
                      {{ treatment.contract?.pet_name }}
                        <mat-icon>
                            {{ validationStatusToMatIcon(treatment.petNameValidationStatus) }}
                        </mat-icon>
                    </span>
                    <div class="comments">
                        <div *ngFor="let comment of filterCommentsByPetName(treatment.comments)">
                        {{ comment }}
                        </div>
                    </div>
                </div>
            </span>
            <span>
                <mat-icon>person</mat-icon><mat-icon>receipt_long</mat-icon>
                {{ fnolRequest.parsedInvoiceData.invoiceMetadata.recipientName || '--' }}
            </span>
            <span>
              <div>
                <span>
                    <mat-icon>person</mat-icon><mat-icon>description</mat-icon>
                    {{ treatment.contract?.firstname }} {{ treatment.contract?.lastname }}
                    <mat-icon>
                        {{ validationStatusToMatIcon(treatment.invoiceRecipientValidationStatus) }}
                    </mat-icon>
                </span>
                <div class="comments">
                    <div *ngFor="let comment of filterCommentsByRecipientName(treatment.comments)">
                    {{ comment }}
                    </div>
                </div>
            </div>
            </span>
          </div>
        </div>
  
        <div *ngIf="!treatment.contract">
          <div class="no-contract-selected">
            {{ 'invoice-assign-items-preview.no-contract-selected' | translate }}
          </div>
        </div>
      </div>
  
    </div>
    <div class="actions">
      <mat-icon (click)="expanded = !expanded" class="expand-button" [class.expand]="!expanded">expand_less</mat-icon>
    </div>
  </div>