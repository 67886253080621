<main id="scrolled">
  <div class="container">
    <h2>{{ 'fnol-request-list.title' | translate }}</h2>
    <div class="search-actions">
      <div class="filter-progress-container">
        <div class="filter-progress" (click)="progressFilterChange(FnolRequestStatusFilter.ALL)"
             [class.active]="progressFilter===FnolRequestStatusFilter.ALL">
          {{ 'fnol-request-list.status-all' | translate }} ({{ countFnolRequestsInProgress(FnolRequestStatusFilter.ALL) }})
        </div>
        <div class="filter-progress" (click)="progressFilterChange(FnolRequestStatusFilter.TODO)"
             [class.active]="progressFilter===FnolRequestStatusFilter.TODO">
          {{ 'fnol-request-list.status-new' | translate }} ({{ countFnolRequestsInProgress(FnolRequestStatusFilter.TODO) }})
        </div>
        <div class="filter-progress" (click)="progressFilterChange(FnolRequestStatusFilter.IN_PROGRESS)"
             [class.active]="progressFilter===FnolRequestStatusFilter.IN_PROGRESS">
          {{ 'fnol-request-list.status-in-progress' | translate }} ({{ countFnolRequestsInProgress(FnolRequestStatusFilter.IN_PROGRESS) }})
        </div>
        <div class="filter-progress" (click)="progressFilterChange(FnolRequestStatusFilter.DONE)"
             [class.active]="progressFilter===FnolRequestStatusFilter.DONE">
          {{ 'fnol-request-list.status-completed' | translate }} ({{ countFnolRequestsInProgress(FnolRequestStatusFilter.DONE) }})
        </div>
      </div>
      <div class="flex-placeholder"></div>
      <div class="filter-assignee-container">
        <mat-form-field>
          <mat-label>{{ 'fnol-request-list.assignee-label' | translate }}</mat-label>
          <mat-select [(ngModel)]="assigneeFilter" (selectionChange)="assigneeFilterChange($event.value)">
            <mat-option value="">{{ 'fnol-request-list.assignee-any' | translate }}</mat-option>
            <mat-option value="-">{{ 'fnol-request-list.assignee-unassigned' | translate }}</mat-option>
            <mat-option [value]="user.email" *ngFor="let user of usersList">{{ user.firstname }} {{ user.lastname }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="labels-container">
        <section>
          <mat-checkbox [checked]="isLabelFiltered(TasksLabels.SIMPLE_CLAIM)"
                        (change)="toggleLabelInFilter(TasksLabels.SIMPLE_CLAIM)">{{ 'fnol-request-side-bar.labels.simple_claim' | translate }}
          </mat-checkbox>
          <mat-checkbox [checked]="isLabelFiltered(TasksLabels.MULTIPLE_CLAIMS)"
                        (change)="toggleLabelInFilter(TasksLabels.MULTIPLE_CLAIMS)">{{ 'fnol-request-side-bar.labels.multiple_claims' | translate }}
          </mat-checkbox>
          <mat-checkbox [checked]="isLabelFiltered(TasksLabels.WAITING)"
                        (change)="toggleLabelInFilter(TasksLabels.WAITING)">{{ 'fnol-request-side-bar.labels.waiting' | translate }}
          </mat-checkbox>
          <mat-checkbox [checked]="isLabelFiltered(TasksLabels.SIMPEGO)"
                        (change)="toggleLabelInFilter(TasksLabels.SIMPEGO)">{{ 'fnol-request-side-bar.labels.simpego' | translate }}
          </mat-checkbox>
        </section>
      </div>
      <div class="search-actions-button">
        <button (click)="openFindModal()" color="primary"
                mat-raised-button>{{ 'fnol-request-list.button-search' | translate }}
        </button>
        <button (click)="fetchAllFnolRequests(); resetSearch()" color="primary"
          mat-raised-button>{{ 'fnol-request-list.button-fetch-all' | translate }}
        </button>
      </div>
    </div>

    <div class="sort-container">
      <div class="sort-title">{{ 'fnol-request-list.sort-title' | translate }}</div>
      <div (click)="sortBy('prio')" class="sort-item pointer">{{ 'fnol-request-list.sort-priority' | translate }}
        <mat-icon *ngIf="listSort!='+prio' && listSort !='-prio'">height</mat-icon>
        <mat-icon *ngIf="listSort=='+prio'">north</mat-icon>
        <mat-icon *ngIf="listSort=='-prio'">south</mat-icon>
      </div>

      <div (click)="sortBy('notifiDate')"
           class="sort-item pointer">{{ 'fnol-request-list.sort-notification-date' | translate }}
        <mat-icon *ngIf="listSort!='+notifiDate' && listSort !='-notifiDate'">height</mat-icon>
        <mat-icon *ngIf="listSort=='+notifiDate'">north</mat-icon>
        <mat-icon *ngIf="listSort=='-notifiDate'">south</mat-icon>
      </div>
    </div>

    <div class="search-results-container">
      <ng-container *ngIf="filteredFnolRequests.length else noResults">
        <div class="result" *ngFor="let fnolRequest of filteredFnolRequests">
          <div class="item-header">
            <div class="label-value simple" [title]="'fnol-request-side-bar.labels.simple_claim' | translate" [class.not-checked]="!isLabelChecked(TasksLabels.SIMPLE_CLAIM, fnolRequest)"></div>
            <div class="label-value multiple" [title]="'fnol-request-side-bar.labels.multiple_claims' | translate" [class.not-checked]="!isLabelChecked(TasksLabels.MULTIPLE_CLAIMS, fnolRequest)"></div>
            <div class="label-value waiting" [title]="'fnol-request-side-bar.labels.waiting' | translate" [class.not-checked]="!isLabelChecked(TasksLabels.WAITING, fnolRequest)"></div>
            <div class="label-value simpego" [title]="'fnol-request-side-bar.labels.simpego' | translate" [class.not-checked]="!isLabelChecked(TasksLabels.SIMPEGO, fnolRequest)"></div>
          </div>

          <div class="list-item">
            <div class="list-value">
              <mat-icon color="primary" *ngIf="isUnclosedTasks(fnolRequest)">pending_actions</mat-icon>
            </div>

              <div class="list-value">
                <mat-icon color="primary" *ngIf="hasReseverClaim(fnolRequest)">attach_money</mat-icon>
              </div>

            <div class="list-value priority-circle">
              <mat-icon *ngIf="fnolRequest.priority===1">priority_high</mat-icon>
            </div>
            <div class="list-value">{{ fnolRequest.notificationDate | date: 'dd MMM yyyy, HH:mm' }}</div>
            <div class="list-value">{{ fnolRequest.fnolFirstName }}</div>
            <div class="list-value">{{ fnolRequest.fnolLastName }}</div>
            <div class="list-value">{{ fnolRequest.fnolEmail }}</div>
            <div class="list-value">{{ fnolRequest.fnolPetName }}</div>
            <div class="list-value status">{{ statusString(fnolRequest.processingStatus) | translate }}</div>
            <div class="list-value assignee"
                 *ngIf="getUserByEmail(fnolRequest.processingAssignee) as assignee; else noAssignee"
                 [title]="assignee.email">
              <div class="assignee-circle">{{ assignee.firstname[0].toUpperCase() }}</div>
              {{ assignee.firstname }}
            </div>
            <ng-template #noAssignee>
              <div class="list-value assignee">-</div>
            </ng-template>
            <div class="list-value action">
              <button mat-icon-button color="primary" (click)="openSideDetails(fnolRequest)">
                <mat-icon>arrow_right_alt</mat-icon>
              </button>
              <button (click)="openDetails(fnolRequest)" color="primary"
                      mat-raised-button>{{ 'fnol-request-list.button-details' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="load-mode" *ngIf="lastEvaluatedKey && !USE_SCROLL_TO_LOAD">
          <button (click)="loadMoreFnolRequests()" color="primary"
                  mat-raised-button>{{ 'fnol-request-list.button-load-more' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #noResults>
        <div class="no-results">
          <p>{{ 'fnol-request-list.no-results' | translate }}</p>
        </div>
      </ng-template>
    </div>
  </div>
</main>
