import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UiKitComponent} from "./ui-kit/ui-kit.component";
import {TestComponent} from "./test/test.component";
import {AccountComponent} from "./account/account.component";
import {FindContractPageComponent} from "./find-contract-page/find-contract-page.component";
import {FnolDetailsComponent} from "./components/fnol/fnol-details/fnol-details.component";
import {FnolRequestComponent} from "./components/fnol/fnol-request/fnol-request.component";
import {FnolRequestListComponent} from "./components/fnol/fnol-request-list/fnol-request-list.component";
import {MfvClaimCalculationComponent} from "./components/contract/mfv-claim-calculation/mfv-claim-calculation.component";
import {FnolRequestPageComponent} from "./fnol-request-page/fnol-request-page.component";
import {dataResolver} from "./resolvers/data.resolver";

const routes: Routes = [
  {
    path: 'platform', resolve: {data: dataResolver}, children: [
      {path: 'fnol-request-list', component: FnolRequestListComponent},
      {path: 'fnol-request/:id', component: FnolRequestPageComponent},
      /** FOR DEVELOPMENT ONLY */
      {path: 'fnol-details', component: FnolDetailsComponent},
      {path: 'find-contract', component: FindContractPageComponent},
      {path: 'fnol-request', component: FnolRequestComponent},
      {path: 'mfv-claims-test', component: MfvClaimCalculationComponent},
    ]
  },
  {path: 'account', component: AccountComponent},
  {path: 'ui-kit', component: UiKitComponent},
  {path: 'test', component: TestComponent},
  {path: '**', redirectTo: 'account'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
