<h2>{{ 'vat-and-discount-details.edit-info' | translate }}</h2>
<div *ngIf="_fnolRequest?.parsedInvoiceData">

  <div class="details-with-title">
    <h4>{{ 'vat-and-discount-details.vat-title' | translate }}</h4>

    <div class="details-form-text">
      <section>
        <mat-label>{{ 'vat-and-discount-details.vat-contained-in-positions' | translate }}</mat-label>
        <mat-checkbox [(ngModel)]="_fnolRequest.parsedInvoiceData.calculationInformation!.vatContainedInPositions"></mat-checkbox>
      </section>

      <mat-form-field>
        <mat-label>{{ 'vat-and-discount-details.total-included-vat' | translate }}</mat-label>
        <input matInput type="number" (input)="calculateToDistribute()" [(ngModel)]="_fnolRequest.parsedInvoiceData.calculationInformation!.totalInclVat">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'vat-and-discount-details.total-excluded-vat' | translate }}</mat-label>
        <input matInput type="number"  (input)="calculateToDistribute()" [(ngModel)]="_fnolRequest.parsedInvoiceData.calculationInformation!.totalExclVat">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'vat-and-discount-details.vat-amount-to-distribute' | translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="_fnolRequest.parsedInvoiceData.calculationInformation!.vatAmountToDistribute">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'vat-and-discount-details.vat-percentage-to-distribute' | translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="_fnolRequest.parsedInvoiceData.calculationInformation!.vatPercentageToDistribute">
      </mat-form-field>
    </div>
  </div>

  <div class="details-with-title">
    <h4>{{ 'vat-and-discount-details.discount-title' | translate }}</h4>

    <div class="details-form-text">
      <mat-form-field>
        <mat-label>{{ 'vat-and-discount-details.applicable-discount-amount' | translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="_fnolRequest.parsedInvoiceData.calculationInformation!.applicableDiscountAmount">
      </mat-form-field>
    </div>
  </div>

</div>
