<div class="title-2">{{'find-contract.selected-contracts-title' | translate}}</div>

<div class="contract" *ngFor="let contract of contracts">
  <div class="info" *ngIf="contract.expanded">
    <div class="contract-number">
      <label>{{ 'find-contract.contract-number' | translate }}</label>
      <p>{{ contract.contractNumberFirstPart }}</p>
    </div>

    <div class="pet-name">
      <label>{{ 'find-contract.pet-name' | translate }}</label>
      <p>{{ contract.pet_name }}</p>
    </div>

    <div class="first-name">
      <label>{{ 'find-contract.first-name' | translate }}</label>
      <p>{{ contract.firstname }}</p>
    </div>

    <div class="last-name">
      <label>{{ 'find-contract.last-name' | translate }}</label>
      <p>{{ contract.lastname }}</p>
    </div>

    <div class="email">
      <label>{{ 'find-contract.email' | translate }}</label>
      <p>{{ contract.email }}</p>
    </div>

    <div class="phone">
      <label>{{ 'find-contract.phone' | translate }}</label>
      <p>{{ contract.phoneNumber }}</p>
    </div>

    <div class="pet-birth-date">
      <label>{{ 'find-contract.pet-birth-date' | translate }}</label>
      <p>{{ contract.birthdate | date }}</p>
    </div>
  </div>

  <div class="small-info" *ngIf="!contract.expanded">
    <span><mat-icon>description</mat-icon>
      {{ contract.contractNumberFirstPart }}
    </span>
    <span><mat-icon>pets</mat-icon>
      {{ contract.pet_name }}
    </span>
    <span><mat-icon>person</mat-icon>
      {{ contract.firstname }} {{ contract.lastname }}
    </span>
  </div>

  <div class="actions">
    <mat-icon (click)="toggleExpand(contract)" class="expand-button" [class.expand]="!contract.expanded">expand_less</mat-icon>
  </div>
</div>
