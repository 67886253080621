import {Component, Inject} from '@angular/core';
import {FnolRequest, fnolRequestExample} from "../../../models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'CPF-task-list-modal',
  templateUrl: './task-list-modal.component.html',
  styleUrls: ['./task-list-modal.component.scss']
})
export class TaskListModalComponent {
  fnolRequest?: FnolRequest;

  constructor(public dialogRef: MatDialogRef<TaskListModalComponent>, @Inject(MAT_DIALOG_DATA) public data: FnolRequest) {
    this.fnolRequest = data;
  }

  close(): void {
    this.dialogRef.close();
  }
}
