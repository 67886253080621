<div *ngIf="_fnolRequest" class="container">
  <div class="item">
    <div>
      {{ _fnolRequest.parsedInvoiceData.invoiceMetadata.issuerName }}
    </div>

    <div>
      {{ _fnolRequest.parsedInvoiceData.invoiceMetadata.issuerAddress }}
    </div>
    <section class="recognized-vet-actions">
      <mat-label>{{ 'invoice-items-coverage-check.recognized-vet' | translate }}</mat-label>
      <button mat-raised-button [color]="getButtonColor(true)"
              (click)="setRecognizedVet(true)">{{ 'invoice-items-coverage-check.yes-button' | translate }}
      </button>
      <button mat-raised-button [color]="getButtonColor(false)"
              (click)="setRecognizedVet(false)">{{ 'invoice-items-coverage-check.no-button' | translate }}
      </button>
    </section>
  </div>
  <div class="pet-names-items">
    <div class="item" *ngFor="let treatment of _fnolRequest.parsedInvoiceData.treatments; let i=index">
      <ng-container *ngIf="treatment.contract">
        <div class="title"><span class="title-text">{{ 'invoice-items-coverage-check.treatment-title' | translate }}</span> <span><mat-icon>pets</mat-icon>
          {{ treatment.petName }}
          </span> - <span>
              <mat-icon>description</mat-icon>
          {{ treatment.contract.full_contract_number }}
          <mat-icon class="pointer" (click)="openContractDetailsSidebar(treatment.contract)">open_in_full</mat-icon></span>
          <mat-icon class="pointer contract-link" (click)="openContactInNewTab(treatment.contract)">
            link
          </mat-icon>
        </div>
        <section class="recognized-vet-actions">
          <mat-label>{{ 'invoice-items-coverage-check.has-overdue-payments' | translate }}</mat-label>
          <button mat-raised-button [color]="getOverdueButtonColor(treatment, true)"
                  (click)="setOverdue(treatment, true)">{{ 'invoice-items-coverage-check.yes-button' | translate }}
          </button>
          <button mat-raised-button [color]="getOverdueButtonColor(treatment, false)"
                  (click)="setOverdue(treatment, false)">{{ 'invoice-items-coverage-check.no-button' | translate }}
          </button>
        </section>
        <div class="treatment-items-list">
          <div class="treatment-item">
            <div class="title-text">{{ 'invoice-items-coverage-check.invoice-recipient-title' | translate }}</div>
            <div class="status">
              <div *ngIf="treatment.invoiceRecipientValidationStatus === InvoiceItemsCoverageCheckStatus.NOT_NECESSARY" class="not-necessary">
                {{ 'invoice-items-coverage-check.recipient-correct' | translate }}
              </div>
              <div *ngIf="treatment.invoiceRecipientValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN" class="necessary">
                {{ 'invoice-items-coverage-check.recipient-incorrect' | translate }}
              </div>
              <div *ngIf="treatment.invoiceRecipientValidationStatus === InvoiceItemsCoverageCheckStatus.PERFORMED" class="necessary">
                {{ 'invoice-items-coverage-check.recipient-validation-performed' | translate }}
              </div>
            </div>
            <div class="comment">
              <mat-form-field>
                <textarea [id]="'invoiceRecipientComment' + i" matInput></textarea>
              </mat-form-field>
              <div class="actions">
                <button mat-raised-button color="primary"
                        (click)="confirmRecipientComment(treatment, 'invoiceRecipientComment' + i)">
                  {{ 'invoice-items-coverage-check.add-comment' | translate }}
                </button>
                <button mat-raised-button color="primary"
                        (click)="confirmRecipientStandardComment(treatment, 'invoiceRecipientComment' + i)">
                  {{ 'invoice-items-coverage-check.add-standard-comment' | translate }}
                </button>
              </div>
            </div>
            <div class="verification-item">
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.name-from-invoice' | translate }}</div>
                <div class="verification-item-value">{{ _fnolRequest.parsedInvoiceData.invoiceMetadata.recipientName }}
                </div>
              </div>
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.name-from-fnol' | translate }}</div>
                <div class="verification-item-value"> {{ _fnolRequest.fnolFirstName }} {{ _fnolRequest.fnolLastName }}</div>
              </div>
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.name-from-contract' | translate }}</div>
                <div class="verification-item-value">{{ treatment.contract.firstname }} {{ treatment.contract.lastname }}
                </div>
              </div>
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.address-from-invoice' | translate }}</div>
                <div class="verification-item-value">{{ _fnolRequest.parsedInvoiceData.invoiceMetadata.recipientAddress }}
                </div>
              </div>
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.address-from-contract' | translate }}</div>
                <div class="verification-item-value">{{ composeContractAddress(treatment.contract) }}
                </div>
              </div>
              <div class="verification-item-status">
                <ng-container [ngSwitch]="treatment.invoiceRecipientValidationStatus">
                  <div class="verification-item-status-ok" *ngSwitchCase="InvoiceItemsCoverageCheckStatus.NOT_NECESSARY"
                       (click)="openFnolCommentsModal(treatment.comments, 'recipientName')">
                    <mat-icon>done</mat-icon>
                  </div>
                  <div class="verification-item-status-ok" *ngSwitchCase="InvoiceItemsCoverageCheckStatus.PERFORMED"
                       (click)="openFnolCommentsModal(treatment.comments, 'recipientName')">
                    <mat-icon>done</mat-icon>
                  </div>
                  <div class="verification-item-status-warn" *ngSwitchCase="InvoiceItemsCoverageCheckStatus.OPEN"
                       (click)="openFnolCommentsModal(treatment.comments, 'recipientName')"
                       title="{{ 'invoice-items-coverage-check.click-to-see-comments' | translate }}">
                    <mat-icon>error</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="treatment-item">
            <div class="title-text">{{ 'invoice-items-coverage-check.pet-name-title' | translate }}</div>
            <div class="status">
              <div *ngIf="treatment.petNameValidationStatus === InvoiceItemsCoverageCheckStatus.NOT_NECESSARY" class="not-necessary">
                {{ 'invoice-items-coverage-check.pet-name-correct' | translate }}
              </div>
              <div *ngIf="treatment.petNameValidationStatus === InvoiceItemsCoverageCheckStatus.OPEN" class="necessary">
                {{ 'invoice-items-coverage-check.pet-name-incorrect' | translate }}
              </div>
              <div *ngIf="treatment.petNameValidationStatus === InvoiceItemsCoverageCheckStatus.PERFORMED" class="necessary">
                {{ 'invoice-items-coverage-check.pet-name-validation-performed' | translate }}
              </div>
            </div>
            <div class="comment">
              <mat-form-field>
                <textarea [id]="'petNameComment' + i" matInput></textarea>
              </mat-form-field>
              <div class="actions">
                <button mat-raised-button color="primary"
                        (click)="confirmPetNameComment(treatment, 'petNameComment' + i)">
                  {{ 'invoice-items-coverage-check.add-comment' | translate }}
                </button>
                <button mat-raised-button color="primary"
                        (click)="confirmPetNameStandardComment(treatment, 'petNameComment' + i)">
                  {{ 'invoice-items-coverage-check.add-standard-comment' | translate }}
                </button>
              </div>
            </div>
            <div class="verification-item">
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.pet-name-from-invoice' | translate }}</div>
                <div class="verification-item-value">{{ treatment.petName }}</div>
              </div>
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.pet-name-from-fnol' | translate }}</div>
                <div class="verification-item-value">{{ _fnolRequest.fnolPetName }}</div>
              </div>
              <div class="verification-item-row">
                <div class="verification-item-label">{{ 'invoice-items-coverage-check.pet-name-from-contract' | translate }}</div>
                <div class="verification-item-value">{{ treatment.contract.pet_name }}</div>
              </div>
              <div class="verification-item-status">
                <ng-container [ngSwitch]="treatment.petNameValidationStatus">
                  <div class="verification-item-status-ok" *ngSwitchCase="InvoiceItemsCoverageCheckStatus.NOT_NECESSARY"
                       (click)="openFnolCommentsModal(treatment.comments, 'petName')">
                    <mat-icon>done</mat-icon>
                  </div>
                  <div class="verification-item-status-ok" *ngSwitchCase="InvoiceItemsCoverageCheckStatus.PERFORMED"
                       (click)="openFnolCommentsModal(treatment.comments, 'petName')">
                    <mat-icon>done</mat-icon>
                  </div>
                  <div class="verification-item-status-warn" *ngSwitchCase="InvoiceItemsCoverageCheckStatus.OPEN"
                       (click)="openFnolCommentsModal(treatment.comments, 'petName')"
                       title="{{ 'invoice-items-coverage-check.click-to-see-comments' | translate }}">
                    <mat-icon>error</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
