<main>
  <div class="title-2">{{ 'contract-used-up-franchise-preview.title-used-up-franchise' | translate }}</div>
  <div class="container" *ngFor="let contract of contracts">
    <div class="top-row">
      <div class="top-row-info info">
        <div>
          <p>{{contract.full_contract_number}} ({{contract.pet_name}}), {{ 'contract-used-up-franchise-preview.year' | translate }} {{ contract.full_contract_number_year_adjusted ? contract.full_contract_number_year_adjusted.charAt(contract.full_contract_number_year_adjusted.length - 1) : '-' }}</p>
        </div>
        <div>
          <a [href]="buildContractLink(contract)" target="_blank">{{ buildContractLink(contract) }}</a>
        </div>
      </div>
      <div class="actions">
        <mat-icon (click)="toggleExpandUsedUpFranchise(contract)" class="expand-button" [class.expand]="!contract.expanded">expand_less</mat-icon>
      </div>
    </div>
    <div class="info" *ngIf="contract.expanded">
      <div>
        <label>{{ 'contract-used-up-franchise-preview.overall-limit' | translate }}</label>
        <p>{{ contract.used_up_sum_insured }}</p>
      </div>
      <div *ngIf="isContractIncluded(contract, 'krankenhausaufenthalt_des_besitzers')">
        <label>{{ 'contract-used-up-franchise-preview.emergency-minding' | translate }}</label>
        <p>{{ contract.illness_advanced_treatment_coverage.used_up_sum_insured }}</p>
      </div>
      <div *ngIf="isContractIncluded(contract, 'komplementaermedizinische_behandlung')">
        <label>{{ 'contract-used-up-franchise-preview.complementary-medicine' | translate }}</label>
        <p>{{ contract.illness_complementary_treatment_coverage.used_up_sum_insured }}</p>
      </div>
      <div *ngIf="isContractIncluded(contract, 'praeventive_behandlungen')">
        <label>{{ 'contract-used-up-franchise-preview.preventive-care' | translate }}</label>
        <p>{{ contract.preventive_care_coverage.used_up_sum_insured }}</p>
      </div>
      <div *ngIf="isContractIncluded(contract, 'medizinalfutter')">
        <label>{{ 'contract-used-up-franchise-preview.medicinal-food' | translate }}</label>
        <p>{{ contract.medical_food_coverage.used_up_sum_insured }}</p>
      </div>
      <div *ngIf="isContractIncluded(contract, 'preexisting_conditions_coverage')">
        <label>{{ 'contract-used-up-franchise-preview.preexisting-conditions' | translate }}</label>
        <p>{{ contract.preexisting_conditions_coverage.used_up_sum_insured }}</p>
      </div>
      <div *ngIf="isContractIncluded(contract, 'worldwide_protection_coverage')">
        <label>{{ 'contract-used-up-franchise-preview.worldwide-coverage' | translate }}</label>
        <p>{{ contract.worldwide_protection_coverage.used_up_sum_insured }}</p>
      </div>
      <div>
        <label>{{ 'contract-used-up-franchise-preview.used-up-franchise' | translate }}</label>
        <p>{{ contract.used_up_annual_franchise }}</p>
      </div>
    </div>
  </div>
</main>
