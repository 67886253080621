import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {CognitoAuthenticatedUser} from "../models";
import {fetchUserAttributes, signOut} from "@aws-amplify/auth";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _user$ = new BehaviorSubject<CognitoAuthenticatedUser | null>(null);

  constructor(public router: Router) {
  }

  get user$() {
    return this._user$.asObservable();
  }

  setUser(user: CognitoAuthenticatedUser | null) {
    this._user$.next(user);
  }

  async logout() {
    await signOut();
    this.setUser(null);
    this.router.navigate(['/account']);
  }

  async handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      if (userAttributes) {
        const user = new CognitoAuthenticatedUser(
          userAttributes.email_verified === 'true',
          userAttributes.given_name!,
          userAttributes.family_name!,
          userAttributes.email!,
          userAttributes.sub
        );
        this.setUser(user);
        return true;
      } else {
        this.setUser(null)
        this.router.navigate(['/account']);
        return false;
      }
    } catch (error) {
      this.setUser(null)
      this.router.navigate(['/account']);
      return false;
    }
  }
}
