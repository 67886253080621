import {Component, Input, OnInit} from '@angular/core';
import {
  Contract,
  ContractPreview,
  FnolRequest,
  FnolRequestStatus,
  Treatment,
  TreatmentCheckComment,
  TreatmentVerificationStatus
} from "../../../models";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";
import {MatDialog} from "@angular/material/dialog";
import {FnolCommentsModalComponent} from "../../fnol/fnol-comments-modal/fnol-comments-modal.component";
import {compareNames, getAddressCheckStatus, getDateCheckStatus, getPetNameCheckStatus, getRecipientNameCheckStatus} from "../../../helpers";

@Component({
  selector: 'CPF-invoice-assign-items-preview',
  templateUrl: './invoice-assign-items-preview.component.html',
  styleUrls: ['./invoice-assign-items-preview.component.scss']
})
export class InvoiceAssignItemsPreviewComponent implements OnInit {
  public _fnolRequest!: FnolRequest;

  contracts: Contract[] = [];
  expanded = false;

  public treatmentVerificationStatus = TreatmentVerificationStatus;
  public FnolRequestStatusEnum = FnolRequestStatus;

  constructor(private sidebarDetailsService: SidebarDetailsService, private dialog: MatDialog) {
  }

  @Input() set fnolRequest(fnolRequest: FnolRequest) {
    this._fnolRequest = fnolRequest;
    this._fnolRequest.parsedInvoiceData.treatments.forEach(treatment => {
      this.generateTreatmentsComments(treatment);
    });
  }

  @Input() set contractSelected(contractSelected: ContractPreview[]) {
    this.contracts = contractSelected.map(c => c.contracts).flat();
  }

  ngOnInit() {
  }

  generateTreatmentsComments(treatment: Treatment) {
    treatment.comments = (treatment.comments || []).filter(c => c.addedBy !== 'system');

    if (!treatment.contract) {
      return;
    }

    const nameVerificationStatus = getRecipientNameCheckStatus(this._fnolRequest, treatment.contract!);
    const petNameVerificationStatus = getPetNameCheckStatus(this._fnolRequest, treatment, treatment.contract!);
    const dateVerificationStatus = getDateCheckStatus(treatment, treatment.contract!);
    const addressVerificationStatus = getAddressCheckStatus(this._fnolRequest, treatment.contract!);

    if (nameVerificationStatus === TreatmentVerificationStatus.ERROR) {
      treatment.comments.push({
        fieldName: 'recipientName',
        comment: 'Recipient name does not match with the contract nor the FNOL request',
        addedBy: 'system'
      });
    }

    if (nameVerificationStatus === TreatmentVerificationStatus.WARNING) {
      treatment.comments.push({
        fieldName: 'recipientName',
        comment: 'Recipient name does not match with the contract or the FNOL request',
        addedBy: 'system'
      });
    }

    if (petNameVerificationStatus === TreatmentVerificationStatus.ERROR) {
      treatment.comments.push({
        fieldName: 'petName',
        comment: 'Pet name does not match with the contract nor the FNOL request',
        addedBy: 'system'
      });
    }

    if (petNameVerificationStatus === TreatmentVerificationStatus.WARNING) {
      treatment.comments.push({
        fieldName: 'petName',
        comment: 'Pet name does not match with the contract or the FNOL request',
        addedBy: 'system'
      });
    }

    if (dateVerificationStatus === TreatmentVerificationStatus.ERROR) {
      treatment.comments.push({
        fieldName: 'date',
        comment: 'Treatment date is not between the contract start and end date',
        addedBy: 'system'
      });
    }

    if (addressVerificationStatus === TreatmentVerificationStatus.WARNING) {
      treatment.comments.push({
        fieldName: 'address',
        comment: 'Not full address match with the contract',
        addedBy: 'system'
      });
    }

    if (addressVerificationStatus === TreatmentVerificationStatus.ERROR) {
      treatment.comments.push({
        fieldName: 'address',
        comment: 'Address does not match with the contract',
        addedBy: 'system'
      });
    }
  }

  composeContractAddress(contract: Contract) {
    return `${contract.street} ${contract.houseNumber}, ${contract.city}, ${contract.zip}`;
  }

  openContractDetailsSidebar(contract: Contract) {
    if (!contract) {
      return;
    }

    this.sidebarDetailsService.setContractDetails(contract);
    this.sidebarDetailsService.open();
  }

  openFnolCommentsModal(comments: TreatmentCheckComment[], fieldName: string | undefined) {
    const dialogRef = this.dialog.open(FnolCommentsModalComponent, {
      data: {comments: comments, fieldName: fieldName}
    });
  }

  protected readonly getRecipientNameCheckStatus = getRecipientNameCheckStatus;
  protected readonly getPetNameCheckStatus = getPetNameCheckStatus;
  protected readonly getAddressCheckStatus = getAddressCheckStatus;
  protected readonly getDateCheckStatus = getDateCheckStatus;
}
