<div class="list">
  <div class="task pointer" [class.active]="task.selected" *ngFor="let task of fnolRequest.tasks" (click)="selectTask(task)">
    <div class="title">
      {{ task.title }}
    </div>
    <div class="open-date">{{ task.openingDate | date:'dd.MM.YYYY' }}</div>
    <div *ngIf="!isTaskClosed(task)" class="status open">{{ 'task-list-fnol-request.task.status-open' | translate }}</div>
    <div *ngIf="isTaskClosed(task)" class="status closed">{{ 'task-list-fnol-request.task.status-closed' | translate }}
      [{{ task.closingDate | date:'dd.MM.YYYY' }}]
    </div>
  </div>
  <button (click)="createNewTask()" color="primary" mat-raised-button>{{ 'task-list-fnol-request.task.new-task-button' | translate }}</button>
</div>
<div class="task-detail">
  <ng-container *ngIf="selectedTask">
    <mat-form-field>
      <mat-label>{{ 'task-list-fnol-request.task.title-label' | translate }}</mat-label>
      <input matInput [(ngModel)]="selectedTask.title">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'task-list-fnol-request.task.description-label' | translate }}</mat-label>
      <input matInput [(ngModel)]="selectedTask.description">
    </mat-form-field>
    <div class="resolution-container">
      <mat-form-field>
        <mat-label>{{ 'task-list-fnol-request.task.resolution-label' | translate }}</mat-label>
        <input matInput [(ngModel)]="selectedTask.resolution" [disabled]="isTaskClosed(selectedTask)">
      </mat-form-field>
      <div class="resolution-actions">
        <button (click)="resolveTask()" *ngIf="!isTaskClosed(selectedTask)"
                mat-raised-button>{{ 'task-list-fnol-request.task.resolve-button' | translate }}
        </button>
        <button (click)="reopenTask()" *ngIf="isTaskClosed(selectedTask)"
                mat-raised-button>{{ 'task-list-fnol-request.task.reopen-button' | translate }}
        </button>
      </div>
    </div>
    <section>
      <mat-label>{{ 'task-list-fnol-request.task.included-in-comment-label' | translate }}</mat-label>
      <mat-checkbox [(ngModel)]="selectedTask.includeInComment"></mat-checkbox>
    </section>
    <div class="actions">
      <button (click)="confirmTask()" color="primary" mat-raised-button>{{ 'task-list-fnol-request.task.confirm-button' | translate }}</button>
      <button (click)="closeTask()" color="accent" mat-raised-button>{{ 'task-list-fnol-request.task.close-button' | translate }}</button>
    </div>
  </ng-container>
</div>
