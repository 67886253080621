import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FnolRequest, fnolRequestExample} from "../models";
import {ApiHttpService} from "../services/api-http.service";
import {filter, take} from "rxjs";

@Component({
  selector: 'CPF-fnol-request-page',
  templateUrl: './fnol-request-page.component.html',
  styleUrls: ['./fnol-request-page.component.scss']
})
export class FnolRequestPageComponent {
  fnolRequest: FnolRequest | undefined;

  constructor(private route: ActivatedRoute, private apiHttpService: ApiHttpService) {
    this.route.params.pipe(filter(params => !!params['id']), take(1)).subscribe(async params => {
      this.fnolRequest = await this.apiHttpService.getFnolRequest(params['id']);
    });
  }
}
