<div class="container" *ngIf="contracts.length && parsedInvoiceData">
  <div class="info-and-assign-container" *ngFor="let treatment of parsedInvoiceData.treatments">
    <div class="info treatment">
      <div class="treatment-pet-info">
        <div>
          <label>{{ 'invoice-assign-items.pet-name-label' | translate }}</label>
          <p>{{ treatment.petName }}</p>
        </div>
        <div>
          <label>{{ 'invoice-assign-items.date-label' | translate }}</label>
          <p>{{ treatment.date | date:'dd.MM.YYYY' }}</p>
        </div>
        <div>
          <label>{{ 'invoice-assign-items.diagnosis-label' | translate }}</label>
          <p>{{ treatment.diagnosis }}</p>
        </div>
      </div>
      <div class="info treatment-position" *ngFor="let treatmentPosition of treatment.positions">
        <div class="treatment-name-container">
          <label>{{ 'invoice-assign-items.treatment-name-label' | translate }}</label>
          <p>{{ treatmentPosition.name }}</p>
        </div>
        <div>
          <label>{{ 'invoice-assign-items.quantity-label' | translate }}</label>
          <p>{{ treatmentPosition.quantity }}</p>
        </div>
        <div>
          <label>{{ 'invoice-assign-items.vat-percentage-label' | translate }}</label>
          <p>{{ treatmentPosition.vatPercentage }}</p>
        </div>
        <div>
          <label>{{ 'invoice-assign-items.amount-label' | translate }}</label>
          <p>{{ treatmentPosition.amount }}</p>
        </div>
        <div>
          <label>{{ 'invoice-assign-items.classification-label' | translate }}</label>
          <p>{{ (!!treatmentPosition.classification) | booleanTranslate }}</p>
        </div>
      </div>
    </div>
    <div class="assign-contract-container">
      <div class="assigned-contract" *ngIf="treatment.contract">
        <span><mat-icon>description</mat-icon>
          {{ treatment.contract.full_contract_number }}
          <mat-icon class="pointer" (click)="openContractDetailsSidebar(treatment.contract)">open_in_full</mat-icon>
        </span>
        <span><mat-icon>pets</mat-icon>
          {{ treatment.contract.pet_name }}
        </span>
        <span><mat-icon>person</mat-icon>
          {{ treatment.contract.firstname }} {{ treatment.contract.lastname }}
        </span>
      </div>
      <div class="assign-contract">
        <button (click)="assignContract(treatment)" color="primary" mat-raised-button>
          {{ 'invoice-assign-items.assign-contract-button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
