import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ApiHttpService} from "../services/api-http.service";
import {fetchUserAttributes} from "@aws-amplify/auth";
import {Contract} from "../models";
import { jsPDF } from 'jspdf';
import {euclidCircularbBoldFontBinaryString, euclidCircularbRegularFontBinaryString} from "../helpers";

@Component({
  selector: 'CPF-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit{
  title = 'customer-portal-frontend';

  petForm = new FormGroup({
    petName: new FormControl(''),
    petBirthDate: new FormControl(''),
    contractNumber: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl('')
  });

  @ViewChild('table') tableEl: ElementRef | undefined;

  constructor(private translateService: TranslateService, private apiHttpService: ApiHttpService, private renderer: ElementRef) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit(): void {
    this.handleFetchUserAttributes();
  }

  async handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      console.log(userAttributes);
    } catch (error) {
      console.log(error);
    }
  }

  onSubmit() {
    console.log('Form data:', this.petForm.value);
    this.apiHttpService.findContractAPI(this.petForm.value).then((res) => {
      const contracts:Contract[] = res as unknown as Contract[];
      console.log('API Response:', contracts);
      console.log('API Response:', contracts[0].full_contract_number);
      console.log('API Response:', contracts[0].firstname);
    });
  }

  async generatePDF() {
    // const tableContainer = this.tableEl?.nativeElement;
    let tableContainer = document.createElement('div');
    let table = document.createElement('table');
    let tbody = document.createElement('tbody');

    // Add class and id to div
    tableContainer.className = 'table-container';
    tableContainer.id = 'table';

    //Add logo image
    let img = document.createElement('img');
    img.src = 'assets/logo_calingo_rgb_petrol.png';
    img.width = 259;
    img.height = 94;
    tableContainer.appendChild(img);

    //Add title
    let title = document.createElement('h2');
    title.textContent = 'Schadenabrechnung der Meldung vom 22.05.2024';
    tableContainer.appendChild(title);

    // Append table and tbody to div and table respectively
    tableContainer.appendChild(table);
    table.appendChild(tbody);

    // Rows data
    let rows = [
      {className: 'empty', cells: ['']},
      {className: 'no-borders case-name', cells: ['Case of damage 2024/1350537/7-1T']},
      {className: 'no-borders', cells: ['Animal', 'Gusti']},
      {className: 'no-borders', cells: ['Treatment date', '22.05.2024']},
      {className: 'no-borders', cells: ['Category', 'Krankheit']},
      {className: 'empty', cells: ['']},
      {className: 'border-outside border-top', cells: ['Claim according to invoice dated', '31.05.2024', 'CHF', '760,20']},
      {className: 'border-outside border-top', cells: ['Thereof covered', 'Krankheit', 'CHF', '760,20']},
      {className: 'border-outside border-top', cells: ['Of which uncovered', 'Innerhalb Selbstbehalt', 'CHF', '-']},
      {className: 'border-outside border-top', cells: ['Franchise per year', '', 'CHF', '238,20']},
      {className: 'border-outside', cells: ['', '', 'CHF', '-238,20']},
      {className: 'border-outside', cells: ['New balance franchise', '', 'CHF', '-']},
      {className: 'border-outside border-top', cells: ['Per claim deductible', '', 'CHF', '']},
      {className: 'border-outside border-top', cells: ['Deductible', '40%', 'CHF', '208,80']},
      {className: 'border-outside border-top', cells: ['Balance limit sum insured', '', 'CHF', '700']},
      {className: 'border-outside', cells: ['', '', 'CHF', '-313,20']},
      {className: 'border-outside', cells: ['New balance limit sum insured', '', 'CHF', '386,80']},
      {className: 'border-outside border-top border-bottom', cells: ['Payout in your favor', '', 'CHF', '313,20']},
    ]

// Create rows with cells
    for (let row of rows) {
      let tr = document.createElement('tr');
      tr.className = row.className;

      for (let cellData of row.cells) {
        let td = document.createElement('td');
        td.textContent = cellData;

        tr.appendChild(td);
      }

      tbody.appendChild(tr);
    }
    let doc = new jsPDF('p', 'pt', 'a4');

    console.log('html:', tableContainer);

    doc.addFileToVFS('EuclidCircularB-Regular-normal.ttf', euclidCircularbRegularFontBinaryString);
    doc.addFileToVFS('EuclidCircularB-Bold-normal.ttf', euclidCircularbBoldFontBinaryString);
    doc.addFont('EuclidCircularB-Regular-normal.ttf', 'euclid-circularb-regular', 'normal');
    doc.addFont('EuclidCircularB-Bold-normal.ttf', 'euclid-circularb-regular', 'bold');
    doc.html(tableContainer, {
      callback: (doc: jsPDF) => {
        doc.save('pdf-export');
      }
    })
  }
}
