<div class="info-claims">
  <h3>{{ 'contract-claims-list.claims-info-title'|translate }}</h3>
  <ng-container *ngIf="claims && claims.length">
    <div class="info-claims-list" *ngFor="let claim of claims">
      <div class="info claim">
        <div>
          <label>{{ 'contract-claims-list.identifier' | translate }}</label>
          <p>{{ claim.identifier }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.claim-date' | translate }}</label>
          <p>{{ dateToString(claim.claim_date) }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.paid-amount' | translate }}</label>
          <p>{{ claim.paid_amount }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.expense' | translate }}</label>
          <p>{{ claim.expense }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.used-franchise' | translate }}</label>
          <p>{{ claim.used_franchise }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.status' | translate }}</label>
          <p>{{ claim.status }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.closing-reason' | translate }}</label>
          <p>{{ claim.closing_reason }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.closing-date' | translate }}</label>
          <p>{{ dateToString(claim.closing_date) }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.type' | translate }}</label>
          <p>{{ claim.type }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.sub-type' | translate }}</label>
          <p>{{ claim.sub_type }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.cause' | translate }}</label>
          <p>{{ claim.cause }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.insured-event' | translate }}</label>
          <p>{{ claim.insured_event | booleanTranslate }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.reason-why-not-insured' | translate }}</label>
          <p>{{ claim.reason_why_not_insured }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.deduction-yearly-franchise' | translate }}</label>
          <p>{{ claim.deduction_yearly_franchise }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.deduction-percentage-deductible' | translate }}</label>
          <p>{{ claim.deduction_percentage_deductible }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.deduction-deductible' | translate }}</label>
          <p>{{ claim.deduction_deductible }}</p>
        </div>
        <div>
          <label>{{ 'contract-claims-list.benefit-amount' | translate }}</label>
          <p>{{ claim.benefit_amount }}</p>
        </div>
      </div>
      <div class="actions">
        <button mat-raised-button color="primary" (click)="selectClaim(claim.identifier)">Select</button>
      </div>
    </div>
  </ng-container>
</div>
