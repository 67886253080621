import {Contract, ContractPreview, FnolRequest, ParsedInvoiceData, Treatment, TreatmentVerificationStatus} from "../models";
import {MissingTranslationHandler} from "@ngx-translate/core/lib/missing-translation-handler";

export const convertContractsToContractPreview = (contracts: Contract[]): ContractPreview[] => {
  return contracts.reduce((acc: ContractPreview[], item: Contract) => {
    const contractNumberFirstPart = item.full_contract_number.split('-')[0];
    if (acc.length === 0 || acc[acc.length - 1].contractNumberFirstPart !== contractNumberFirstPart) {
      acc.push({
        full_contract_number: item.full_contract_number,
        contractNumberFirstPart: contractNumberFirstPart,
        pet_name: item.pet_name,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        phoneNumber: item.phoneNumber,
        birthdate: item.birthdate,
        contracts: [item]
      });
    } else {
      acc[acc.length - 1].contracts.push(item);
    }
    return acc;
  }, [])
}

export const getRecipientNameCheckStatus = (fnolRequest: FnolRequest, contract: Contract): TreatmentVerificationStatus => {
  let successfulComparison = 0;

  const fnolFullName = `${fnolRequest.fnolFirstName} ${fnolRequest.fnolLastName}`;
  const contractFullName = `${contract.firstname} ${contract.lastname}`;

  if (compareNames(fnolRequest.parsedInvoiceData.invoiceMetadata.recipientName, fnolFullName)) {
    successfulComparison++;
  }

  if (compareNames(fnolRequest.parsedInvoiceData.invoiceMetadata.recipientName, contractFullName)) {
    successfulComparison++;
  }

  if (compareNames(fnolFullName, contractFullName)) {
    successfulComparison++;
  }

  if (successfulComparison === 3) {
    return TreatmentVerificationStatus.OK;
  }

  if (successfulComparison > 0) {
    return TreatmentVerificationStatus.WARNING;
  }

  return TreatmentVerificationStatus.ERROR;
}

export const compareNames = (name1: string, name2: string) => {
  return name1.trim().toLowerCase() === name2.trim().toLowerCase();
}

export const getPetNameCheckStatus = (fnolRequest: FnolRequest, treatment: Treatment, contract: Contract): TreatmentVerificationStatus => {
  var cleanPetNameFromTreatment = cleanPetName(treatment.petName)

  if (!compareNames(cleanPetNameFromTreatment, contract.pet_name)) {
    return TreatmentVerificationStatus.ERROR;
  }
  
  if (!compareNames(cleanPetNameFromTreatment, fnolRequest.fnolPetName)) {
    return TreatmentVerificationStatus.WARNING;
  }
  // given that we did not return on the previous two conditions, we can infer that all petName instances are equal
  return TreatmentVerificationStatus.OK;

}

function cleanPetName(name: string): string {
  // Remove common prefixes/suffixes and anything in parentheses
  return name
    .replace(/\b(Hund|Katze|Dog|Cat|Chien|Chat|Cane|Gatto)\b/gi, '') // Adjust this list based on your needs
    .replace(/\(.*?\)/g, '') // Remove anything within parentheses
    .trim(); // Remove leading and trailing whitespace
}

export const checkIfTreatmentDateBeforeContractStartDate = (contractStartDate: string, treatmentDate: string) => {
  return new Date(treatmentDate).getTime() < new Date(contractStartDate).getTime();
}

export const checkIfTreatmentDateAfterContractEndDate = (contractEndDate: string, treatmentDate: string) => {
  return new Date(treatmentDate).getTime() > new Date(contractEndDate).getTime();
}

export const checkIfTreatmentDateIsBetweenContractDates = (contractStartDate: string, contractEndDate: string, treatmentDate: string) => {
  return new Date(treatmentDate).getTime() >= new Date(contractStartDate).getTime() && new Date(treatmentDate).getTime() <= new Date(contractEndDate).getTime();
}

export const getDateCheckStatus = (treatment: Treatment, contract: Contract): TreatmentVerificationStatus => {
  if (checkIfTreatmentDateIsBetweenContractDates(contract.start_date, contract.end_date, treatment.date)) {
    return TreatmentVerificationStatus.OK;
  }

  return TreatmentVerificationStatus.ERROR;
}

export const getAddressCheckStatus = (fnolRequest: FnolRequest, contract: Contract): TreatmentVerificationStatus => {
  const minEntrancesOK = 3;
  const minEntrancesWarning = 1;
  const contractAddressEntrances = [...contract.street.split(' '), contract.houseNumber, ...contract.city.split(' '), contract.zip].filter(e => e !== '').map(e => e.toLowerCase());
  const fnolAddressEntrances = fnolRequest.parsedInvoiceData.invoiceMetadata.recipientAddress.split(' ').filter(e => e !== '').map(e => e.toLowerCase());

  let successfulComparison = 0;

  contractAddressEntrances.forEach(e => {
    if (fnolAddressEntrances.includes(e)) {
      successfulComparison++;
    }
  });

  if (successfulComparison >= minEntrancesOK) {
    return TreatmentVerificationStatus.OK;
  }

  if (successfulComparison >= minEntrancesWarning) {
    return TreatmentVerificationStatus.WARNING;
  }

  return TreatmentVerificationStatus.ERROR;
}

export const contractIdFromContractNumber = (contractNumber?: string): number => {
  if (!contractNumber) {
    return 0
  }
  let contractIdStr = contractNumber.startsWith("PET") ? contractNumber.substring("PET".length) : contractNumber
  let contractId = parseInt(contractIdStr)
  if (isNaN(contractId)) {
    return 0
  }
  return contractId
}

export class HandleMissingTranslation implements MissingTranslationHandler {
  handle(params: any): string {
    console.log('Missing translation for:', params.key);
    return params.key;
  }
}

export function createEmptyParsedInvoiceData(): ParsedInvoiceData {

  const emptyParsedInvoiceData: ParsedInvoiceData = {
    invoiceMetadata: {
      issuerName: "",
      issuerAddress: "",
      recipientName: "",
      recipientAddress: "",
      invoiceNumber: "",
      invoiceDate: "",
      language: ""
    },
    recognizedVet: undefined,  // Optional property
    treatments: [],  // Empty array for treatments
    sumWithoutVat: 0,
    sumWithVat: 0,
    calculatedSum: 0,
    calculationInformation: {
      vatContainedInPositions: false,
      totalInclVat: 0,
      totalExclVat: 0,
      vatAmountToDistribute: 0,
      vatPercentageToDistribute: 0,
      applicableDiscountAmount: 0,

    }
  };

  return emptyParsedInvoiceData
}