import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Contract, ContractPreviewUIStatus, ContractStatus} from "../../../models";

@Component({
  selector: 'CPF-contract-preview-select-modal',
  templateUrl: './contract-preview-select-modal.component.html',
  styleUrls: ['./contract-preview-select-modal.component.scss']
})
export class ContractPreviewSelectModalComponent {
  public contracts: Contract[] = [];
  public petName: string = '';
  public firstName: string = '';
  public lastName: string = '';

  constructor(public dialogRef: MatDialogRef<ContractPreviewSelectModalComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    contractPreviews: Contract[]
  }) {
    this.contracts = data.contractPreviews;
    this.petName = this.contracts[0].pet_name;
    this.firstName = this.contracts[0].firstname;
    this.lastName = this.contracts[0].lastname;
  }

  public selectContract(contractPreview: Contract) {
    this.dialogRef.close(contractPreview);
  }

  getStatusClass(contract: Contract) {
    return contract.status === ContractStatus.ACTIVE ? 'contract-active' : 'contract-expired';
  }

  getStatusTranslationKey(contract: Contract) {
    return contract.status === ContractStatus.ACTIVE ? 'contract-preview.status.active' : 'contract-preview.status.expired';
  }
}
