import {Component, Input} from '@angular/core';
import {Contract, ContractPreview, ParsedInvoiceData, Treatment} from "../../../models";
import {MatDialog} from "@angular/material/dialog";
import {
  ContractAssignListModalComponent
} from "../../contract/contract-assign-list-modal/contract-assign-list-modal.component";
import {SidebarDetailsService} from "../../../services/sidebar-details.service";

@Component({
  selector: 'CPF-invoice-assign-items',
  templateUrl: './invoice-assign-items.component.html',
  styleUrls: ['./invoice-assign-items.component.scss']
})
export class InvoiceAssignItemsComponent {
  @Input() parsedInvoiceData: ParsedInvoiceData | undefined;

  contracts: Contract[] = [];

  @Input() set contractSelected(contractSelected: ContractPreview[]) {
    this.contracts = contractSelected.map(c => c.contracts).flat();
  }

  constructor(private dialog: MatDialog, private sidebarDetailsService: SidebarDetailsService) {
  }

  findAssignedContract(fullContractNumber: string) {
    return this.contracts.find(c => c.full_contract_number === fullContractNumber);
  }

  assignContract(treatment: Treatment) {
    const dialogRef = this.dialog.open(ContractAssignListModalComponent, {
      data: {contracts: this.contracts},
      minWidth: '50%',
      width: '100%',
    });

    dialogRef.afterClosed().subscribe((result: Contract | null | 'empty') => {
      if (result) {
        if (result === 'empty') {
          treatment.contract = null;
          return;
        }

        treatment.contract = {...result};
      }
    });
  }

  openContractDetailsSidebar(contract: Contract) {
    if (!contract) {
      return;
    }

    this.sidebarDetailsService.setContractDetails(contract);
    this.sidebarDetailsService.open();
  }

}
