<ng-container *ngIf="fnolRequest">
  <div class="action-container">
    <div class="icon" *ngIf="isUnclosedTasks()">
      <mat-icon color="primary">pending_actions</mat-icon>
    </div>
    <button (click)="openTasksList()" color="primary"
            mat-raised-button>{{ 'fnol-request-side-bar.task_button' | translate }}
    </button>
    <div class="flex-placeholder" *ngIf="isUnclosedTasks()"></div>
  </div>

  <div>
    <mat-form-field>
      <mat-label>{{ 'fnol-request-side-bar.priority_label' | translate }}</mat-label>
      <mat-select [(ngModel)]="fnolRequest.priority" (selectionChange)="dropdownValueChanged()">
        <mat-option [value]="0">{{ 'fnol-request-side-bar.priority_normal' | translate }}</mat-option>
        <mat-option [value]="1">{{ 'fnol-request-side-bar.priority_high' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>{{ 'fnol-request-side-bar.assignee_label' | translate }}</mat-label>
      <mat-select [(ngModel)]="fnolRequest.processingAssignee" (selectionChange)="dropdownValueChanged()">
        <mat-option value="-">{{ 'fnol-request-side-bar.assignee_unassigned' | translate }}</mat-option>
        <mat-option [value]="user.email" *ngFor="let user of usersList">{{ user.firstname }} {{ user.lastname }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="labels-container">
    <section>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.SIMPLE_CLAIM)"
                    (change)="checkLabel(tasksLabels.SIMPLE_CLAIM)">{{ 'fnol-request-side-bar.labels.simple_claim' | translate }}
      </mat-checkbox>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.MULTIPLE_CLAIMS)"
                    (change)="checkLabel(tasksLabels.MULTIPLE_CLAIMS)">{{ 'fnol-request-side-bar.labels.multiple_claims' | translate }}
      </mat-checkbox>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.WAITING)"
                    (change)="checkLabel(tasksLabels.WAITING)">{{ 'fnol-request-side-bar.labels.waiting' | translate }}
      </mat-checkbox>
      <mat-checkbox [checked]="isLabelChecked(tasksLabels.SIMPEGO)"
                    (change)="checkLabel(tasksLabels.SIMPEGO)">{{ 'fnol-request-side-bar.labels.simpego' | translate }}
      </mat-checkbox>
    </section>
  </div>

  <mat-form-field>
    <mat-label>{{ 'fnol-request-side-bar.reserve-claim-contract-id-label' | translate }}</mat-label>
    <input matInput [(ngModel)]="fnolRequest.mfvReserveClaimContractID" (change)="dropdownValueChanged()">
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'fnol-request-side-bar.reserve-claim-id-label' | translate }}</mat-label>
    <input matInput [(ngModel)]="fnolRequest.mfvReserveClaimID" (change)="dropdownValueChanged()">
  </mat-form-field>


  <div class="info" *ngIf="fnolRequest.initialReserveData">
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-contract-id' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.contractID }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-full-contract-number' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.fullContractNumber }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-claim-id' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.claimID }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-claim-number' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.claimNumber }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-claim-date' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.claimDate | date:'dd.MM.YYYY' }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-claim-type' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.type }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-claim-amount' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.amount }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'fnol-request-side-bar.api-reserve-claim-course-of-events' | translate }}</label>
      <div><span>{{ fnolRequest.initialReserveData.courseOfEvents }}</span>
      </div>
    </div>
  </div>

  <button mat-raised-button color="warn"
          (click)="completeFNOLRequestWithoutProcessing()">
    {{ 'fnol-request-side-bar.close-without-processing' | translate }}
  </button>

  <button mat-raised-button color="primary" *ngIf="fnolRequest.processingStatus === 98"
          (click)="reopenFNOLRequest()">
    {{ 'fnol-request-side-bar.reopen' | translate }}
  </button>

  <button mat-raised-button color="primary"
          (click)="duplicate()">
    {{ 'fnol-request-side-bar.duplicate' | translate }}
  </button>

  <div *ngIf="fnolRequest.parentRequestId">
    <div class="duplicate-title">{{ 'fnol-request-side-bar.parent-request-title' | translate }}</div>
    <div class="duplicate-value">
      <a [href]="linkToFnolRequestById(fnolRequest.parentRequestId)">
        {{ 'fnol-request-side-bar.parent-request-value' | translate }}
      </a>
    </div>
  </div>

  <div *ngIf="fnolRequest.clonedRequestIds && fnolRequest.clonedRequestIds.length">
    <div class="duplicate-title">{{ 'fnol-request-side-bar.cloned-requests-title' | translate }}</div>
    <div class="duplicate-value">
      <a *ngFor="let clonedRequestId of fnolRequest.clonedRequestIds" [href]="linkToFnolRequestById(clonedRequestId)">
        {{ clonedRequestId }}
      </a>
    </div>
  </div>

</ng-container>
