<div class="header" *ngIf="fnolRequest">
  <div class="header-title title-2">{{ 'task-list-modal.header-title' | translate }} {{ fnolRequest.fnolFirstName }} {{ fnolRequest.fnolLastName }}
    ({{ fnolRequest.fnolPetName }})
  </div>
  <div class="close pointer" (click)="close()">
    <span>
      <mat-icon>close</mat-icon>
    </span>
  </div>
</div>

<CPF-task-list-fnol-request *ngIf="fnolRequest" [fnolRequest]="fnolRequest"></CPF-task-list-fnol-request>
